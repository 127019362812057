import React, { Component, Fragment } from 'react';

import {
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Table,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

import './Invoice.css';
import logo from './logo.png';


class Invoice extends Component {

    toFullAddressArray(addressModal) {
        let localAddress = [addressModal.address_1, addressModal.address_2].filter(x => x.trim() !== '')
        let cityAndRegionState = [addressModal.postcode, addressModal.town_city].filter(x => x.trim() !== '').join(', ');
        let countryAndPostCode = [addressModal.state, addressModal.country].filter(x => x.trim() !== '').join(', ');

        return [].concat(localAddress, cityAndRegionState, countryAndPostCode).filter(x => x.trim() !== '')
    }

    /**
     * Generate item's configuration table to be placed inside the cart table
     *
     * @param {Object} item Product item in the cart system
     */
    generateItemConfigurationTable(item) {
        return (
            <table className="item-configs">
                <tbody>
                    {
                        Object.keys(item.configurations).map(key => {
                            let value = item.configurations[key];
                            if (key === 'size') {
                                value = (value.type) ? `${value.type}` : `${value.height} mm x ${value.width} mm`;
                            }

                            if (!key.includes('path') && !key.includes('weight')) {
                                return (
                                    <tr key={key}>
                                        <td>{key}</td>
                                        <td>{value}</td>
                                    </tr>
                                )
                            }
                        })
                    }
                </tbody>
            </table>
        )
    }

    openPrintDialog() {
        window.print();
    }

    render() {
        const { order } = this.props;

        return (
            <Container>
                <div className="invoice-container">
                    <div className='print-prompt'>
                        <Button color="primary" onClick={this.openPrintDialog.bind(this)}>PRINT</Button>
                    </div>

                    <div className="document-header">
                        {/* <img src={logo} alt="company logo" /> */}
                        <div style={{ fontSize: '2.5rem', fontWeight: 'bolder' }}>{((order.status === 'Paid') || (order.status === 'Shipped'))? 'INVOICE' : 'ORDER CONFIRMATION'}</div>
                    </div>

                    <div className="invoice-header">
                        <div className="company-address">
                            <div className="address-name">U-Tech Enterprise</div>
                            <div className="address-line">No. 7, Solok Sungai Pinang 4,</div>
                            <div className="address-line">11600 Sungai Pinang,</div>
                            <div className="address-line">Penang, Malaysia.</div>
                            <div className="address-phone" style={{ marginTop: '0.25rem' }}>Tel: +60 4-281 5207</div>
                            {/* <div className="address-phone">Fax: +60 4-281 5207</div> */}
                        </div>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Date :</td>
                                    <td style={{ textAlign: 'right' }}>{new Date(order.order_date).toLocaleDateString('en-MY', { day: 'numeric', month: 'long', year: 'numeric' })}</td>
                                </tr>
                                <tr>
                                    <td>{((order.status === 'Paid') || (order.status === 'Shipped')) ? 'Invoice No :' : 'Reference No :'}</td>
                                    <td style={{ textAlign: 'right' }}>{order._id}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="invoice-address">
                        <div>
                            <div className="address-title">Shipping address</div>
                            <div className="shipping-address">
                                <div className="address-name">{(order.order_details.recipient) ? order.order_details.recipient.name : order.order_details.customer.name}</div>
                                {this.toFullAddressArray((order.order_details.recipient) ? order.order_details.recipient.address : order.order_details.customer.address).map(x => (
                                    <div className="address-line">{x}</div>
                                ))}
                                <div className="address-phone" style={{ marginTop: '0.25rem' }}>{(order.order_details.recipient) ? order.order_details.recipient.phone : order.order_details.customer.phone}</div>
                            </div>
                            <div style={{ marginTop: '0.5rem' }}>Delivery option: {order.order_details.delivery_option}</div>
                        </div>
                        <div>
                            <div className="address-title">Billing address</div>
                            <div className="billing-address">
                                <div className="address-name">{order.order_details.customer.name}</div>
                                { order.order_details.customer.company && <div className="address-name">{order.order_details.customer.company}</div>}
                                {this.toFullAddressArray(order.order_details.customer.address).map(x => (
                                    <div className="address-line">{x}</div>
                                ))}
                                <div className="address-phone" style={{ marginTop: '0.25rem' }}>{order.order_details.customer.phone}</div>
                                <div className="address-email">{order.order_details.customer.email}</div>
                            </div>
                            <div style={{ marginTop: '0.5rem' }}>{order.order_details.payment_method ? 'Payment method: ' + order.order_details.payment_method : 'Payment status: UNPAID'}</div>
                        </div>


                    </div>
                    <div className="invoice-listing">
                        <table id="listing-table" className='table table-bordered table-sm'>
                            <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Description</th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(order.order_details.items) && order.order_details.items.map((item, index) =>
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>
                                            <h6 style={{ display: 'inline-block' }}>{item.product}</h6>
                                            {this.generateItemConfigurationTable(item)}
                                        </td>
                                        <td>RM {item.price.toFixed(2)}</td>
                                    </tr>
                                )}
                                <tr>
                                    <td colSpan='2' style={{ textAlign: 'right' }}>Subtotal</td>
                                    <td>RM {order.order_details.subtotal.toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td colSpan='2' style={{ textAlign: 'right' }}>Shipping</td>
                                    <td>RM {order.order_details.shipping_fee.toFixed(2)}</td>
                                </tr>
                                {
                                    (order.order_details.voucher_code.length > 0) &&
                                    <tr>
                                        <td colSpan='2' style={{ textAlign: 'right' }}>Discount (Voucher Code: {order.order_details.voucher_code})</td>
                                        <td>RM {order.order_details.discount_amount.toFixed(2)}</td>
                                    </tr>
                                }
                                <tr>
                                    <td colSpan='2' style={{ fontWeight: 'bold', textAlign: 'right' }}>Total</td>
                                    <td>RM {order.order_details.total_amount.toFixed(2)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {!((order.status === 'Paid') || (order.status === 'Shipped')) &&
                        <Fragment>
                            <div style={{ textAlign: 'center' }}>Please make payment to our Public Bank account</div>
                            <div style={{ textAlign: 'center' }}>Account Name: U-TECH ENTERPRISE, Account Number: 381 623 1200 and contact us upon payment made.</div>
                        </Fragment>
                    }
                    <div className='document-footer'>
                        <p>Computer Generated. No Signature Required.</p>
                    </div>
                </div>
            </Container>
        );
    }
}

export default Invoice;