import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Row, Col, Modal, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withAlert } from 'react-alert';
import { DotLoader } from 'react-spinners';
import { showLoginModal } from '../../../actions';
import Login from './Login';
import './UtechHeader.css';

// Image assets
import logo from './logo.png';
import onlineprint from './print.png';
import signupimage from './sign-up-icon.jpg';
import iconchat from './icon-chat.png';
import icondate from './icon-date.png';
import iconcart from './icon-cart.png';

class UtechHeader extends Component {

    state = {
        theme: null,
        dropdownOpen: false
    }

    componentDidUpdate(prevProps) {
        if ((this.props.customer !== prevProps.customer) && (this.props.customer)) {
            if (this.props.customer.status !== 'ACTIVE') {
                this.props.firebaseApp.auth().signOut();
                this.props.history.push('/');
            }
        }

        if ((this.props.loginError !== prevProps.loginError) && (this.props.loginError)) {
            if (this.props.loginError === 'User not found!') {
                this.props.history.push('/sign-up');
            } else {
                const alert = this.props.alert.show(this.props.loginError, {
                    timeout: 3000,
                    type: 'error'
                });
            }
        }
    }

    toggleDropdown = () => {
        this.setState({ dropdownOpen: !this.state.dropdownOpen });
    }

    toggleLoginModal = (evt) => {
        evt.preventDefault();
        this.props.showLoginModal(!this.props.loginModal);
    }

    toggleAccountOpen = () => {
        this.setState({ accountOpen: !this.state.accountOpen });
    }

    handleLogout = (evt) => {
        evt.preventDefault();
        this.props.firebaseApp.auth().signOut();
        this.props.history.push('/');
    }

    handleCart = (evt) => {
        evt.stopPropagation();
        evt.preventDefault();
        this.props.history.push('/cart');
    }

    resetTheme = (evt) => {
        evt.preventDefault();
        this.setState({ theme: null });
    }

    chooseTheme = (theme, evt) => {
        evt.preventDefault();
        this.setState({ theme });
    }

    render() {
        const { theme, dropdownOpen } = this.state;
        const { cart, customer, loginModal, loading, order, loginSuccessHandle, loginError } = this.props;
        const themeClass = theme ? theme.toLowerCase() : 'secondary';

        console.log(customer);

        return (
            <Container className="utech-header text-center">
                <Modal className="loadingModal" isOpen={loading}>
                    <ModalBody className="loadingBody">
                        <Row>
                            <Col></Col>
                            <Col xs="auto"><DotLoader color={'#36D7B7'} size={60} loading={true} /></Col>
                            <Col></Col>
                        </Row>
                    </ModalBody>
                </Modal>
                {loginModal && <Login isOpen={loginModal} successHandle={loginSuccessHandle} />}
                <Row>
                    <Col className="mt-3 mt-lg-5 mb-3">
                        <a className="float-none float-lg-left" href="/"><img src={logo} title="U-Tech Enterprise" alt="U-Tech Enterprise" style={{ height: '150px', width: 'auto' }} /></a>
                        <img className="float-right d-none d-lg-block" src={onlineprint} title="online-print" alt="Online Print" style={{ height: '100px', width: 'auto' }} />
                        <div className="float-none float-lg-right mt-4 mt-lg-2 signup-group">
                            <img className="d-none d-md-inline-block group" src={signupimage} title="free-signup" alt="Free Signup" />
                            <div className="auth-links">
                                {(customer === null) && <a href="#" onClick={this.toggleLoginModal.bind(this)}>log in / sign up / reset password</a>}
        {(customer !== null) && <span>You are logged in as <Link to="/my-account">{customer.email} (RM{customer.balance.value.toFixed(2)})</Link> | <a href="#" onClick={this.handleLogout.bind(this)}>LOGOUT</a></span>}
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="mt-2 mt-md-3 mt-xl-0 mb-3">
                        <div className="float-none float-lg-right">
                            <img className='mr-2 header-cart' src={iconcart} title="cart" alt="Cart" onClick={this.handleCart.bind(this)} />
                            <span className='header-cart' onClick={this.handleCart.bind(this)}>Cart - {cart.items.length} item(s)</span>
                            <img className="ml-3 mr-2" src={iconchat} title="chat" alt="Chat" />
                            <span>+604-502 3256</span>
                            <img className="ml-3 mr-2" src={icondate} title="date" alt="Date" />
                            <span>{(new Date()).toDateString()}</span>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = ({ main, product, cart, checkout }) => {
    const { customer, loginModal, loginSuccessHandle, loading, firebaseApp, loginError } = main;
    const { prod_cat } = product;
    const { order } = checkout;
    return { prod_cat, customer, cart, loginModal, loginSuccessHandle, loading, firebaseApp, order, loginError };
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        showLoginModal: showLoginModal
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withAlert(UtechHeader));
