import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import { checkArray } from '../Utility'
/**
 * FormGroup for Size configuration
 * 
 * @param {Object} object - Name of the configuration. Used to identify which configuration has changed.
 * @param {Object} style - Style
 */
export default class JsonDisplay extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Fragment>
                <pre style={this.props.style}>
                <code>
                {JSON.stringify(this.props.object, null, 4)}
                </code>
                </pre>
            </Fragment>
            
        )
    }
}