import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { registerFirebase } from '../../actions';
import './App.css';

import UtechHeader from '../Layouts/UtechHeader/UtechHeader';
import UtechNavbar from '../Layouts/UtechNavbar/UtechNavbar';
import ScrollToTop from './ScrollToTop';
// import LoginPage from '../Pages/Account/LoginPage';
// import SignUpPage from '../Pages/Account/SignUpPage';
// import ForgotPasswordPage from '../Pages/Account/ForgotPasswordPage';
import HomePage from '../Pages/Home/HomePage';
import AboutPage from '../Pages/About/AboutPage';
import ProductPage from '../Pages/Products/ProductPage';
import CustomQuoteBookletPage from '../Pages/CustomQuoteBooklet/CustomQuoteBookletPage';
import CustomQuoteGeneralPage from '../Pages/CustomQuoteGeneral/CustomQuoteGeneralPage';
import CustomQuoteEnquiryPage from '../Pages/CustomQuoteEnquiry/CustomQuoteEnquiryPage';
import FaqsPage from '../Pages/Faqs/FaqsPage';
import HowWeDoPage from '../Pages/HowWeDo/HowWeDoPage';
import HowToTopUpAccountPage from '../Pages/HowToTopUpAccount/HowToTopUpAccountPage';
import HowToOrderPage from '../Pages/HowToOrder/HowToOrderPage';
import HowToCountProcessDayPage from '../Pages/HowToCountProcessDay/HowToCountProcessDayPage';
import HowToJoinPage from '../Pages/HowToJoin/HowToJoinPage';
import ArtWorkSpecificationPage from '../Pages/ArtWorkSpecification/ArtWorkSpecificationPage';
import ProductSizeTemplatePage from '../Pages/ProductSizeTemplate/ProductSizeTemplatePage';
import ProductSpecificationsPage from '../Pages/ProductSpecifications/ProductSpecificationsPage';
import ContactFormPage from '../Pages/ContactForm/ContactFormPage';
import UtechFooter from '../Layouts/UtechFooter/UtechFooter';
import TermsOfUse from '../Pages/TermsAndPolicies/TermsOfUse';
import PrivacyPolicy from '../Pages/TermsAndPolicies/PrivacyPolicy';
import CartPage from '../Pages/Cart/Cart';
import Checkout from '../Pages/Checkout/Checkout';
import ThankYou from '../Pages/Checkout/ThankYou';
import Acknowledge from '../Pages/Acknowledge/Acknowledge';
import MyAccount from "../Pages/Account/MyAccount";
import SignUpPage from "../Pages/Account/SignUpPage";
import SignUpAck from "../Pages/Account/SignUpAck";
import Invoice from '../Pages/Invoice/Invoice';
import PasswordReset from '../Layouts/UtechHeader/PasswordReset';
//
class App extends Component {
  divStyle = {
    height: '500px',
    backgroundColor: 'gray',
  };

  constructor(props) {
    super(props);

    this.props.registerFirebase();
  }

  componentDidUpdate(prevProps) {
    if (this.props.customer !== prevProps.customer) {
      if (this.props.customer && (this.props.customer.status === 'INCOMPLETE')) {
        let signUpURL = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/sign-up';
        if (window.location.href !== signUpURL) window.location.href = signUpURL;
      }
    }
  }

  render() {
    return (
      <Router basename="/">
        <div className="App">
          <Route component={UtechHeader} />
          <Route component={UtechNavbar} />
          <div className="page-content">
            <Switch>
              <ScrollToTop>
                <Route exact path="/" component={HomePage} />
                <Route path="/about-us" component={AboutPage} />
                <Route path="/terms-of-use" component={TermsOfUse} />
                <Route path="/privacy-policy" component={PrivacyPolicy} />
                <Route path="/products/:type" component={ProductPage} />
                <Route path="/custom-quote-booklet" component={CustomQuoteBookletPage} />
                <Route path="/custom-quote-general" component={CustomQuoteGeneralPage} />
                <Route path="/custom-quote-enquiry" component={CustomQuoteEnquiryPage} />
                <Route path="/faqs" component={FaqsPage} />
                <Route path="/how-we-do" component={HowWeDoPage} />
                <Route path="/how-to-top-up-account" component={HowToTopUpAccountPage} />
                <Route path="/how-to-order" component={HowToOrderPage} />
                <Route path="/how-to-count-process-day" component={HowToCountProcessDayPage} />
                <Route path="/how-to-join" component={HowToJoinPage} />
                <Route path="/art-work-specification" component={ArtWorkSpecificationPage} />
                <Route path="/product-size-template" component={ProductSizeTemplatePage} />
                <Route path="/product-specifications" component={ProductSpecificationsPage} />
                <Route path="/contact-form" component={ContactFormPage} />
                <Route path="/cart" component={CartPage} />
                <Route path="/checkout" component={Checkout} />
                <Route path="/thankyou" component={ThankYou} />
                <Route path="/acknowledge" component={Acknowledge} />
                <Route path="/my-account" component={MyAccount} />
                <Route path="/invoice" component={Invoice} />
                <Route path="/reset-password" component={PasswordReset} />
                <Route path="/sign-up" component={SignUpPage} />
                <Route path="/sign-up-ack" component={SignUpAck} />
              </ScrollToTop>
            </Switch>
          </div>
          <UtechFooter />
        </div>
      </Router>
    );
  }
}

const mapStateToProps = ({ main }) => {
  const { customer } = main;
  return { customer };
}

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators({
    registerFirebase: registerFirebase,
  }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(App);
