import React, { Component } from 'react';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

import booklet from '../../../assets/product-guide/booklet.png';
import brochure from '../../../assets/product-guide/brochure.png';
import businessCard from '../../../assets/product-guide/business-card.png';
import flyer from '../../../assets/product-guide/flyer.png';
import plasticCard from '../../../assets/product-guide/plastic-card.png';
import cdLabel from '../../../assets/product-guide/cd-label.png';

import './ProductSpecificationsPage.css';

class ProductSpecificationsPage extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Container className="my-3">
                <Row>
                    <Col>
                        <h1 className="my-2">Product Specifications</h1>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col className="text-center">
                        <a href={`${process.env.PUBLIC_URL}/files/product-specs/Booklet.pdf`} download>
                            <img className="product-image" src={booklet} alt="booklet" />
                            <p>Booklet</p>
                        </a>
                    </Col>
                    <Col className="text-center">
                        <a href={`${process.env.PUBLIC_URL}/files/product-specs/Brochure.pdf`} download>
                            <img className="product-image" src={brochure} alt="brochure" />
                            <p>Brochure</p>
                        </a>
                    </Col>
                    <Col className="text-center">
                        <a href={`${process.env.PUBLIC_URL}/files/product-specs/BusinessCard.pdf`} download>
                            <img className="product-image" src={businessCard} alt="business-card" />
                            <p>Business Card</p>
                        </a>
                    </Col>
                    <Col className="text-center">
                        <a href={`${process.env.PUBLIC_URL}/files/product-specs/Flyer.pdf`} download>
                            <img className="product-image" src={flyer} alt="flyer" />
                            <p>Flyer</p>
                        </a>
                    </Col>
                    {/* <Col className="text-center">
                        <a href={`${process.env.PUBLIC_URL}/files/product-specs/PlasticCard.pdf`} download>
                            <img className="product-image" src={plasticCard} alt="plastic-card" />
                            <p>Plastic Card</p>
                        </a>
                    </Col>
                    <Col className="text-center">
                        <a href={`${process.env.PUBLIC_URL}/files/product-specs/CdLabel.pdf`} download>
                            <img className="product-image" src={cdLabel} alt="cd-label" />
                            <p>Cd Label</p>
                         </a>
                    </Col> */}
                </Row>
                <Row>
                    <Col>
                        <h2>Booklet</h2>
                        <p>
                            Booklet consisting of a few sheets which is binded to with paper covers. We provide two types binding services: <br />
                            Saddle Stitching - Booklet folded sheets are stapled together through the fold line with wire staples. <br />
                            Perfect Binding - The pages and cover are glued together at the spine with a strong thermal glue.
                        </p>

                        <h2>Brochure</h2>
                        <p>
                            Brochure is an informative paper document (often also used for advertising), that can be folded into a template, pamphlet or leaflet. Brochures served as an advertising tools to introduce a company or organization and inform about products and/or services to a target audience. Check out for our folding styles in product specifications.
                        </p>

                        <h2>Business Card</h2>
                        <p>
                            Business cards are cards containing business information about a company or individual. A business card typically includes the giver's name, company or business affiliation (usually with a logo) and contact information such as street addresses, telephone number(s), fax number, e-mail addresses, website, social media addresses such as Facebook, LinkedIn and Twitter. Our business card printing consists of different types of paper materials and finishing for you to order.
                        </p>

                        <h2>Flyer</h2>
                        <p>
                            A flyer is a form of paper advertisement intended for wide distribution. Flyer normally used to advertise an event, promote services and announce an offer or promotion. Flyer is a loose sheet leaflet without folding.
                        </p>

                        {/* <h2>Plastic Card</h2>
                        <p>
                            We provide two types of plastic card - member card (0.76mm) and plastic business card (0.46mm). Member cards offer membership benefits to your customers. Standard finishing is available like magnetic stripe, embossed sequence number and signature panel. Our member card are manufactured with durable plastic Teslin and are laminated on both sides and they are digitally printed. Whereas, plastic business card is a special idea to present your company or individual because it is a plastic type business card. Plastic business card can give you transparency effect which is looks like a frosted card.
                        </p>

                        <h2>CD Label</h2>
                        <p>
                            All our CD Label comes with data. You need to supply us the data to input into the disc. Our CD Label consists of three types: <br />
                            CD-R + Sticker + Data - CD-R with a storage capacity of 700MB comes with sticker on top and data input. <br />
                            DVD-R + Sticker + Data - DVD-R with a storage capacity of 4.7GB comes with sticker on top and data input. <br />
                            DVD + Data - DVD with a storage capacity of 4.7GB come with data input. Label is printed directly on top of the disc by offset printing.
                        </p> */}
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default ProductSpecificationsPage;
