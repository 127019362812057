import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAlert } from 'react-alert';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Progress, Dropdown, Alert, Tooltip } from 'reactstrap';
import { getProductSetup, getProductTimeAndCost, resetProductTimeAndCost, uploadFiles, resetUploads, addToCart } from '../../../actions'
import './ProductPage.css';
import SizeFormGroup from './Components/SizeFormGroup';
import GenericDropdownsFormGroup from './Components/GenericDropdownsFormGroup';
import TimeAndCostFormGroup from './Components/TimeAndCostFormGroup';
import SelectFileFormGroup from './Components/SelectFileFormGroup';
import ManualInputFormGroup from './Components/ManualInputFormGroup';
import Specification from './Components/Specifications';
import LoadingIndicator from './Components/LoadingIndicator';
import imagecalendar from './Images/calendar-with-header.png';


class RacingHorseCalendar extends Component {
    constructor(props) {
        super(props);
        this.product = 'Racing Horse Calendar With Header';
        this.state = {
            /**
             * Dynamic options for certain configuration
             */
            dynamic_options: {
            },
            /**
             * Current configuration selected by end-user
             */
            configurations: {
                size: null,
                quantity: 0,
                print: null,
                header_material: null,
                inner_material: null,
                finishing: null,
                header_artwork: null,
                header_artwork_path: null,
                header_artwork_download_url: null,
                weight: 0.0
            },
            /**
             * Time and cost looked up based on configurations
             */
            price_and_duration: {
                price: 0.0,
                duration: 0
            },
            isLoadingData: true,
            tooltipOpen: false,
            tooltipMesg: '',
        };

        this.props.getProductSetup(this.product);
    }

    /**
     * Invoked immediately after a component is mounted (inserted into the tree).
     * Initialization that requires DOM nodes should go here.
     * If you need to load data from a remote endpoint, this is a good place to instantiate the network request.
     */
    componentDidMount() {
        document.title = `U-Tech - ${this.product}`
    }

    /**
     * Invoked immediately after updating occurs.
     * This method is not called for the initial render.
     * Use this as an opportunity to operate on the DOM when the component has been updated.
     * This is also a good place to do network requests as long as you compare the current props to previous props (e.g. a network request may not be necessary if the props have not changed).
     *
     * @param {*} previousProps Previous properties before this completed rendering cycle
     * @param {*} previousState Previous state before this already completed rendering cycle
     */
    componentDidUpdate(previousProps, previousState) {
        if ((previousProps.product.setup !== this.props.product.setup) && this.props.product.setup.options) {
            let newConfigurations = this.getDefaultConfigurations(this.props.product.setup.options);
            let newDynamicOptions = this.getDynamicOptions(newConfigurations, this.props.product.setup.options);

            // Make sure the state's default value synced up with the UI one during initial data load.
            let newQuantites = newDynamicOptions['quantity'];
            let currentQuantity = newConfigurations['quantity'];
            if (Array.isArray(newQuantites) && newQuantites.length > 0 && newQuantites.includes(parseInt(currentQuantity)) === false) {
                newConfigurations['quantity'] = newQuantites[0];
            }

            this.setState({
                dynamic_options: { ...this.props.product.setup.options, ...newDynamicOptions },
                configurations: newConfigurations,
                isLoadingData: false
            });
        }

        if (previousState.configurations !== this.state.configurations) {
            let newConfig = this.state.configurations;
            let newPriceAndDuration = this.state.price_and_duration;

            // Get the correct options based on the latest configurations
            let newDynamicOptions = this.getDynamicOptions(newConfig, this.props.product.setup.options);

            // Make sure the state's default value synced up with the UI one during initial data load.
            let newQuantities = newDynamicOptions['quantity'];
            let currentQuantity = newConfig['quantity'];
            if (Array.isArray(newQuantities) && newQuantities.length > 0 && newQuantities.includes(parseInt(currentQuantity)) === false) {
                newConfig['quantity'] = newQuantities[0];
            }

            let variant = this.props.product.setup.variants.find(variant => (variant.size === newConfig.size.type));
            // Find the right price
            if (variant) {
                let item = variant.prices.find(item => (item.quantity == newConfig.quantity))
                if (item) {
                    newPriceAndDuration.price = item.Price;
                    newPriceAndDuration.duration = item.duration;
                    newConfig['weight'] = item.weight;
                } else {
                    newPriceAndDuration.price = 0.0;
                    newPriceAndDuration.duration = 0;
                    newConfig['weight'] = 0.0;
                }
            }

            this.setState({
                dynamic_options: newDynamicOptions,
                price_and_duration: newPriceAndDuration,
                configurations: newConfig
            });
        }
        let checkWidth = this.state.configurations.size;
        console.log(checkWidth);

        if (previousProps.cart.upload_count !== this.props.cart.upload_count) {
            if ((this.props.cart.total_uploads > 0) && (this.props.cart.total_uploads === this.props.cart.upload_count)) {
                let newConfig = this.state.configurations;
                newConfig.header_artwork = this.props.cart.header_artwork;
                newConfig.header_artwork_path = this.props.cart.header_artwork_path;
                let newDynamicOptions = this.getDynamicOptions(newConfig, this.props.product.setup.options);
                this.setState({
                    dynamic_options: newDynamicOptions,
                    configurations: newConfig
                });
            }
        }
    }

    /**
     * Generate configurations with default value, based on the provided options
     *
     * @param {*} productOptions Product options
     *
     * @returns {Object} configurations with default value
     */
    getDefaultConfigurations(productOptions) {
        let newConfigurations = { ...this.state.configurations }

        const stateConfigKeys = Object.keys(this.state.configurations);
        const optionKeys = Object.keys(productOptions);

        stateConfigKeys.forEach(name => {
            if (optionKeys.includes(name)) {
                let defaultValue = null;

                if (['header_artwork', 'header_artwork_path', 'header_artwork_download_url'].includes(name)) {
                    // Use null as default
                    defaultValue = null;
                } else if (name === 'quantity') {
                    defaultValue = 0;
                } else {
                    // Use first value as default
                    defaultValue = productOptions[name].values[0];
                }

                newConfigurations[name] = defaultValue;
            }
        });

        return newConfigurations
    }

    /**
     * Get latest dynamic options based on the provided configurations
     *
     * @param {Object} productConfigurations Configurations
     * @param {Object} productOptions Product options. If not supplied, component's props.product.setup.options will be used.
     * @param {Object} productVariants Product variants. If not supplied, component's props.product.setup.variants will be used.
     *
     * @returns {Object} New dynamic options. Return existing state's dynamic options if no static product data is available.
     */
    getDynamicOptions(productConfigurations, productOptions = this.props.product.setup.options, productVariants = this.props.product.setup.variants) {
        // stop if we do not have the data
        if (productOptions == undefined) {
            console.log("Skip update dynamic options due to no data")
            return this.state.dynamic_options;
        }

        let newDynamicOptions = { ...this.state.dynamic_options };

        // quantity depends on header_finishing + size
        let selectedVariant = productVariants.find((variant) => {
            return (variant.size === productConfigurations.size.type);
        });
        // let dynQuantityOptions = (selectedVariant) ? [0, ...selectedVariant.quantity] : [0];
        let dynQuantityOptions = (selectedVariant) ? [...selectedVariant.quantity] : [];

        newDynamicOptions.quantity = dynQuantityOptions;

        return newDynamicOptions;
    }

    /**
     * Handle the input change event.
     * Configuration & options change resulted by the event will be performed here.
     *
     * @param {*} e eventData
     */
    handleInputChange(e) {
        let futureConfigs = { ...this.state.configurations };

        if (e.target.type === 'checkbox') {
            // Use checked value instead of normal value
            futureConfigs[e.target.name] = e.target.checked;
        } else {
            // everything else just let it slide
            futureConfigs[e.target.name] = e.target.value;
        }

        if (e.target.name === 'size') {
            // convert json string to obj
            futureConfigs.size = JSON.parse(e.target.value)
        }

        let futureDynOptions = this.getDynamicOptions(futureConfigs, this.props.product.setup.options);
        // if (e.target.name === 'header_finishing') {
        //     futureConfigs['quantity'] = futureDynOptions['quantity'] ? futureDynOptions['quantity'][0] : null;
        // }

        this.setState({
            dynamic_options: futureDynOptions,
            configurations: futureConfigs
        });
    }

    /**
     * Handle file selection event.
     * Acquires token from backend and upload files to AliCloud storage.
     *
     * @param {*} e eventData
     */
    handleFileSelect(type, e) {
        e.stopPropagation();
        e.preventDefault();
        let files = e.target.files;

        console.log(files);
        if (files.length > 0) {
            this.props.uploadFiles(files, type);
        }
    }

    /**
     * Generate DOM elements based on product options.
     *
     * @param {string} configName Name of the configuration
     * @param {object} configSettings Configuration data
     */
    generateProductOptionElement(configName, configSettings) {
        const { options } = this.props.product.setup;
        const { configurations, dynamic_options } = this.state;
        // console.log(configName);

        switch (configName) {


            case 'size':
                // static options
                return (
                    <Fragment>
                        <SizeFormGroup
                            key={configName}
                            name={configName}
                            label={configSettings.label}
                            itemsSource={options[configName].values}
                            selectedItem={configurations[configName]}
                            onChange={this.handleInputChange.bind(this)}

                        />
                        {(configurations.size.width === 127) ? (
                            <FormGroup row style={{ marginTop: '-10px', marginBottom: '-8px' }}>
                                <Col sm={3}></Col >
                                <Col sm={9}>
                                    <Alert color="secondary" style={{ paddingTop: '1px', paddingBottom: '1px' }}>
                                        <p style={{ paddingTop: "5px", margin: '0px', paddingBottom: "5px" }}>HEADER 127MM X 133.3MM</p>
                                    </Alert>
                                </Col>
                            </FormGroup>
                        )
                            : (
                                <FormGroup row style={{ marginTop: '-10px', marginBottom: '-8px' }}>
                                    <Col sm={3}></Col >
                                    <Col sm={9}>
                                        <Alert color="secondary" style={{ paddingTop: '1px', paddingBottom: '1px' }}>
                                            <p style={{ paddingTop: "5px", margin: '0px', paddingBottom: "5px" }}>HEADER 267MM X 212.7MM</p>
                                        </Alert>
                                    </Col>
                                </FormGroup>
                            )
                        }
                    </Fragment>

                )

            case 'header_material':
            case 'inner_material':
            case 'print':
            case 'finishing':
                // STATIC dropdown options
                return (
                    <GenericDropdownsFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        onChange={this.handleInputChange.bind(this)}
                        itemsSource={options[configName].values}
                        selectedItem={configurations[configName]}
                    />
                )

            case 'header_artwork':
                return (
                    <SelectFileFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        accept=".pdf, .rar, .zip"
                        onChange={this.handleFileSelect.bind(this, 'calendar_header')}
                    />
                )

            case 'header_artwork_download_url':
                return (
                    <ManualInputFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        value={configurations[configName]}
                        inputAttributes={{ placeholder: 'ARTWORK DOWNLOAD URL', style: { textTransform: 'none' } }}
                        onChange={this.handleInputChange.bind(this)}
                    />
                )

            case 'quantity':
                // DYNAMIC dropdown options
                return (
                    <GenericDropdownsFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        onChange={this.handleInputChange.bind(this)}
                        itemsSource={dynamic_options[configName]}
                        selectedItem={configurations[configName]}
                    />
                )
            default:
                break;
        }
    }

    /**
     * Check if the required configurations is supplied.
     *
     * @returns True if all required configurations is supplied. False otherwise.
     */
    checkRequiredConfigurations() {
        var missingConfigurations = [];

        // Add checks for required configuration here
        if (this.state.configurations['header_artwork_path'] == null && (
            this.state.configurations['header_artwork_download_url'] == null ||
            this.state.configurations['header_artwork_download_url'] == ""
        )) {
            missingConfigurations.push("Artwork");
        }

        if (missingConfigurations.length > 0) {
            window.alert(`Please supply the missing information: ${missingConfigurations.join(", ")}.`);
            return false;
        }

        if ((this.state.price_and_duration.price <= 0) || (this.state.price_and_duration.duration <= 0)) {
            window.alert(`Invalid product configurations.`);
            return false;
        }

        // everything OK
        return true;
    }

    addToCart() {
        if (this.checkRequiredConfigurations() === false) {
            return;
        }

        let config = { ...this.state.configurations }
        Object.entries(config).forEach(pair => {
            if (pair[1] == null) config[pair[0]] = undefined;
            if (typeof pair[1] === 'boolean') {
                config[pair[0]] = (pair[1]) ? 'Yes' : 'No';
            }
        });

        this.props.addToCart({
            product: this.product,
            configurations: config,
            price: this.state.price_and_duration.price,
            duration: this.state.price_and_duration.duration
        });

        const alert = this.props.alert.show('Item added to cart', {
            timeout: 3000,
            type: 'success',
        });

        this.props.history.push('/cart');
    }

    toggleTooltip(open, e) {
        const { configurations } = this.state;

        if (configurations.size.width === 127) {
            this.setState({
                tooltipMesg: 'Product Out Of Stock!',
                tooltipOpen: open
            });
        }
    }

    render() {
        const { setup } = this.props.product;
        const { upload_started, upload_count, upload_filename, total_uploads } = this.props.cart;
        const { configurations, dynamic_options, price_and_duration, isLoadingData, tooltipOpen, tooltipMesg } = this.state;

        if (isLoadingData === true) {
            return (
                <Container>
                    <LoadingIndicator />
                </Container>
            )
        }

        return (
            <Container>
                <Modal isOpen={upload_started} className='scrolling'>
                    <ModalHeader>File Upload</ModalHeader>
                    <ModalBody>
                        {
                            (total_uploads === 0)
                                ? (
                                    <div>
                                        <span>Contacting server . . . <br /><br /></span>
                                        <Progress multi>
                                            <Progress bar animated value={10} />
                                        </Progress>
                                    </div>
                                )
                                : ((total_uploads < 0)
                                    ? (<span>Upload Failed! <br /><br /><br /></span>)
                                    : ((upload_count === total_uploads)
                                        ? (
                                            <div>
                                                <span>Upload Completed!<br /><br /></span>
                                                <Progress multi>
                                                    <Progress bar color="success" value={100} > 100% </Progress>
                                                </Progress>
                                            </div>
                                        )
                                        : (
                                            <div>
                                                <span>Uploading <i>{upload_filename}</i><br /><br /></span>
                                                <Progress multi>
                                                    <Progress bar animated value={20} />
                                                </Progress>
                                            </div>
                                        )))
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color='success' onClick={() => this.props.resetUploads()} disabled={(total_uploads === 0) || (upload_count !== total_uploads)}>Close</Button>
                    </ModalFooter>
                </Modal>
                {(setup && dynamic_options && configurations) &&
                    <Fragment>
                        <h1 id="product-digital-book" style={{ display: "flex", alignDirection: "column", fontSize: "2.5em" }}>RACING HORSE CALENDAR WITH HEADER <img src={imagecalendar} style={{ height: "220px", paddingRight: "10px" }} /> </h1>
                        <Form className="product-info">
                            {setup.options &&
                                Object.entries(setup.options).map((entry) => {
                                    let configName = entry[0];
                                    let configSettings = entry[1];
                                    return this.generateProductOptionElement(configName, configSettings);
                                })
                            }
                            {
                                (configurations.quantity > 0) && (
                                    <Fragment>
                                        <TimeAndCostFormGroup
                                            price={price_and_duration.price}
                                            processDay={price_and_duration.duration} />
                                        {(configurations.size.width === 127) ?
                                            <div>
                                                <Col sm={16}>
                                                    <Alert color="warning" style={{ paddingTop: '1px', paddingBottom: '1px' }}>
                                                        <p style={{ paddingTop: "5px", margin: '0px', paddingBottom: "5px" }}>Calendar Size 127MM X 133MM is Out Of Stock!</p>
                                                    </Alert>
                                                </Col>
                                                <Tooltip placement="top" isOpen={tooltipOpen} target="cartbutton">
                                                    {tooltipMesg}
                                                </Tooltip>
                                                <Button
                                                    id="cartbutton" className="button checkout-disabled"
                                                    onClick={this.toggleTooltip.bind(this, true)}
                                                    onMouseOver={this.toggleTooltip.bind(this, true)}
                                                    onMouseOut={this.toggleTooltip.bind(this, false)}>ADD TO CART</Button>
                                            </div>
                                            :
                                            <Button onClick={this.addToCart.bind(this)}>ADD TO CART</Button>
                                        }
                                    </Fragment>
                                )
                            }
                        </Form>
                    </Fragment>
                }
            </Container>
        )
    }
}

const mapStateToProps = ({ product, cart }) => {
    return { product, cart };
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getProductSetup: getProductSetup,
        getProductTimeAndCost: getProductTimeAndCost,
        resetProductTimeAndCost: resetProductTimeAndCost,
        uploadFiles: uploadFiles,
        resetUploads: resetUploads,
        addToCart: addToCart
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withAlert(RacingHorseCalendar));
