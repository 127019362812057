import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAlert } from 'react-alert';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Progress } from 'reactstrap';
import { getProductSetup, getProductTimeAndCost, resetProductTimeAndCost, uploadFiles, resetUploads, addToCart } from '../../../actions';
import './ProductPage.css';
import SizeFormGroup from './Components/SizeFormGroup';
import GenericDropdownsFormGroup from './Components/GenericDropdownsFormGroup';
import OptionalGenericDropdownsFormGroup from './Components/OptionalGenericDropdownsFormGroup';
import OrientationFormGroup from './Components/OrientationFormGroup';
import FoldingCreasingFormGroup from './Components/FoldingCreasingFormGroup';
import HotStampingFormGroup from './Components/HotStampingFormGroup';
import RoundCornerFormGroup from './Components/RoundCornerFormGroup';
import TimeAndCostFormGroup from './Components/TimeAndCostFormGroup';
import SelectFileFormGroup from './Components/SelectFileFormGroup';
import JsonDisplay from './Components/JsonDisplay'
import { isObjValueEqual, matchCurrentConfigToVariant, readFileToBase64StringPromise, productSpecUrl, productPriceUrl } from "./Utility";
import Specification from './Components/Specifications';
import LoadingIndicator from './Components/LoadingIndicator';
import ManualInputFormGroup from './Components/ManualInputFormGroup';

class OffsetBusinessCard extends Component {
    constructor(props) {
        super(props);
        this.product = 'Offset Business Card';
        this.state = {
            /**
             * Dynamic options for certain configuration
             */
            dynamic_options: {
                lamination: [],
                folding: [],
                spot_uv: [],
                round_corner: [],
                hole_punching: [],
                print: []
            },
            /**
             * Current configuration selected by end-user
             */
            configurations: {
                size: null,
                orientation: null,
                material: null,
                quantity: 0,
                print: null,
                lamination: null,
                folding: null,
                custom_creasing: false,
                creasing: 10,
                creasing_remains: 0,
                spot_uv: null,
                hot_stamping: null,
                hot_stamping_colour_1: null,
                hot_stamping_colour_2: null,
                round_corner: null,
                round_corner_type: null,
                hole_punching: null,
                cover_artwork: null,
                cover_artwork_path: null,
                cover_artwork_download_url: null,
                weight: 0.0
            },
            /**
             * Time and cost looked up based on configurations
             */
            price_and_duration: {
                price: 0.0,
                duration: 0
            },
            isLoadingData: true
        };

        this.props.getProductSetup(this.product);
    }

    /**
     * Invoked immediately after a component is mounted (inserted into the tree).
     * Initialization that requires DOM nodes should go here.
     * If you need to load data from a remote endpoint, this is a good place to instantiate the network request.
     */
    componentDidMount() {
        document.title = `U-Tech - ${this.product}`
    }

    /**
     * Invoked immediately after updating occurs.
     * This method is not called for the initial render.
     * Use this as an opportunity to operate on the DOM when the component has been updated.
     * This is also a good place to do network requests as long as you compare the current props to previous props (e.g. a network request may not be necessary if the props have not changed).
     *
     * @param {*} previousProps Previous properties before this completed rendering cycle
     * @param {*} previousState Previous state before this already completed rendering cycle
     */
    componentDidUpdate(previousProps, previousState) {
        if ((previousProps.product.setup !== this.props.product.setup) && this.props.product.setup.options) {
            let newConfigurations = this.getDefaultConfigurations(this.props.product.setup.options);
            let newDynamicOptions = this.getDynamicOptions(newConfigurations, this.props.product.setup.options);

            // Make sure the state's default value synced up with the UI one during initial data load.
            let newQuantities = newDynamicOptions['quantity'];
            let currentQuantity = newConfigurations['quantity'];
            if (Array.isArray(newQuantities) && newQuantities.length > 0 && newQuantities.includes(parseInt(currentQuantity)) === false) {
                newConfigurations['quantity'] = newQuantities[0];
            }

            this.setState({
                dynamic_options: { ...this.props.product.setup.options, ...newDynamicOptions },
                configurations: newConfigurations,
                isLoadingData: false
            });
        }

        if (previousState.configurations !== this.state.configurations) {
            let newConfig = this.state.configurations;
            let newPriceAndDuration = this.state.price_and_duration;

            // Get the correct options based on the latest configurations
            let newDynamicOptions = this.getDynamicOptions(newConfig, this.props.product.setup.options);
            if (previousState.configurations.size !== this.state.configurations.size) {

                // Select the first valid options, ONLY IF the current selected is not a valid option
                if (newDynamicOptions.folding.includes(newConfig.folding) === false) {
                    newConfig.folding = newDynamicOptions.folding[0];
                }

                if (newDynamicOptions.round_corner.includes(newConfig.round_corner) === false) {
                    newConfig.round_corner = newDynamicOptions.round_corner[0];
                    newConfig.round_corner_type = null;
                }

                if (newDynamicOptions.hole_punching.includes(newConfig.hole_punching) == false) {
                    newConfig.hole_punching = newDynamicOptions.hole_punching[0];
                }
            }


            // Re-query for potential drop-down options, if the configurations is changed due to default settings
            newDynamicOptions = this.getDynamicOptions(newConfig, this.props.product.setup.options);

            // Make sure the state's default value synced up with the UI one during initial data load.
            let newQuantities = newDynamicOptions['quantity'];
            let currentQuantity = newConfig['quantity'];
            if (Array.isArray(newQuantities) && newQuantities.length > 0 && newQuantities.includes(parseInt(currentQuantity)) === false) {
                newConfig['quantity'] = newQuantities[0];
            }

            // Find the right variant
            let variant = this.props.product.setup.variants.find(variant =>
                (variant.material === newConfig.material)
                && (variant.lamination === newConfig.lamination)
                && (variant.spot_uv === newConfig.spot_uv)
                && (variant.folding === newConfig.folding)
            );

            console.log(variant);
            console.log(this.state.configurations);

            // Find the right price
            if (variant && this.state.configurations.print) {
                let item = variant.prices.find(item => (item.quantity == newConfig.quantity))
                if (item) {
                    newPriceAndDuration.price = item[newConfig.print.replace(' ', '_')];
                    newPriceAndDuration.duration = variant.duration;

                    if (this.state.configurations.round_corner !== 'None') {
                        newPriceAndDuration.price += item.RoundCorner_PunchHole;
                        newPriceAndDuration.duration += variant.duration_RC_PH;
                    }

                    if (this.state.configurations.hole_punching !== 'None') {
                        newPriceAndDuration.price += item.RoundCorner_PunchHole;
                        newPriceAndDuration.duration += variant.duration_RC_PH;
                    }

                    if (this.state.configurations.hot_stamping !== 'None') {
                        newPriceAndDuration.price += item.HotStamping;
                        newPriceAndDuration.duration += variant.duration_HS;
                        if (this.state.configurations.hot_stamping.includes('2C')) {
                            newPriceAndDuration.price += item.HotStamping;
                        }
                    }

                } else {
                    newPriceAndDuration.price = 0.0;
                    newPriceAndDuration.duration = 0;
                }
            }

            console.log(newPriceAndDuration);

            this.setState({
                dynamic_options: newDynamicOptions,
                price_and_duration: newPriceAndDuration,
                configurations: newConfig
            });
        }

        if (previousProps.cart.upload_count !== this.props.cart.upload_count) {
            if ((this.props.cart.total_uploads > 0) && (this.props.cart.total_uploads === this.props.cart.upload_count)) {
                let newConfig = this.state.configurations;
                newConfig.cover_artwork = this.props.cart.cover_artwork;
                newConfig.cover_artwork_path = this.props.cart.cover_artwork_path;
                let newDynamicOptions = this.getDynamicOptions(newConfig, this.props.product.setup.options);
                this.setState({
                    dynamic_options: newDynamicOptions,
                    configurations: newConfig
                });
            }
        }
    }

    /**
     * Generate configurations with default value, based on the provided options
     *
     * @param {*} productOptions Product options
     *
     * @returns {Object} configurations with default value
     */
    getDefaultConfigurations(productOptions) {
        let newConfigurations = { ...this.state.configurations }

        let defaultVariant = (this.props.product.setup.variants) ? this.props.product.setup.variants[0] : [];
        const defaultVariantKeys = Object.keys(defaultVariant);
        const stateConfigKeys = Object.keys(this.state.configurations);
        const optionKeys = Object.keys(productOptions);

        stateConfigKeys.forEach(name => {
            if (defaultVariantKeys.includes(name)) {
                newConfigurations[name] = Array.isArray(defaultVariant[name]) ? defaultVariant[name][0] : defaultVariant[name];
            } else if (optionKeys.includes(name)) {
                let defaultValue = null;

                if (['cover_artwork', 'cover_artwork_path', 'cover_artwork_download_url'].includes(name)) {
                    // Use null as default
                    defaultValue = null;
                } else if (['folding', 'lamination', 'spot_uv'].includes(name)) {
                    // Use first value's type as default
                    defaultValue = productOptions[name].values[0].type;
                } else if (name === 'quantity') {
                    defaultValue = 0;
                } else {
                    // Use first value as default
                    defaultValue = productOptions[name].values[0];
                }

                newConfigurations[name] = defaultValue;
            }
        });

        return newConfigurations;
    }

    /**
     * Get latest dynamic options based on the provided configurations
     *
     * @param {Object} productConfigurations Configurations
     * @param {Object} productOptions Product options. If not supplied, component's props.product.setup.options will be used.
     * @param {Object} productVariants Product variants. If not supplied, component's props.product.setup.variants will be used.
     *
     * @returns {Object} New dynamic options. Return existing state's dynamic options if no static product data is available.
     */
    getDynamicOptions(productConfigurations, productOptions = this.props.product.setup.options, productVariants = this.props.product.setup.variants) {
        // stop if we do not have the data
        if (productOptions == undefined) {
            console.log("Skip update dynamic options due to no data")
            return this.state.dynamic_options;
        }

        let newDynamicOptions = { ...this.state.dynamic_options };

        // material choices affects laminations & spot uv choices
        const selectedMaterial = productConfigurations.material;
        // material -> lamination
        const staticLaminationOptions = productOptions.lamination;
        let dynLaminationOptions = []
        staticLaminationOptions.values.forEach((element, index) => {
            if (element.material.includes(selectedMaterial)) {
                dynLaminationOptions.push(element.type);
            }
        })
        newDynamicOptions.lamination = dynLaminationOptions;

        // material -> spot uv
        const staticSpotUvOptions = productOptions.spot_uv;
        let dynSpotUvOptions = [];
        staticSpotUvOptions.values.forEach((element, index) => {
            if (element.material.includes(selectedMaterial)) {
                dynSpotUvOptions.push(element.type);
            }
        })
        newDynamicOptions.spot_uv = dynSpotUvOptions;

        // lamination -> spot uv
        const selectedLamination = productConfigurations.lamination;
        if (selectedLamination.toUpperCase().includes('GLOSS')) {
            newDynamicOptions.spot_uv = ['None'];
        }

        // material -> print (Frosted Plastic Card can only print 1 side)
        const staticPrintOptions = productOptions.print;
        let dynPrintOptions = [];
        staticPrintOptions.values.forEach((element, index) => {
            if (!(selectedMaterial.toUpperCase().includes('FROSTED PLASTIC CARD') && element.toUpperCase().includes('BOTH'))) {
                dynPrintOptions.push(element);
            }
        })
        newDynamicOptions.print = dynPrintOptions;

        // size affects folding choices
        // size -> folding
        const selectedSize = productConfigurations.size.width;
        const staticFoldingOptions = productOptions.folding;
        let dynFoldingOptions = [];
        staticFoldingOptions.values.forEach(value => {
            if (value.card_width.includes(selectedSize)) {
                dynFoldingOptions.push(value.type);
            }
        })
        newDynamicOptions.folding = dynFoldingOptions;

        newDynamicOptions.hole_punching = productOptions.hole_punching.values;
        newDynamicOptions.round_corner = productOptions.round_corner.values;
        // folding affects round corner and hole punching
        if (productConfigurations.folding !== 'None') {
            // no round corner & hold punching when folded
            newDynamicOptions.round_corner = ['None'];
            newDynamicOptions.hole_punching = ['None'];
        }

        // quantity depends on material + lamination + spot uv + folding
        let selectedVariant = productVariants.find((variant) => {
            return ((variant.material === productConfigurations.material) && (variant.lamination === productConfigurations.lamination) && (variant.spot_uv === productConfigurations.spot_uv) && (variant.folding === productConfigurations.folding));
        });
        // let dynQuantityOptions = (selectedVariant) ? [0, ...selectedVariant.quantity] : [0];
        console.log(productConfigurations);
        console.log(selectedVariant);
        let dynQuantityOptions = (selectedVariant) ? [...selectedVariant.quantity] : [];
        newDynamicOptions.quantity = dynQuantityOptions;

        console.log('New Dynoptions');
        console.log(newDynamicOptions);
        return newDynamicOptions;
    }

    /**
     * Handle the input change event.
     * Configuration & options change resulted by the event will be performed here.
     *
     * @param {*} e eventData
     */
    handleInputChange(e) {
        const options = this.props.product.setup.options;
        let futureConfigs = { ...this.state.configurations };

        if (e.target.type === 'checkbox') {
            // Use checked value instead of normal value
            futureConfigs[e.target.name] = e.target.checked;
        } else {
            // everything else just let it slide
            futureConfigs[e.target.name] = e.target.value;
        }

        if (e.target.name === 'hot_stamping') {
            // special handling for hot stamping colour
            if (e.target.value.includes('1C')) {
                if (futureConfigs.hot_stamping_colour_1 === null) {
                    futureConfigs.hot_stamping_colour_1 = 'Black';
                }
                futureConfigs.hot_stamping_colour_2 = null;
            } else if (e.target.value.includes('2C')) {
                if (futureConfigs.hot_stamping_colour_1 === null) {
                    futureConfigs.hot_stamping_colour_1 = 'Black';
                }
                if (futureConfigs.hot_stamping_colour_2 === null) {
                    futureConfigs.hot_stamping_colour_2 = 'Black';
                }
            } else {
                futureConfigs.hot_stamping_colour_1 = null;
                futureConfigs.hot_stamping_colour_2 = null;
            }
        } else if (e.target.name === 'size') {
            // convert json string to obj
            futureConfigs.size = JSON.parse(e.target.value)

            // updating creasing remain value
            futureConfigs.creasing_remains = futureConfigs.size.width - futureConfigs.creasing;
        } else if (e.target.name === 'round_corner') {
            if (futureConfigs.round_corner === 'None') {
                // Reset it
                futureConfigs.round_corner_type = null
            } else if (futureConfigs.round_corner_type === null && options.round_corner.round_corner_type.length > 0) {
                // Set a default
                futureConfigs.round_corner_type = options.round_corner.round_corner_type[0];
            }
        } else if (e.target.name === 'custom_creasing') {
            if (futureConfigs.custom_creasing === false) {
                // reset to default
                futureConfigs.creasing = 10;
                futureConfigs.creasing_remains = futureConfigs.size.width - futureConfigs.creasing;
            }
        } else if (e.target.name === 'creasing') {
            // update creasing remain value
            futureConfigs.creasing_remains = futureConfigs.size.width - futureConfigs.creasing;
        } else if (e.target.name === 'creasing_remains') {
            // update creasing value
            futureConfigs.creasing = futureConfigs.size.width - futureConfigs.creasing_remains;
        }

        let futureDynOptions = this.getDynamicOptions(futureConfigs, this.props.product.setup.options);
        if (e.target.name === 'material') {
            futureConfigs['lamination'] = futureDynOptions['lamination'] ? futureDynOptions['lamination'][0] : null;
            futureConfigs['spot_uv'] = futureDynOptions['spot_uv'] ? futureDynOptions['spot_uv'][0] : null;
            futureConfigs['print'] = futureDynOptions['print'] ? futureDynOptions['print'][0] : null;
            futureConfigs['quantity'] = futureDynOptions['quantity'] ? futureDynOptions['quantity'][0] : null;
            console.log(futureDynOptions['quantity']);
            console.log(futureConfigs['quantity']);
        }

        this.setState({
            dynamic_options: futureDynOptions,
            configurations: futureConfigs
        });
    }

    /**
     * Handle file selection event.
     * Acquires token from backend and upload files to AliCloud storage.
     *
     * @param {*} e eventData
     */
    handleFileSelect(type, e) {
        e.stopPropagation();
        e.preventDefault();
        let files = e.target.files;

        console.log(files);
        if (files.length > 0) {
            this.props.uploadFiles(files, type);
        }
    }

    /**
     * Generate DOM elements based on product options.
     *
     * @param {string} configName Name of the configuration
     * @param {object} configSettings Configuration data
     */
    generateProductOptionElement(configName, configSettings) {
        const { options } = this.props.product.setup;
        const { configurations, dynamic_options } = this.state;

        switch (configName) {

            case 'size':
                // static options
                return (
                    <SizeFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        itemsSource={options[configName].values}
                        selectedItem={configurations[configName]}
                        onChange={this.handleInputChange.bind(this)}
                    />
                )

            case 'orientation':
                // static options
                return (
                    <OrientationFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        onChange={this.handleInputChange.bind(this)}
                        itemsSource={options[configName].values}
                        selectedItem={configurations[configName]}
                    />
                )
            case 'material':
                // STATIC dropdown options
                return (
                    <GenericDropdownsFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        onChange={this.handleInputChange.bind(this)}
                        itemsSource={options[configName].values}
                        selectedItem={configurations[configName]}
                    />
                )

            case 'print':
            case 'lamination':
            case 'quantity':
                // DYNAMIC dropdown options
                return (
                    <GenericDropdownsFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        onChange={this.handleInputChange.bind(this)}
                        itemsSource={dynamic_options[configName]}
                        selectedItem={configurations[configName]}
                    />
                )

            case 'spot_uv':
            case 'hole_punching':
                // DYNAMIC dropdown options
                return (
                    <OptionalGenericDropdownsFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        onChange={this.handleInputChange.bind(this)}
                        itemsSource={dynamic_options[configName]}
                        selectedItem={configurations[configName]}
                    />
                )

            case 'folding':
                // dynamic options
                return (
                    <FoldingCreasingFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        onChange={this.handleInputChange.bind(this)}
                        optionItemsSource={dynamic_options[configName]}
                        selectedOption={configurations[configName]}
                        customCreasing={configurations.custom_creasing}
                        creasing={configurations.creasing}
                        creasingRemains={configurations.creasing_remains}
                        materialSize={configurations.size}
                    />
                )

            case 'hot_stamping':
                // static options
                return (
                    <HotStampingFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        onChange={this.handleInputChange.bind(this)}
                        optionItemsSource={options[configName].values}
                        selectedOption={configurations[configName]}
                        colourItemsSource={options[configName].colours}
                        selectedColour1={configurations[`${configName}_colour_1`]}
                        selectedColour2={configurations[`${configName}_colour_2`]}
                    />
                )

            case 'round_corner':
                // dynamic options for options
                // static options for types
                let cornerTypeKey = `${configName}_type`;
                return (
                    <RoundCornerFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        onChange={this.handleInputChange.bind(this)}
                        selectedOption={configurations[configName]}
                        optionItemsSource={dynamic_options[configName]}
                        selectedCornerType={configurations[cornerTypeKey]}
                        cornerTypeItemsSource={options[configName].round_corner_type}
                    />
                )

            case 'cover_artwork':
                return (<SelectFileFormGroup
                    key={configName}
                    name={configName}
                    label={configSettings.label}
                    accept=".pdf, .rar, .zip"
                    onChange={this.handleFileSelect.bind(this, 'offset_business_card_cover')}
                />
                )

            case 'cover_artwork_download_url':
                return (
                    <ManualInputFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        value={configurations[configName]}
                        inputAttributes={{ placeholder: 'ARTWORK DOWNLOAD URL', style: { textTransform: 'none' } }}
                        onChange={this.handleInputChange.bind(this)}
                    />
                )
            default:
                break;
        }
    }

    /**
     * Check if the required configurations is supplied.
     *
     * @returns True if all required configurations is supplied. False otherwise.
     */
    checkRequiredConfigurations() {
        var missingConfigurations = [];

        // Add checks for required configuration here
        if (this.state.configurations['cover_artwork_path'] == null &&
            (this.state.configurations['cover_artwork_download_url'] == null ||
                this.state.configurations['cover_artwork_download_url'] == "")) {
            missingConfigurations.push("Artwork");
        }

        if (missingConfigurations.length > 0) {
            window.alert(`Please supply the missing information: ${missingConfigurations.join(", ")}.`);
            return false;
        }

        if ((this.state.price_and_duration.price <= 0) || (this.state.price_and_duration.duration <= 0)) {
            window.alert(`Invalid product configurations.`);
            return false;
        }

        // everything OK
        return true;
    }

    addToCart() {
        const { material, size } = this.state.configurations;
        let quantity = parseInt(this.state.configurations.quantity);

        if (this.checkRequiredConfigurations() === false) {
            return;
        }

        let paper_density = parseInt(material);
        let weight = Math.ceil(quantity * size.height * size.width * paper_density / 1e9) + 2;  // size in mm and density in g to convert to kg, round up to nearest kg then add 2kg

        let config = { ...this.state.configurations, weight }

        if (this.state.configurations.folding.toUpperCase() === 'NONE') {
            config.custom_creasing = undefined;
            config.creasing = undefined;
            config.creasing_remains = undefined;
        } else {
            config.custom_creasing = this.state.configurations.custom_creasing;
            config.creasing = this.state.configurations.creasing;
            config.creasing_remains = this.state.configurations.creasing_remains;
        }

        Object.entries(config).forEach(pair => {
            if (pair[1] == null) config[pair[0]] = undefined;
            if (typeof pair[1] === 'boolean') {
                config[pair[0]] = (pair[1]) ? 'Yes' : 'No';
            }
        });

        this.props.addToCart({
            product: this.product,
            configurations: config,
            price: this.state.price_and_duration.price,
            duration: this.state.price_and_duration.duration
        });

        const alert = this.props.alert.show('Item added to cart', {
            timeout: 3000,
            type: 'success',
        });

        this.props.history.push('/cart');
    }

    render() {
        const { setup } = this.props.product;
        const { upload_started, upload_count, upload_filename, total_uploads } = this.props.cart;
        const { configurations, dynamic_options, price_and_duration, isLoadingData } = this.state;

        if (isLoadingData === true) {
            return (
                <Container>
                    <LoadingIndicator />
                </Container>
            )
        }

        return (
            <Container>
                {/* <JsonDisplay object={this.state} style={{ position: 'absolute', background: 'white', zIndex: '2', right: '0px', fontSize: '0.75em', border: '1px blue solid' }} /> */}
                <Modal isOpen={upload_started} className='scrolling'>
                    <ModalHeader>File Upload</ModalHeader>
                    <ModalBody>
                    {
                            (total_uploads === 0)
                                ? (
                                    <div>
                                        <span>Contacting server . . . <br/><br/></span>
                                        <Progress multi>
                                            <Progress bar animated value={10} />
                                        </Progress>
                                    </div>
                                )
                                : ((total_uploads < 0)
                                    ? (<span>Upload Failed! <br/><br/><br/></span>)
                                    : ((upload_count === total_uploads)
                                        ? (
                                            <div>
                                                <span>Upload Completed!<br/><br/></span>
                                                <Progress multi>
                                                    <Progress bar color="success" value={100} > 100% </Progress>
                                                </Progress>
                                            </div>
                                        )
                                        : (
                                            <div>
                                                <span>Uploading <i>{upload_filename}</i><br/><br/></span>
                                                <Progress multi>
                                                    <Progress bar animated value={20} />
                                                </Progress>
                                            </div>
                                        )))
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color='success' onClick={() => this.props.resetUploads()} disabled={(total_uploads === 0) || (upload_count !== total_uploads)}>Close</Button>
                    </ModalFooter>
                </Modal>
                {(setup && dynamic_options && configurations) &&
                    <Fragment>
                        <h1 id="product-digital-book">{this.product.toUpperCase()}</h1>
                        <Specification productSpecs={productSpecUrl("OffsetBusinessCard.pdf")} priceList={productPriceUrl("BusinessCard-Offset.pdf")} />
                        <Form className="product-info">
                            {setup.options &&
                                Object.entries(setup.options).map((entry) => {
                                    let configName = entry[0];
                                    let configSettings = entry[1];
                                    return this.generateProductOptionElement(configName, configSettings);
                                })
                            }
                            {
                                (configurations.quantity > 0) && (
                                    <Fragment>
                                        <TimeAndCostFormGroup
                                            price={price_and_duration.price}
                                            processDay={price_and_duration.duration} />
                                        <Button onClick={this.addToCart.bind(this)}>ADD TO CART</Button>
                                    </Fragment>
                                )
                            }
                        </Form>
                    </Fragment>
                }
            </Container>
        )
    }
}

const mapStateToProps = ({ product, cart }) => {
    return { product, cart };
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getProductSetup: getProductSetup,
        getProductTimeAndCost: getProductTimeAndCost,
        resetProductTimeAndCost: resetProductTimeAndCost,
        uploadFiles: uploadFiles,
        resetUploads: resetUploads,
        addToCart: addToCart
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withAlert(OffsetBusinessCard));
