import React, { Component, Fragment } from 'react';
import './Specifications.css'
/**
 * FormGroup for Size configuration
 *
 * @param {String} productSpecs         - Link to downloadable product specs
 * @param {String} priceList            - Link to downloadable price list
 */
export default class Specification extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="prod-specs-container">
                <a className="prod-specs" href={this.props.productSpecs} download>Product Specs</a>
                {/* <a className="prod-specs" href={this.props.artworkSpecs}>Artwork Specs</a>
                <a className="prod-specs" href={this.props.productSizeTemplate}>Product Size Template</a> */}
                <a className="prod-specs" href="/art-work-specification">Artwork Specs</a>
                <a className="prod-specs" href="/product-size-template">Product Size Template</a>
                {/* <a className="prod-specs" href={this.props.priceList} download>Price List</a> */}
            </div>
        )
    }
}