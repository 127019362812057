import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Prompt } from 'react-router-dom';
import { propertyToUrl } from "query-string-params";
import { serverURI } from '../../../config';
import {
    Alert,
    Container, Row, Col,
    Button,
    Collapse,
    Table,
    InputGroup, InputGroupAddon,
    Form, FormGroup, Label, Input, FormText,
    Popover, PopoverBody,
    Modal, ModalHeader, ModalBody, ModalFooter,
    CardDeck, Card, CardHeader, CardSubtitle, CardBody
} from 'reactstrap';
import { withAlert } from 'react-alert'
// import { propertyToUrl } from "query-string-params";
import { fetchShippingInfo, calculateShipping, resetShipping, emptyCart, registerLoginSuccessHandle, showLoading, showLoginModal, saveOrder, verifyVoucher, resetVoucher } from '../../../actions';
import MediaQuery from 'react-responsive';
import MaskedInput from 'react-text-mask';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { faTrash, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import './Checkout.css';

const request = require('superagent');

class Checkout extends Component {
    state = {
        shippingAddr: false,
        createAcct: false,
        shipping_country: 'Malaysia',
        billing_country: 'Malaysia',
        shipping_state: 'State',
        billing_state: 'State',
        ib_pay: false,
        cc_pay: false,
        popoverOpen: false,
        popoverTarget: 'billing_phone',
        popoverText: '',
        confirmSubmitOrder: false,
        isBlocking: true,
        total_weight: 0.0,
        voucher_code: '',
        voucher_discount: null,
        balance_alert: false,
        submitting: false,
        totalAmount: 0.0,
        ttl_promo_disc: 0,
    }

    componentWillMount() {
        const { billing_country, shipping_country } = this.props.checkout;
        const { customer } = this.props;
        let total_weight = 0.0;
        this.props.cart.items.forEach(item => total_weight += item.configurations.weight);
        this.props.fetchShippingInfo([billing_country, shipping_country]);
        console.log(this.props.cart.delivery_option);
        if (customer) {
            this.setState({
                billing_name: customer.name,
                billing_company: customer.company,
                billing_address_1: customer.address.address_1,
                billing_address_2: customer.address.address_2,
                billing_town_city: customer.address.town_city,
                billing_postcode: customer.address.postcode,
                billing_state: customer.address.state,
                billing_country: customer.address.country,
                billing_phone: customer.mobile,
                email: customer.email,
                total_weight: total_weight,
                delivery_option: this.props.cart.delivery_option
            });
            console.log('setState in componentWillMount');
            this.props.calculateShipping({ delivery_option: this.props.cart.delivery_option, country: customer.address.country, state: customer.address.state, weight: total_weight });
        } else {
            this.setState({
                billing_name: '',
                billing_company: '',
                billing_address_1: '',
                billing_address_2: '',
                billing_town_city: '',
                billing_postcode: '',
                billing_state: 'State',
                billing_country: 'Malaysia',
                billing_phone: '',
                email: '',
                total_weight: total_weight,
                delivery_option: this.props.cart.delivery_option
            });
            console.log('setState in componentWillMount');
            this.props.calculateShipping({ delivery_option: this.props.cart.delivery_option, country: 'MALAYSIA', state: 'State', weight: total_weight });
        }
    }

    componentDidUpdate(previousProps, previousState) {
        if (previousProps.customer !== this.props.customer) {
            if (this.props.customer) {
                this.setState({
                    billing_name: this.props.customer.name,
                    billing_company: this.props.customer.company,
                    billing_address_1: this.props.customer.address.address_1,
                    billing_address_2: this.props.customer.address.address_2,
                    billing_town_city: this.props.customer.address.town_city,
                    billing_postcode: this.props.customer.address.postcode,
                    billing_state: this.props.customer.address.state,
                    billing_country: this.props.customer.address.country,
                    billing_phone: this.props.customer.mobile,
                    email: this.props.customer.email
                });
                this.calculateTotal();
            }
        }

        if (previousProps.cart !== this.props.cart) {
            let total_weight = 0.0;
            this.props.cart.items.forEach(item => total_weight += item.configurations.weight);
            this.setState({ total_weight: total_weight });
            // When Cart Changes, Recalculate Total
            this.calculateTotal();
        }

        if ((previousState.shipping_state !== this.state.shipping_state) || (previousState.billing_state !== this.state.billing_state) || (previousState.shippingAddr !== this.state.shippingAddr) || (previousState.total_weight !== this.state.total_weight)) {
            if (this.state.shippingAddr) {
                this.props.calculateShipping({ delivery_option: this.state.delivery_option, country: this.state.shipping_country, state: this.state.shipping_state, weight: this.state.total_weight });
                this.calculateTotal();
            } else {
                this.props.calculateShipping({ delivery_option: this.state.delivery_option, country: this.state.billing_country, state: this.state.billing_state, weight: this.state.total_weight });
                this.calculateTotal();
            }
        }
        // When Have Voucher Discount, Recalculate Total
        if (previousProps.checkout.voucher_discount !== this.props.checkout.voucher_discount) {
            this.calculateTotal();
        }
    }

    componentWillUnmount() {
        console.log('Deregistering login callback');
        this.props.registerLoginSuccessHandle(null);
        // Clear Voucher When Switching Page
        this.props.resetVoucher();
    }

    handleLogin(e) {
        e.preventDefault();
        this.props.showLoginModal(true);
    }

    handleIBclicked() {
        this.setState({ cc_pay: false, ib_pay: !this.state.ib_pay });
    }

    handleCCclicked() {
        this.setState({ cc_pay: !this.state.cc_pay, ib_pay: false });
    }

    // handlePhoneNumberFocus(e) {
    //     e.target.placeholder = '(6XXX)-XXX-XXXXX';
    // }

    handleBillAddrChange(e) {
        if (e.target.value === 'State') {
            e.target.style.color = '#999';
            e.target.classList.remove('is-invalid');
        } else {
            e.target.style.color = '#495057';
        }
        this.setState({ billing_state: e.target.value });
    }

    handleShipAddrChange(e) {
        if (e.target.value === 'State') {
            e.target.style.color = '#999';
        } else {
            e.target.style.color = '#495057';
            this.setState({ shippingStateValid: null });
        }
        this.setState({ shipping_state: e.target.value });
    }

    handleInput(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    resetInputStyle(e) {
        e.target.classList.remove('is-invalid');
        this.setState({
            popoverOpen: false
        });
    }

    showPhoneHelpText(e) {
        this.setState({
            popoverText: 'Phone number starts with area code (eg. 0XXXXXXXX for fixed line, 01XXXXXXXX for mobile)',
            popoverTarget: e.target.id,
            popoverOpen: true
        });
    }

    closePopover() {
        this.setState({
            popoverOpen: false
        });
    }

    closeBalanceAlert() {
        this.setState({
            balance_alert: false
        });
    }

    // Calculate Total with Vouher / Shipping Fee/ Shipping Discount
    calculateTotal() {
        const { shipping_fee, shipping_disc, total } = this.props.cart;
        let voucher = this.props.checkout.voucher_discount;
        let amount = total;

        // When have shipping fee then will just add shipping fee
        if (shipping_fee || shipping_disc) {
            amount = total + shipping_fee - shipping_disc;
        }
        // When have voucher , then just discount voucher value
        if (voucher) {
            let ttl_promo_disc = 0;
            if (voucher.ttl_promo_price) {
                ttl_promo_disc = (voucher.type === 'cash') ? voucher.value : (voucher.ttl_promo_price * voucher.value / 100);
                if (ttl_promo_disc <= voucher.ttl_promo_price) ttl_promo_disc = ttl_promo_disc;
                else ttl_promo_disc = voucher.ttl_promo_price;
            } else {
                ttl_promo_disc = (voucher.type === 'cash') ? voucher.value : (total * voucher.value / 100);
                if (ttl_promo_disc <= total) ttl_promo_disc = ttl_promo_disc;
                else ttl_promo_disc = total;
            }
            amount -= ttl_promo_disc;
            console.log(ttl_promo_disc)
            this.setState({ ttl_promo_disc })
        }

        // If Amount is nagative value, total amount will just show 0 to user.
        if (amount < 0) {
            amount = 0;
        //When the total amount is NaN,the front end will still show total value while not NaN
        } else if (isNaN(amount)) {
            this.setState({ totalAmount: total });
        } else {
            this.setState({ totalAmount: amount });
        }
    }

    async completeSubmitOrder(user, order) {
        console.log('completeSubmitOrder: ');
        console.log(user);
        console.log(order);

        this.setState({ isBlocking: false, submitting: true });

        let trxn = {
            user: user,
            order_details: (order) ? order : this.props.checkout.order
        }

        if (trxn.order_details) {
            trxn.user.email = trxn.order_details.customer.email;
            trxn.user.name = trxn.order_details.customer.name;
            trxn.user.address = trxn.order_details.customer.address;
            trxn.user.phone = trxn.order_details.customer.phone;
        }

        try {
            let resp = await request.post(serverURI + '/v1/commit_order')
                .set('Content-Type', 'application/json')
                .send(trxn);
            if (resp.status === 200) {
                this.formRef.reset();
                this.setState({
                    shippingAddr: false,
                    createAcct: false,
                    shipping_state: 'State',
                    billing_state: 'State',
                    ib_pay: false,
                    cc_pay: false,
                    popoverOpen: false,
                    popoverTarget: 'billing_phone',
                    popoverText: '',
                    confirmSubmitOrder: false
                });
                this.props.showLoading(false);
                this.props.showLoginModal(false);
                this.props.history.push('/thankyou?' + propertyToUrl({ invoice_id: resp.body.invoice_id }));
            } else {
                const alert = this.props.alert.show('Failed to submit order. Please try again!', {
                    timeout: 3000,
                    type: 'error',
                });

                this.setState({ submitting: false });
            }
        } catch (err) {
            console.log(err);
            const alert = this.props.alert.show(err.response.text, {
                timeout: 3000,
                type: 'error',
            });

            let balance_alert = (err.response.text.toUpperCase().includes('INSUFFICIENT BALANCE')) ? true : false;

            this.setState({ submitting: false, balance_alert });
        }
    }

    submitOrder(login, e) {
        this.setState({ confirmSubmitOrder: false });
        if (login) {
            console.log('Registering login callback');
            this.props.registerLoginSuccessHandle(this.completeSubmitOrder.bind(this));
            this.props.showLoginModal(true);
        } else {
            this.completeSubmitOrder();
        }
    }

    validateAndSubmit(e) {
        e.preventDefault();
        this.closePopover();

        // Perform form validation
        if (e.target.elements['billing_name'].value === '') {
            e.target.elements['billing_name'].classList.add('is-invalid');
            e.target.elements['billing_name'].focus();
            this.setState({
                popoverText: 'Name is required!',
                popoverTarget: 'billing_name',
                popoverOpen: true
            });
        } else if (e.target.elements['billing_company'].value === '') {
            e.target.elements['billing_company'].classList.add('is-invalid');
            e.target.elements['billing_company'].focus();
            this.setState({
                popoverText: 'Company name is required!',
                popoverTarget: 'billing_company',
                popoverOpen: true
            });
        } else if (e.target.elements['billing_address_1'].value === '') {
            e.target.elements['billing_address_1'].classList.add('is-invalid');
            e.target.elements['billing_address_1'].focus();
            this.setState({
                popoverText: 'Billing address is required!',
                popoverTarget: 'billing_address_1',
                popoverOpen: true
            });
        } else if (e.target.elements['billing_town_city'].value === '') {
            e.target.elements['billing_town_city'].classList.add('is-invalid');
            e.target.elements['billing_town_city'].focus();
            this.setState({
                popoverText: 'Town / City is required!',
                popoverTarget: 'billing_town_city',
                popoverOpen: true
            });
        } else if (e.target.elements['billing_postcode'].value === '') {
            e.target.elements['billing_postcode'].classList.add('is-invalid');
            e.target.elements['billing_postcode'].focus();
            this.setState({
                popoverText: 'Postal code is required!',
                popoverTarget: 'billing_postcode',
                popoverOpen: true
            });
        } else if (isNaN(e.target.elements['billing_postcode'].value) || (e.target.elements['billing_postcode'].value.length < 5)) {
            e.target.elements['billing_postcode'].classList.add('is-invalid');
            e.target.elements['billing_postcode'].focus();
            this.setState({
                popoverText: 'Postal code must be a 5 digit number!',
                popoverTarget: 'billing_postcode',
                popoverOpen: true
            });
        } else if (e.target.elements['billing_state'].value === 'State') {
            e.target.elements['billing_state'].classList.add('is-invalid');
            e.target.elements['billing_state'].focus();
            this.setState({
                popoverText: 'Please select valid state!',
                popoverTarget: 'billing_state',
                popoverOpen: true
            });
        } else if (e.target.elements['billing_phone'].value === '') {
            e.target.elements['billing_phone'].classList.add('is-invalid');
            e.target.elements['billing_phone'].focus();
            this.setState({
                popoverText: 'Phone number is required!',
                popoverTarget: 'billing_phone',
                popoverOpen: true
            });
        } else if (e.target.elements['billing_phone'].value.length < 9) {
            e.target.elements['billing_phone'].classList.add('is-invalid');
            e.target.elements['billing_phone'].focus();
            this.setState({
                popoverText: 'Phone number must be at least 9 digits in length!',
                popoverTarget: 'billing_phone',
                popoverOpen: true
            });
        } else if (e.target.elements['email'].value.length < 9) {
            e.target.elements['email'].classList.add('is-invalid');
            e.target.elements['email'].focus();
            this.setState({
                popoverText: 'Email address is required!',
                popoverTarget: 'email',
                popoverOpen: true
            });
        } else if (this.state.shippingAddr) {
            if (e.target.elements['shipping_name'].value === '') {
                e.target.elements['shipping_name'].classList.add('is-invalid');
                e.target.elements['shipping_name'].focus();
                this.setState({
                    popoverText: 'Name is required!',
                    popoverTarget: 'shipping_name',
                    popoverOpen: true
                })
            } else if (e.target.elements['shipping_address_1'].value === '') {
                e.target.elements['shipping_address_1'].classList.add('is-invalid');
                e.target.elements['shipping_address_1'].focus();
                this.setState({
                    popoverText: 'Shipping address is required!',
                    popoverTarget: 'shipping_address_1',
                    popoverOpen: true
                });
            } else if (e.target.elements['shipping_town_city'].value === '') {
                e.target.elements['shipping_town_city'].classList.add('is-invalid');
                e.target.elements['shipping_town_city'].focus();
                this.setState({
                    popoverText: 'Town / City is required!',
                    popoverTarget: 'shipping_town_city',
                    popoverOpen: true
                });
            } else if (e.target.elements['shipping_postcode'].value === '') {
                e.target.elements['shipping_postcode'].classList.add('is-invalid');
                e.target.elements['shipping_postcode'].focus();
                this.setState({
                    popoverText: 'Postal code is required!',
                    popoverTarget: 'shipping_postcode',
                    popoverOpen: true
                });
            } else if (isNaN(e.target.elements['shipping_postcode'].value) || (e.target.elements['billing_postcode'].value.length < 5)) {
                e.target.elements['shipping_postcode'].classList.add('is-invalid');
                e.target.elements['shipping_postcode'].focus();
                this.setState({
                    popoverText: 'Postal code must be a 5 digit number!',
                    popoverTarget: 'shipping_postcode',
                    popoverOpen: true
                });
            } else if (e.target.elements['shipping_state'].value === 'State') {
                e.target.elements['shipping_state'].classList.add('is-invalid');
                e.target.elements['shipping_state'].focus();
                this.setState({
                    popoverText: 'Please select valid state!',
                    popoverTarget: 'shipping_state',
                    popoverOpen: true
                });
            } else {
                console.log('Validation completed successfully');
                // Submit order to backend
                let order = {
                    customer: {
                        name: e.target.elements['billing_name'].value.trim().toUpperCase(),
                        company: e.target.elements['billing_company'].value.trim().toUpperCase(),
                        address: {
                            address_1: e.target.elements['billing_address_1'].value.trim().toUpperCase(),
                            address_2: e.target.elements['billing_address_2'].value.trim().toUpperCase(),
                            town_city: e.target.elements['billing_town_city'].value.trim().toUpperCase(),
                            postcode: e.target.elements['billing_postcode'].value.trim().toUpperCase(),
                            state: e.target.elements['billing_state'].value.trim().toUpperCase(),
                            country: e.target.elements['billing_country'].value.trim().toUpperCase()
                        },
                        phone: e.target.elements['billing_phone'].value.trim(),
                        email: e.target.elements['email'].value.trim().toLowerCase(),
                    },
                    recipient: {
                        name: e.target.elements['shipping_name'].value.trim().toUpperCase(),
                        address: {
                            address_1: e.target.elements['shipping_address_1'].value.trim().toUpperCase(),
                            address_2: e.target.elements['shipping_address_2'].value.trim().toUpperCase(),
                            town_city: e.target.elements['shipping_town_city'].value.trim().toUpperCase(),
                            postcode: e.target.elements['shipping_postcode'].value.trim().toUpperCase(),
                            state: e.target.elements['shipping_state'].value.trim().toUpperCase(),
                            country: e.target.elements['shipping_country'].value.trim().toUpperCase()
                        },
                        phone: e.target.elements['shipping_phone'].value.trim()
                    },
                    items: this.props.cart.items,
                    process_days: this.props.cart.items.reduce((max, item) => Math.max(max, item.duration), 0),
                    delivery_option: (this.props.cart.delivery_option === 'SELF COLLECT') ? 'SELF COLLECT' : 'COURIER',
                    subtotal: this.props.cart.total,
                    shipping_fee: this.props.cart.shipping_fee,
                    shipping_disc: this.props.cart.shipping_disc,
                    voucher_code: (this.props.checkout.voucher_discount && this.props.checkout.voucher_discount.type !== 'error') ? this.state.voucher_code : '',
                    total_amount: this.props.cart.total + this.props.cart.shipping_fee - this.props.cart.shipping_disc,
                    notes: e.target.elements['notes'].value.trim(),
                    payment_method: e.target.elements['payment-method'].value
                }

                this.props.calculateShipping({ delivery_option: this.props.cart.delivery_option, country: order.recipient.address.country, state: order.recipient.address.state, weight: 0.0 });
                if (this.props.customer) {
                    this.completeSubmitOrder(this.props.customer, order);
                } else {
                    this.props.saveOrder(order);
                    this.setState({ confirmSubmitOrder: true });
                }
            }
        } else {
            console.log('Validation completed successfully');
            // Submit order to backend
            let order = {
                customer: {
                    name: e.target.elements['billing_name'].value.trim().toUpperCase(),
                    company: this.props.customer.company,
                    address: {
                        address_1: e.target.elements['billing_address_1'].value.trim().toUpperCase(),
                        address_2: e.target.elements['billing_address_2'].value.trim().toUpperCase(),
                        town_city: e.target.elements['billing_town_city'].value.trim().toUpperCase(),
                        postcode: e.target.elements['billing_postcode'].value.trim().toUpperCase(),
                        state: e.target.elements['billing_state'].value.trim().toUpperCase(),
                        country: e.target.elements['billing_country'].value.trim().toUpperCase()
                    },
                    phone: e.target.elements['billing_phone'].value.trim(),
                    email: e.target.elements['email'].value.trim().toLowerCase(),
                },
                recipient: null,
                delivery_option: (this.props.cart.delivery_option === 'SELF COLLECT') ? 'SELF COLLECT' : 'COURIER',
                items: this.props.cart.items,
                process_days: this.props.cart.items.reduce((max, item) => Math.max(max, item.duration), 0),
                subtotal: this.props.cart.total,
                shipping_fee: this.props.cart.shipping_fee,
                shipping_disc: this.props.cart.shipping_disc,
                voucher_code: (this.props.checkout.voucher_discount && this.props.checkout.voucher_discount.type !== 'error') ? this.state.voucher_code : '',
                total_amount: this.props.cart.total + this.props.cart.shipping_fee - this.props.cart.shipping_disc,
                notes: e.target.elements['notes'].value.trim(),
                payment_method: e.target.elements['payment-method'].value
            }

            if (this.props.customer) {
                this.completeSubmitOrder(this.props.customer, order);
            } else {
                this.props.saveOrder(order);
                this.setState({ confirmSubmitOrder: true });
            }
        }
    }

    handleApplyCode(e) {
        console.log(this.props.customer.email);
        console.log(this.state.voucher_code);
        this.props.verifyVoucher(this.props.customer.email, this.state.voucher_code, this.props.cart);
    }

    render() {
        const { cart, checkout, customer } = this.props;
        const { shipping_fee, shipping_disc, total } = cart;
        const { delivery_option, totalAmount, ttl_promo_disc } = this.state;

        return (
            <div>
                <Modal isOpen={this.state.confirmSubmitOrder}>
                    <ModalHeader>Submit Order</ModalHeader>
                    <ModalBody>You are not logged in. Would you like to record this order to your account?</ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.submitOrder.bind(this, false)}>No</Button>
                        <Button color="primary" onClick={this.submitOrder.bind(this, true)}>Yes</Button>
                    </ModalFooter>
                </Modal>
                <Container fluid={false} className="my-3">
                    <h1 style={{ marginBottom: '1em' }}>Checkout</h1>
                    {!customer && <Alert color="danger">Returning customer? Click <a href="#" style={{ color: 'red' }} onClick={this.handleLogin.bind(this)}>HERE</a> to login</Alert>}
                    <Popover placement='bottom' isOpen={this.state.popoverOpen} target={this.state.popoverTarget} toggle={this.closePopover.bind(this)}>
                        <PopoverBody>{this.state.popoverText}</PopoverBody>
                    </Popover>
                    <Form innerRef={(ref) => this.formRef = ref} onSubmit={this.validateAndSubmit.bind(this)}>
                        <Prompt
                            when={this.state.isBlocking}
                            message={location =>
                                'Your form will be clear. Are you sure you want to abort checkout?'
                            }
                        />
                        <Alert color="warning" isOpen={this.state.balance_alert} toggle={this.closeBalanceAlert.bind(this)}>
                            Insufficient account balance. Follow instructions at <a href={window.location.protocol + '//' + window.location.hostname + '/how-to-top-up-account'} target="_blank">{window.location.protocol + '//' + window.location.hostname + '/how-to-top-up-account'}</a> to top-up.
                        </Alert>
                        <CardDeck>
                            <Card>
                                <CardHeader>Billing Details</CardHeader>
                                <CardBody>
                                    <FormGroup row>
                                        <Col>
                                            <Label for="billing_name" hidden>Name</Label>
                                            <Input type="text" id="billing_name" name="billing_name" placeholder="Name" value={this.state.billing_name} onChange={this.handleInput.bind(this)} onBlur={this.resetInputStyle.bind(this)} disabled />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col>
                                            <Label for="billing_company" hidden>Company</Label>
                                            <Input type="text" id="billing_company" name="billing_company" placeholder="Company" value={this.state.billing_company} onChange={this.handleInput.bind(this)} onBlur={this.resetInputStyle.bind(this)} disabled />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col>
                                            <Label for="billing_address_1" hidden>Address Line 1</Label>
                                            <Input type="text" id="billing_address_1" name="billing_address_1" placeholder="Address Line 1" value={this.state.billing_address_1} onChange={this.handleInput.bind(this)} onBlur={this.resetInputStyle.bind(this)} disabled />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col>
                                            <Label for="billing_address_2" hidden>Address Line 2</Label>
                                            <Input type="text" id="billing_address_2" name="billing_address_2" placeholder="Address Line 2" value={this.state.billing_address_2} onChange={this.handleInput.bind(this)} onBlur={this.resetInputStyle.bind(this)} disabled />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col>
                                            <Label for="billing_town_city" hidden>Town / City</Label>
                                            <Input type="text" id="billing_town_city" name="billing_town_city" placeholder="Town / City" value={this.state.billing_town_city} onChange={this.handleInput.bind(this)} onBlur={this.resetInputStyle.bind(this)} disabled />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col>
                                            <Label for="billing_postcode" hidden>Postal Code</Label>
                                            <MaskedInput
                                                mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                                                className="form-control"
                                                placeholder="Postal Code"
                                                placeholderChar="X"
                                                guide={true}
                                                id="billing_postcode"
                                                name="billing_postcode"
                                                value={this.state.billing_postcode}
                                                onChange={this.handleInput.bind(this)}
                                                onBlur={this.resetInputStyle.bind(this)}
                                                disabled
                                            />
                                        </Col>
                                        <Col>
                                            <Label for="billing_state" hidden>State</Label>
                                            <Input type="select" className="state_select" id="billing_state" name="billing_state" value={this.state.billing_state} placeholder="State" onChange={this.handleBillAddrChange.bind(this)} onBlur={this.resetInputStyle.bind(this)} disabled >
                                                <option>State</option>
                                                {(checkout.shipping_rates.length > 0) &&
                                                    checkout.shipping_rates.find((m) => m._id === this.props.checkout.billing_country).rates.map((n) => <option key={n.state}>{n.state}</option>)
                                                }
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col>
                                            <Label for="billing_country" hidden>Country</Label>
                                            <Input type="text" id="billing_country" name="billing_country" placeholder="Country" value={this.props.checkout.billing_country} disabled />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col>
                                            <Label for="billing_phone" hidden>Mobile Number</Label>
                                            <MaskedInput
                                                mask={[/0/, /[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                                className="form-control"
                                                placeholder="Mobile Number"
                                                guide={false}
                                                id="billing_phone"
                                                name="billing_phone"
                                                value={this.state.billing_phone}
                                                onChange={this.handleInput.bind(this)}
                                                onInput={this.showPhoneHelpText.bind(this)}
                                                onBlur={this.resetInputStyle.bind(this)}
                                                disabled
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col>
                                            <Label for="email" hidden>Email</Label>
                                            <Input type="email" id="email" name="email" placeholder="EMAIL ADDRESS" value={this.state.email} onChange={this.handleInput.bind(this)} onBlur={this.resetInputStyle.bind(this)} disabled />
                                        </Col>
                                    </FormGroup>
                                    {(!delivery_option.includes('SELF COLLECT')) &&
                                        <FormGroup row>
                                            <Col>
                                                <FormGroup check>
                                                    <Label className="checkboxClass" check>
                                                        <Input type="checkbox" name="diffShipAddr" onClick={() => this.setState({ shippingAddr: !this.state.shippingAddr })} />{' Ship to different address'}
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </FormGroup>
                                    }
                                </CardBody>
                                {(!delivery_option.includes('SELF COLLECT')) &&
                                    <Collapse isOpen={this.state.shippingAddr}>
                                        <CardHeader className="inner-card-header">Shipping Details</CardHeader>
                                        <CardBody>
                                            <FormGroup row>
                                                <Col>
                                                    <Label for="shipping_name" hidden>First Name</Label>
                                                    <Input type="text" id="shipping_name" name="shipping_name" placeholder="Name" onBlur={this.resetInputStyle.bind(this)} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col>
                                                    <Label for="shipping_address_1" hidden>Address Line 1</Label>
                                                    <Input type="text" id="shipping_address_1" name="shipping_address_1" placeholder="Address Line 1" onBlur={this.resetInputStyle.bind(this)} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col>
                                                    <Label for="shipping_address_2" hidden>Address Line 2</Label>
                                                    <Input type="text" id="shipping_address_2" name="shipping_address_2" placeholder="Address Line 2" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col>
                                                    <Label for="shipping_town_city" hidden>Town / City</Label>
                                                    <Input type="text" id="shipping_town_city" name="shipping_town_city" placeholder="Town / City" onBlur={this.resetInputStyle.bind(this)} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col>
                                                    <Label for="shipping_postcode" hidden>Postal Code</Label>
                                                    <MaskedInput
                                                        mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                                                        className="form-control"
                                                        placeholder="Postal Code"
                                                        placeholderChar="X"
                                                        guide={true}
                                                        id="shipping_postcode"
                                                        name="shipping_postcode"
                                                        onBlur={this.resetInputStyle.bind(this)}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Label for="shipping_state" hidden>State</Label>
                                                    <Input type="select" className="state_select" id="shipping_state" name="shipping_state" value={this.state.shipping_state} placeholder="State" onChange={this.handleShipAddrChange.bind(this)} onBlur={this.resetInputStyle.bind(this)}>
                                                        <option>State</option>
                                                        {(checkout.shipping_rates.length > 0) &&
                                                            checkout.shipping_rates.find((m) => m._id === this.props.checkout.shipping_country).rates.map((n) => <option key={n.state}>{n.state}</option>)
                                                        }
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col>
                                                    <Label for="shipping_country" hidden>Country</Label>
                                                    <Input type="text" id="shipping_country" name="shipping_country" placeholder="Country" defaultValue={this.props.checkout.shipping_country} disabled />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col>
                                                    <Label for="shipping_phone" hidden>Mobile Number</Label>
                                                    <MaskedInput
                                                        mask={[/0/, /[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                                        className="form-control"
                                                        placeholder="Mobile Number"
                                                        guide={false}
                                                        id="shipping_phone"
                                                        name="shipping_phone"
                                                        onInput={this.showPhoneHelpText.bind(this)}
                                                        onBlur={this.closePopover.bind(this)}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </CardBody>
                                    </Collapse>
                                }
                            </Card>
                            <Card>
                                <CardHeader>Order Details</CardHeader>
                                <CardBody>
                                    <div className="order-details">
                                        <Table className="order-table">
                                            <thead>
                                                <tr>
                                                    <th>Product</th>
                                                    <th className="currency">Total (RM)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    cart.items.map((item) => {
                                                        return (
                                                            <tr key={item.id}>
                                                                <td>{item.product} ({item.configurations.quantity})</td>
                                                                <td className="currency">{item.price.toFixed(2)}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td>Subtotal</td>
                                                    <td className="currency">{cart.total.toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Shipping</td>
                                                    <td className="currency">{shipping_fee.toFixed(2)} </td>
                                                </tr>
                                                {
                                                    (shipping_disc > 0) &&
                                                    <tr>
                                                        <td>Discount (Shipping)</td>
                                                        <td className="currency">-{shipping_disc.toFixed(2)} </td>
                                                    </tr>
                                                }
                                                {
                                                    (!checkout.voucher_discount || (checkout.voucher_discount.type === 'error')) ?
                                                        <tr>
                                                            <td colSpan='2'>
                                                                <InputGroup>
                                                                    <Input name="voucher_code" placeholder="VOUCHER CODE" value={this.state.voucher_code} style={{ textTransform: 'unset' }} onChange={this.handleInput.bind(this)} />
                                                                    <InputGroupAddon addonType="append"><Button color="secondary" onClick={this.handleApplyCode.bind(this)}>APPLY</Button></InputGroupAddon>
                                                                </InputGroup>
                                                            </td>
                                                        </tr> :
                                                        <tr>
                                                            <td>Voucher Discount ({(checkout.voucher_discount.type === 'cash') ? 'RM ' + checkout.voucher_discount.value.toFixed(2) : checkout.voucher_discount.value + '%'})</td>
                                                            <td className="currency">{(-ttl_promo_disc).toFixed(2)}</td>
                                                        </tr>
                                                }
                                                <tr>
                                                    <td>TOTAL</td>
                                                    <td className="currency">{(totalAmount).toFixed(2)}</td>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                        {
                                            (checkout.voucher_discount && checkout.voucher_discount.type === 'error') &&
                                            <Alert color="danger">{checkout.voucher_discount.value}</Alert>
                                        }
                                    </div>
                                    <FormGroup>
                                        <Label id="notes_label" for="notes">Notes (optional)</Label>
                                        <Input type="textarea" name="notes" id="notes" placeholder="eg. special delivery instruction" />
                                    </FormGroup>
                                    {/* <button type="submit" className="button pay" >PLACE ORDER</button> */}
                                </CardBody>
                                <CardHeader className="inner-card-header">Payment Methods</CardHeader>
                                <CardBody>
                                    <FormGroup tag="fieldset">
                                        <Col sm={12}>
                                            {/* <FormGroup check className="form-check-inline">
                                                <Label check>
                                                    <Input type="radio" name="payment-method" value="ATM / Online Banking"/>
                                                        ATM / Online Banking
                                                </Label>
                                            </FormGroup>
                                            <FormGroup check className="form-check-inline">
                                                <Label check>
                                                    <Input type="radio" name="payment-method" value="Payment Gateway (iPay88)"/>
                                                        Payment Gateway (iPay88)
                                                </Label>
                                            </FormGroup> */}
                                            <FormGroup check className="form-check-inline">
                                                <Label check>
                                                    <Input type="radio" name="payment-method" value="In-Store Credit" defaultChecked />
                                                    In-Store Credit
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </FormGroup>
                                    <Button type="submit" color="success" disabled={this.props.cart.items.length === 0 || this.state.submitting || !customer}>PLACE ORDER</Button>
                                </CardBody>
                            </Card>
                        </CardDeck>
                    </Form>
                </Container>
            </div>
        )
    }
}

const mapStateToProps = ({ cart, checkout, main }) => {
    return { cart, checkout, customer: main.customer, loginModal: main.loginModal };
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchShippingInfo: fetchShippingInfo,
        calculateShipping: calculateShipping,
        resetShipping: resetShipping,
        emptyCart: emptyCart,
        registerLoginSuccessHandle: registerLoginSuccessHandle,
        showLoading: showLoading,
        showLoginModal: showLoginModal,
        saveOrder: saveOrder,
        verifyVoucher: verifyVoucher,
        resetVoucher: resetVoucher
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withAlert(Checkout));
