import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

class PrivacyPolicy extends Component {

  render() {
    return (
      <Container className="my-3">
        <Row>
          <Col>
            <h1 className="my-2">PRIVACY POLICIES</h1>
            <p>
              UTech Enterprise (hereon referred to as "Utech") values your right to privacy. When you visit the Utech web site, you can be assured that any information you share with us will be treated confidentially except when necessary to give effect to the transaction and when we are legally required to do so.
            </p>
            <p>
              <ol>
                <li><b>Information collection</b>
                  <p>We will require some personal information such as your: -</p>
                  <p>
                    <ul>
                      <li>personal name and/or company name</li>
                      <li>address, telephone numbers, mobile numbers, email address</li>
                      {/* <li>credit card details (used for online payment gateway operated by mobile88.com sdn. bhd)</li> */}
                    </ul>
                  </p>
                </li>
                <li><b>Use of information collection</b>
                  <p>Any information obtained via this web site will be used for the purposes of communication with you in respect of them and in respect of other services offered by us. We will not provide it to any third parties, except when necessary to give effect to the transaction, and when we are legally required to do so.</p>
                  {/* <p>For security reasons, your credit card number is not saved. You must type the credit card number each time you reload the money to Utech.</p> */}
                </li>
                <li><b>Log files</b>
                  <p>We use IP addresses to analyze trends, administer the site, track user's movement, and gather broad demographic information for aggregate use. IP addresses are not linked to personally identifiable information.</p>
                </li>
                <li><b>Newsletter</b>
                  <p>By joining the Utech email update service, we will email you our latest updates about our products and services. If you do not wish to remain a subscriber to Utech.com.my, you are able to remove your details from the subscriptions list via our website.</p>
                </li>
                <li><b>Security</b>
                  <p>Our online payment system resides on a secure server that encrypts your purchase information using secure socket layers. we use all reasonable endeavors to protect personal information from loss, misuse and alteration. only authorized employees, who are under appropriate confidentiality obligations, will have access to your personal information. However, you will be responsible for any user id or password that is used on our web site. you should take due care to protect them.</p>
                </li>
                <li><b>Links to other sites</b>
                  <p>Utech web site contains links to other sites. Please be aware that we are not responsible for the privacy practices or content of those sites. We encourage you to read the privacy statements of each site that collect information from you. Utech's privacy policy applies solely to information collected by Utech on this web site.</p>
                </li>
                <li><b>Your consent</b>
                  <p>In using the Utech web site, you consent to the collection and use of this information by Utech as described above (which may change from time to time) until and unless you tell us to the contrary by following the instructions contained in our website.</p>
                </li>
                <li><b>Policy changes</b>
                  <p>Utech reserves the right to modify our privacy policy at any time. If we change or update our privacy policy, we will post the revised policy on this page. We ask you to check it from time to time to make sure you are aware of the latest version.</p>
                </li>
                <li><b>Contact information</b>
                  <p>If you still have questions about our privacy policy, please email us your comments as well as your contact details. We may respond to your concerns effectively and work to improve our service.</p>
                </li>
              </ol>
            </p>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default PrivacyPolicy;
