import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import { checkArray } from '../Utility'

/**
 * Render a dropdown wrapped in a form group.
 * which the value of the item is displayed as it is without futher binding.
 *
 * @param {String} name - unique id for this element
 * @param {String} label - Display for the drop down
 * @param {*} value - current selected value
 * @param {Number} step - Steps
 * @param {Number} min - min
 * @param {Number} max - max
 * @param {Function} onChange - callback to handle changes
 */
export default class SteppingDropdownsFormGroup extends Component {
    constructor(props) {
        super(props);
        console.log(this.props)
    }

    render() {
        var itemsSource = []
        for(var i = this.props.min; i <= this.props.max; i += this.props.step) {
            itemsSource.push(i);
        }

        return (
            <FormGroup row>
                <Label htmlFor={this.props.name} sm={3}>{this.props.label}</Label>
                <Col sm={9}>
                    <Input type="select" name={this.props.name} id={this.props.name} value={this.props.value} onChange={this.props.onChange}>
                        {
                            itemsSource.map(item =>
                                <option key={item} value={item}>{item}</option>
                            )
                        }
                    </Input>
                </Col>
            </FormGroup>
        )
    }
}