import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import './RoundCornerFormGroup.css'
import { checkArray } from '../Utility'
/**
 * Formgroup for Round Corner configuration
 *
 * @param {String} name - Name of the configuration. Used to identify which configuration has changed.
 * @param {String} label - Display for the drop down
 * @param {Function} onChange - callback to handle changes
 * @param {*} selectedOption - current selected option
 * @param {Array} optionItemsSource - list of round corner optinmsitems
 * @param {*} selectedCornerType - current selected corner type
 * @param {Array} cornerTypeItemsSource - list of corner types
 */
export default class RoundCornerFormGroup extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (Array.isArray(this.props.optionItemsSource) === false ||
            Array.isArray(this.props.cornerTypeItemsSource) === false ||
            this.props.selectedOption == null) {
            console.log(`${this.constructor.name} not rendering due to unfullfilled prerequisites`)
            return null
        }

        return (
            <Fragment>
                <FormGroup row>
                    <Label htmlFor={this.props.name} sm={3}>{this.props.label}<br/><small style={{ color: 'red' }}>(optional finishing)</small></Label>
                    <Col sm={9}>
                        <Input type="select" name={this.props.name} id={this.props.name} value={this.props.selectedOption} onChange={this.props.onChange}>
                            {
                                this.props.optionItemsSource.map(value =>
                                    <option key={value} value={value}>{value}</option>
                                )
                            }
                        </Input>
                    </Col>
                </FormGroup>
                {
                    (this.props.selectedOption === 'Yes') &&
                    <FormGroup key={this.props.name + '_type'} row>
                        <Label htmlFor={this.props.name + '_type'} sm={3}>{this.props.label + ' Type'}</Label>
                        <Col sm={9}>
                            {
                                this.props.cornerTypeItemsSource.map(value => {
                                    let cornerId = `round_corner_type_${value}`;
                                    let cornerClassName = `round_corner_type_img round_corner_${value}`;
                                    return (
                                        <FormGroup check inline key={value}>
                                            <Label check>
                                                <Input type="radio"
                                                    name="round_corner_type"
                                                    id={cornerId}
                                                    value={value}
                                                    checked={value === this.props.selectedCornerType}
                                                    onChange={this.props.onChange} />
                                                {value}
                                                <div htmlFor={cornerId} id={cornerId} className={cornerClassName}></div>
                                            </Label>

                                        </FormGroup>
                                    );
                                }
                                )
                            }
                        </Col>
                    </FormGroup>
                }
            </Fragment>
        )
    }
}