import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

class TermsOfUse extends Component {

  render() {
    return (
      <Container className="my-3">
        <Row>
          <Col>
            <h1 className="my-2">TERMS OF USE</h1>
            <p>
              UTech Enterprise (hereon referred to as "Utech") operates this site to provide online access to information about Utech and the products, services, and opportunities we provide (the "service"). By accessing and using this site, you agree to each of the terms and conditions set forth herein ("website terms of use"). Additional terms and conditions applicable to specific areas of this site or to particular content or transactions are also posted in particular areas of the site and, together with these website terms of use, govern your use of those areas, content or transactions. These terms of use, together with applicable additional terms and conditions, are referred to as this "agreement".
            </p>
            <p>
              Utech reserves the right to modify this agreement at any time without giving you prior notice. Your use of the site following any such modification constitutes your agreement to follow and be bound by the agreement as modified. the last date these terms of use were revised is set forth below.
              <br/><br/>
              <ol>
                <li><b>Use of site</b>
                  <p>You may use the service, the site, and the information, writings, images and/or other works that you see, hear or otherwise experience on the site (singly or collectively, the "content") solely for your noncommercial, personal purposes and/or to learn about Utech products and services. No right, title or interest in any content is transferred to you, whether as a result of downloading such content or otherwise.</p>
                  <p>Utech reserves complete title and full intellectual property rights in all content. Except as expressly authorized by this agreement, you may not use, alter, copy, distribute, transmit, or derive another work from any content obtained from the site or the service, except as expressly permitted by the website terms of use.</p>
                </li>
                <li><b>Copyright</b>
                  <p>The site and the content are protected by Malaysian/or foreign copyright laws and belong to Utech or its partners, affiliates, contributors or third parties. the copyrights in the content are owned by Utech or other copyright owners who have authorized their use on the site. You may download and reprint content for non-commercial, non-public, personal use only. If you are browsing this site as an employee or member of any business or organization, you may download and reprint content only for educational or other non-commercial purposes within your business or organization, except as otherwise permitted by Utech. With the exception of the sample images, you may not manipulate or alter in any way images or other content on the site without specific permission from Utech or the copyright owner.</p>
                  <p>2.1 Sample images<br/>Utech has included sample images on certain pages within the site, to demonstrate the application and convenience of its products and services. You may download, reprint and manipulate the sample images for this purpose, but you may not crop or change the composition of the image, or use the image for commercial purposes. Any other use requires specific permission from Utech or the copyright owner.</p>
                  </li>
                <li><b>Trademarks</b>
                  <p>You are prohibited from using any of the marks or logos appearing throughout the site without permission from the trademark owner, except as permitted by applicable law.</p>
                </li>
                <li><b>Links to third-party web sites</b>
                  <p>
                    Links on the site to third party web sites or information are provided solely as a convenience to you. If you use these links, you will leave the site. Such links do not constitute or imply an endorsement, sponsorship, or recommendation by Utech of the third party, the third-party website, or the information contained therein. Utech is not responsible for the availability of any such websites. Utech is not responsible or liable for any such web site or the content thereon. If you use the links to the websites of Utech affiliates or service providers, you will leave the site, and will be subject to the terms of use and privacy policy applicable to those websites.
                  </p>
                </li>
                <li><b>Linking to this site</b>
                  <p>If you would like to link to the site, you must request for Utech’s link guidelines. Unless specifically authorized by Utech, you may not connect “deep links” to the site. You may not mirror or frame the home page or any other pages of this site on any other web site or web page.</p>
                </li>
                <li><b>Downloading files</b>
                  <p>Utech cannot and does not guarantee or warrant that files or software available for downloading through the site will be fully compatible, free of infection by software viruses or other harmful computer code, files or programs.</p>
                </li>
                <li><b>Software</b>
                  <p>Any software available for download via the site is the copyrighted work of Utech and/or its licensors.</p>
                  <p>Use of such software is governed by the terms of the end user license agreement that accompanies or is included with the software. downloading, installing, and/or using any such software indicates your acceptance of the terms of the end user license agreement.</p>
                </li>
                <li><b>Disclaimer of warranties</b>
                  <p>Utech makes no express or implied warranties, representations or endorsements whatsoever with respect to the site, the service or the content. Utech expressly disclaims all warranties of any kind, express, implied, statutory or otherwise, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, with regard to the site, the service, the content, and any product or service furnished or to be furnished via the site.</p>
                  <p>Utech does not warrant that the functions performed by the site or the service will be uninterrupted, timely, secure or error-free, or that defects in the site or the service will be corrected. Utech does not warrant the accuracy or completeness of the content, or that any errors in the content will be corrected. the site, the service, and the content are provided on an “as-is” and “as available” basis.</p>
                </li>
                <li><b>Limitation of liability</b>
                  <p>In no event will Utech be liable for any damages whatsoever, including, but not limited to any direct, incidental, consequential, special, exemplary or other indirect damages arising out of (i) the use of or inability to use the site, the service, or the content, (ii) any transaction conducted through or facilitated by the site; (iii) any claim attributable to errors, omissions, or other inaccuracies in the site, the service and/or the content, (iv) unauthorized access to or alteration of your transmissions or data, or (v) any other matter relating to the site, the service, or the content, even if Utech has been advised of the possibility of such damages. If you are dissatisfied with the site, the service, the content, or with the terms of use, your sole and exclusive remedy is to discontinue using the site.</p>
                </li>
                <li><b>Indemnification</b>
                  <p>You understand and agree that you are personally responsible for your behavior on the site. You agree to indemnify, defend and hold harmless Utech, its parent companies, subsidiaries, affiliated companies, joint venturers, business partners, licensors, employees, agents, and any third-party information providers to the service from and against all claims, losses, expenses, damages and costs (including, but not limited to, direct, incidental, consequential, exemplary and indirect damages), and reasonable attorneys’ fees, resulting from or arising out of your use, misuse, or inability to use the site, the service, or the content, or any violation by you of this agreement.</p>
                </li>
                <li><b>Privacy policy</b>
                  <p>See Utech's privacy policy.</p>
                </li>
                <li><b>User conduct</b>
                  <p>You agree to use the site only for lawful purposes. You agree not to take any action that might compromise the security of the site, render the site inaccessible to others or otherwise cause damage to the site or the content. You agree not to add to, subtract from, or otherwise modify the content, or to attempt to access any content that is not intended for you. You agree not to use the site in any manner that might interfere with the rights of third parties.</p>
                </li>
                <li><b>User supplied information</b>
                  <p>Utech does not want to receive confidential or proprietary information from you via the site. You agree that any material, information, or data you transmit to us or post to the site will be considered non-confidential and non-proprietary. If you supply or post any information or material to the site, you guarantee to us that you have the legal right to post such material and that it will not violate any law or the rights of any person or entity. By posting material on the site, you give Utech the royalty-free, irrevocable, perpetual, worldwide right to use, distribute, display and create derivative works from this material, in any and all media, in any manner, in whole or in part, without any restriction or responsibilities to you.</p>
                </li>
                <li><b>Password security</b>
                  <p>If you register to become a Utech dealer, you are responsible for maintaining the confidentiality of your member identification and password information, and for restricting access to your computer. you agree to accept responsibility for all activities that occur under your member identification and password.</p>
                </li>
                <li><b>General provisions</b>
                  <p>a. Entire agreement/no waiver. These terms of use constitute the entire agreement of the parties with respect to the subject matter hereof. No waiver by Utech of any breach or default hereunder shall be deemed to be a waiver of any preceding or subsequent breach or default.</p>
                  <p>b. Correction of errors and inaccuracies. The content may contain typographical errors or other errors or inaccuracies and may not be complete or current. Utech, therefore, reserves the right to correct any errors, inaccuracies or omissions and to change or update the content at any time without prior notice. Utech does not, however, guarantee that any errors, inaccuracies or omissions will be corrected.</p>
                  <p>c. Enforcement/ choice of law/ choice of forum. If any part of this agreement is determined by a court of competent jurisdiction to be invalid or unenforceable, it will not impact any other provision of this agreement, all of which will remain in full force and effect. Any and all disputes relating to this agreement, Utech’s privacy policy, your use of the site, any other Utech website, the service, or the content are governed by, and will be interpreted in accordance with, the laws of Malaysia, without regard to any conflict of laws provisions.</p>
                </li>
              </ol>
            </p>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default TermsOfUse;
