import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import './FoldingCreasingFormGroup.css'
import { checkArray } from "../Utility";

/**
 * FormGroup for Folding configuration
 * 
 * @param {string} name - unique id for this element * 
 * @param {string} label - Display for the drop down
 * @param {function} onChange - callback to handle changes
 * @param {*} selectedOption - current selected value
 * @param {Array} optionItemsSource - list of items
 * @param {Boolean} customCreasing - current custom creasing option
 * @param {number} creasing - left creasing value in mm
 * @param {number} creasingRemain - remain creasing value in mm
 * @param {number} materialSize - selected material size
 */
export default class FoldingCreasingFormGroup extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        if (Array.isArray(this.props.optionItemSource) === null ||
            this.props.materialSize === null) {
            console.log(`${this.constructor.name} not rendering due to unfullfilled prerequisites`)
            return null
        }

        let creasingBarWidth = this.props.materialSize === null ? 0 : this.props.creasing / this.props.materialSize.width * 521;
        let creasingRemainsBarWidth = 512 - creasingBarWidth;

        return (
            <Fragment>
                <FormGroup row>
                    <Label htmlFor={this.props.name} sm={3}>{this.props.label}</Label>
                    <Col sm={9}>
                        <Input type="select" name={this.props.name} id={this.props.name} value={this.props.selectedOption} onChange={this.props.onChange}>
                            {
                                this.props.optionItemsSource.map(value =>
                                    <option key={value} value={value}>{value}</option>
                                )
                            }
                        </Input>
                    </Col>
                </FormGroup>
                {
                    (this.props.selectedOption !== 'None') &&
                    <FormGroup key={`${this.props.name}_type`} row>
                        <Label htmlFor={`${this.props.name}_type`} sm={3}>{`${this.props.label} Type`}</Label>
                        <Col sm={9}>
                            <FormGroup check inline key="creasing">
                                <Label check>
                                    <Input type="checkbox" name="custom_creasing" id="custom_creasing" value={this.props.customCreasing} onChange={this.props.onChange} />
                                    {' '}Yes, I want customize creasing.
                                    </Label>
                            </FormGroup>
                            {
                                <Row>
                                    <Col>
                                        <Label htmlFor="creasing">From left edge (mm)</Label>
                                        <Input type="number" name="creasing" id="creasing" value={this.props.creasing} onChange={this.props.onChange} readOnly={this.props.customCreasing === false} />
                                    </Col>
                                    <Col>
                                        <Label htmlFor="creasing_remains">Remaining space (mm)</Label>
                                        <Input type="number" name="creasing_remains" id="creasing_remains" value={this.props.creasingRemains} onChange={this.props.onChange} readOnly={this.props.customCreasing === false} />
                                    </Col>
                                    <Col>
                                        <Label htmlFor="creasing_total">Card total width (mm)</Label>
                                        <Input type="number" name="creasing_total" id="creasing_total" value={this.props.materialSize.width} onChange={this.props.onChange} readOnly />
                                    </Col>
                                </Row>
                            }
                            {
                                <Row>
                                    <Col>
                                        <div id="size-bar-parameter">
                                            <div id="size-bar-height">{this.props.materialSize.height}</div>
                                            <div id="size-bar-width">{this.props.materialSize.width}</div>
                                            <div id="size-bar-size-1" style={{ width: `${creasingBarWidth}px` }}>{this.props.creasing}</div>
                                            <div id="size-bar-size-2" style={{ width: `${creasingRemainsBarWidth}px` }}>{this.props.creasingRemains}</div>
                                        </div>
                                    </Col>
                                </Row>
                            }
                        </Col>
                    </FormGroup>
                }
            </Fragment>
        )
    }
}