import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, FormText } from 'reactstrap'
import { checkArray } from '../Utility'

/**
 * Render a dropdown wrapped in a form group.
 * which the value of the item is displayed as it is without futher binding.
 *
 * @param {String} name - unique id for this element
 * @param {String} label - Display for the drop down
 * @param {String} accept - Acceptable files. See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#Unique_file_type_specifiers.
 * @param {Function} onChange - callback to handle changes
 */
export default class SelectFileFormGroup extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <FormGroup row>
                <Label sm={3}>{this.props.label}</Label>
                <Col sm={9}>
                    <Input type="file" name={this.props.name} id={this.props.name} onChange={this.props.onChange} accept={this.props.accept} />
                    <FormText color="muted">
                        Accepted upload file format: {this.props.accept}.
                    </FormText>
                </Col>
            </FormGroup>
        )
    }
}