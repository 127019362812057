import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Container, Row, Col, Card, CardHeader, CardBody, Popover, PopoverBody, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withAlert } from 'react-alert';
import MaskedInput from 'react-text-mask';
import * as firebase from 'firebase';
import { fetchShippingInfo, showLoading, registerCustomer, updateCustomerProfile } from '../../../actions';
import './Account.css';
import './MyAccount.css';
import './SignUpPage.css';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faGoogle from '@fortawesome/fontawesome-free-brands/faGoogle';


class SignUpPage extends Component {
    state = {
        profile_changed: false,
        popoverOpen: false,
        popoverTarget: 'profile_phone',
        popoverText: '',
        showModal: false,
        pwModal: false
    }

    componentWillMount() {

        this.props.fetchShippingInfo(['MALAYSIA']);
        this.setState({
            profile_name: '',
            profile_company: '',
            profile_address_1: '',
            profile_address_2: '',
            profile_town_city: '',
            profile_postcode: '',
            profile_state: 'State',
            profile_country: 'MALAYSIA',
            profile_mobile: '',
            profile_phone: '',
            profile_email: '',
            login_password: '',
            retype_password: '',
            submitting: false
        });

        // this.formRef = React.createRef();
        this.togglePWModal = this.togglePWModal.bind(this);
    }

    componentDidUpdate(prevProps) {
        if ((this.props.customer !== prevProps.customer) && (this.props.customer)) {
            this.setState({
                profile_name: this.props.customer.name,
                profile_company: this.props.customer.company,
                profile_address_1: this.props.customer.address.address_1,
                profile_address_2: this.props.customer.address.address_2,
                profile_town_city: this.props.customer.address.town_city,
                profile_postcode: this.props.customer.address.postcode,
                profile_state: this.props.customer.address.state,
                profile_country: this.props.customer.address.country,
                profile_mobile: this.props.customer.mobile,
                profile_phone: this.props.customer.phone,
                profile_email: this.props.customer.email,
            })

            if (this.props.customer.status === 'ACTIVE') {
                this.props.history.push('/');
            }
        }
    }

    authErrorToMsg(err_code) {
        switch (err_code) {
            case 'auth/invalid-email':
                return 'Invalid email address';

            case 'auth/email-already-in-use':
                return 'Account already existed';

            case 'auth/operation-not-allowed':
                return 'Operation not allowed';

            case 'auth/user-disabled':
                return 'Acccount disabled, please contact administrator';

            case 'auth/user-not-found':
                return 'Account not found, please register an account';

            case 'auth/wrong-password':
                return 'Wrong password';

            case 'auth/weak-password':
                return 'Weak password';

            case 'auth/account-exists-with-different-credential':
                return 'Same email address has been used';

            default:
                return 'No error code';
        }
    }

    handleInput(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleBillingStateChange(e) {
        if (e.target.value === 'State') {
            e.target.style.color = '#999';
            e.target.classList.remove('is-invalid');
        } else {
            e.target.style.color = '#495057';
        }
        this.setState({ profile_state: e.target.value, profile_changed: true });
    }

    resetInputStyle(e) {
        e.target.classList.remove('is-invalid');
        this.setState({
            popoverOpen: false
        });
    }

    showMobileHelpText(e) {
        this.setState({
            popoverText: 'Mobile number starts with area code (eg. 01XXXXXXXX for mobile)',
            popoverTarget: e.target.id,
            popoverOpen: true
        });
    }

    showPhoneHelpText(e) {
        this.setState({
            popoverText: 'Phone number starts with area code (eg. 0XXXXXXXX for fixed line)',
            popoverTarget: e.target.id,
            popoverOpen: true
        });
    }

    closePopover() {
        this.setState({
            popoverOpen: false
        });
    }

    resetProfile() {
        this.setState({
            profile_name: '',
            profile_company: '',
            profile_address_1: '',
            profile_address_2: '',
            profile_town_city: '',
            profile_postcode: '',
            profile_state: 'State',
            profile_country: 'Malaysia',
            profile_mobile: '',
            profile_phone: '',
            profile_email: '',
            login_password: '',
            retype_password: '',
        });
    }

    handleUpdateProfile(signup, e) {
        e.preventDefault();
        this.closePopover();

        // Perform form validation
        if (this.formRef.elements['profile_name'].value === '') {
            this.formRef.elements['profile_name'].classList.add('is-invalid');
            this.formRef.elements['profile_name'].focus();
            this.setState({
                popoverText: 'Name is required!',
                popoverTarget: 'profile_name',
                popoverOpen: true
            });
        } else if (this.formRef.elements['profile_address_1'].value === '') {
            this.formRef.elements['profile_address_1'].classList.add('is-invalid');
            this.formRef.elements['profile_address_1'].focus();
            this.setState({
                popoverText: 'Billing address is required!',
                popoverTarget: 'profile_address_1',
                popoverOpen: true
            });
        } else if (this.formRef.elements['profile_town_city'].value === '') {
            this.formRef.elements['profile_town_city'].classList.add('is-invalid');
            this.formRef.elements['profile_town_city'].focus();
            this.setState({
                popoverText: 'Town / City is required!',
                popoverTarget: 'profile_town_city',
                popoverOpen: true
            });
        } else if (this.formRef.elements['profile_postcode'].value === '') {
            this.formRef.elements['profile_postcode'].classList.add('is-invalid');
            this.formRef.elements['profile_postcode'].focus();
            this.setState({
                popoverText: 'Postal code is required!',
                popoverTarget: 'profile_postcode',
                popoverOpen: true
            });
        } else if (isNaN(this.formRef.elements['profile_postcode'].value) || (this.formRef.elements['profile_postcode'].value.length < 5)) {
            this.formRef.elements['profile_postcode'].classList.add('is-invalid');
            this.formRef.elements['profile_postcode'].focus();
            this.setState({
                popoverText: 'Postal code must be a 5 digit number!',
                popoverTarget: 'profile_postcode',
                popoverOpen: true
            });
        } else if (this.formRef.elements['profile_state'].value === 'State') {
            this.formRef.elements['profile_state'].classList.add('is-invalid');
            this.formRef.elements['profile_state'].focus();
            this.setState({
                popoverText: 'Please select valid state!',
                popoverTarget: 'profile_state',
                popoverOpen: true
            });
        } else if (this.formRef.elements['profile_mobile'].value === '') {
            this.formRef.elements['profile_mobile'].classList.add('is-invalid');
            this.formRef.elements['profile_mobile'].focus();
            this.setState({
                popoverText: 'Mobile number is required!',
                popoverTarget: 'profile_mobile',
                popoverOpen: true
            });
        } else if (this.formRef.elements['profile_mobile'].value.length < 10) {
            this.formRef.elements['profile_mobile'].classList.add('is-invalid');
            this.formRef.elements['profile_mobile'].focus();
            this.setState({
                popoverText: 'Mobile number must be at least 10 digits in length!',
                popoverTarget: 'profile_mobile',
                popoverOpen: true
            });
            // } else if (this.formRef.elements['profile_phone'].value === '') {
            //     this.formRef.elements['profile_phone'].classList.add('is-invalid');
            //     this.formRef.elements['profile_phone'].focus();
            //     this.setState({
            //         popoverText: 'Phone number is required!',
            //         popoverTarget: 'profile_phone',
            //         popoverOpen: true
            //     });
            // } else if (this.formRef.elements['profile_phone'].value.length < 9) {
            //     this.formRef.elements['profile_phone'].classList.add('is-invalid');
            //     this.formRef.elements['profile_phone'].focus();
            //     this.setState({
            //         popoverText: 'Phone number must be at least 9 digits in length!',
            //         popoverTarget: 'profile_phone',
            //         popoverOpen: true
            //     });
        } else if ((signup === 'password') && (this.formRef.elements['profile_email'].value.length === 0)) {
            this.formRef.elements['profile_email'].classList.add('is-invalid');
            this.formRef.elements['profile_email'].focus();
            this.setState({
                popoverText: 'Email address is required!',
                popoverTarget: 'profile_email',
                popoverOpen: true
            });
        } else if ((signup === 'password') && (!this.formRef.elements['profile_email'].value.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/))) {
            this.formRef.elements['profile_email'].classList.add('is-invalid');
            this.formRef.elements['profile_email'].focus();
            this.setState({
                popoverText: 'Invalid email format!',
                popoverTarget: 'profile_email',
                popoverOpen: true
            });

        } else if ((signup === 'password') && (!this.formRef.elements['login_password'].value.match(/.*^(?=.{6,})(?=.*[a-zA-Z])(?!.*\s).*$/))) {
            this.formRef.elements['login_password'].classList.add('is-invalid');
            this.formRef.elements['login_password'].focus();
            this.setState({
                popoverText: 'Password MUST be at least 6 alphanumeric characters!',
                popoverTarget: 'login_password',
                popoverOpen: true
            });
        } else {
            console.log('Validation completed successfully');
            // Submit order to backend
            let customer = {
                name: this.formRef.elements['profile_name'].value.trim().toUpperCase(),
                company: this.formRef.elements['profile_company'].value.trim().toUpperCase(),
                address: {
                    address_1: this.formRef.elements['profile_address_1'].value.trim().toUpperCase(),
                    address_2: this.formRef.elements['profile_address_2'].value.trim().toUpperCase(),
                    town_city: this.formRef.elements['profile_town_city'].value.trim().toUpperCase(),
                    postcode: this.formRef.elements['profile_postcode'].value.trim().toUpperCase(),
                    state: this.formRef.elements['profile_state'].value.trim().toUpperCase(),
                    country: this.formRef.elements['profile_country'].value.trim().toUpperCase()
                },
                mobile: this.formRef.elements['profile_mobile'].value.trim(),
                phone: this.formRef.elements['profile_phone'].value.trim(),
                email: this.formRef.elements['profile_email'].value.trim().toLowerCase(),
                status: 'ACTIVE'
            }

            switch (signup) {
                case 'password':
                    this.togglePWModal();
                    break;

                case 'google':
                    console.log('Signing into Google');
                    let provider = new firebase.auth.GoogleAuthProvider();
                    this.props.showLoading(true);
                    firebase.auth().signInWithPopup(provider).then((result) => {
                        let user = result.user;
                        console.log('Google signin successful');
                        customer._id = user.uid;
                        customer.provider = user.providerData[0].providerId;
                        customer.email = user.email;
                        if (user.displayName) customer.name = user.displayName;
                        if (user.phoneNumber) customer.mobile = user.phoneNumber;
                        if (user.photoURL) customer.photoURL = user.photoURL;
                        this.props.registerCustomer(customer);
                        // this.props.history.push('/');
                    }).catch((error) => {
                        console.log('Google signin error: ' + error);

                        // Handle Errors here.
                        var errorCode = error.code;
                        var errorMessage = error.message;
                        // The email of the user's account used.
                        var email = error.email;
                        // The firebase.auth.AuthCredential type that was used.
                        var credential = error.credential;
                        // ...
                        // if (error.code === 'auth/account-exists-with-different-credential') {
                        //     firebase.auth.currentUser.linkWithPopup(provider).then(function(result) {
                        //         // Accounts successfully linked.
                        //         var credential = result.credential;
                        //         var user = result.user;
                        //         // ...
                        //       }).catch(function(error) {
                        //         // Handle Errors here.
                        //         // ...
                        //       });
                        // }
                        this.props.showLoading(false);
                        const alert = this.props.alert.show('Login failed! ' + this.authErrorToMsg(error.code), {
                            timeout: 3000,
                            type: 'error'
                        });
                    });
                    break;

                case 'none':
                    this.props.updateCustomerProfile(customer);
                    break;

                default:
                    break;
            }
        }
    }

    togglePWModal() {
        this.setState({pwModal: !this.state.pwModal, submitting: true});
    }

    confirmPassword() {
        let password = this.formRef.elements['login_password'].value.trim();
        let confirm_password = this.state.retype_password;

        if (password !== confirm_password) {
            this.setState({ submitting: false });
            window.confirm('Password mismatched! Please make sure you enter matching passwords.');
            return;
        }

        this.props.showLoading(true);
        this.completeSignUpWithPasswd();
    }

    completeSignUpWithPasswd() {
        let password = this.formRef.elements['login_password'].value.trim();
        let customer = {
            name: this.formRef.elements['profile_name'].value.trim().toUpperCase(),
            company: this.formRef.elements['profile_company'].value.trim().toUpperCase(),
            address: {
                address_1: this.formRef.elements['profile_address_1'].value.trim().toUpperCase(),
                address_2: this.formRef.elements['profile_address_2'].value.trim().toUpperCase(),
                town_city: this.formRef.elements['profile_town_city'].value.trim().toUpperCase(),
                postcode: this.formRef.elements['profile_postcode'].value.trim().toUpperCase(),
                state: this.formRef.elements['profile_state'].value.trim().toUpperCase(),
                country: this.formRef.elements['profile_country'].value.trim().toUpperCase()
            },
            mobile: this.formRef.elements['profile_mobile'].value.trim(),
            phone: this.formRef.elements['profile_phone'].value.trim(),
            email: this.formRef.elements['profile_email'].value.trim().toLowerCase(),
            status: 'ACTIVE'
        }

        firebase.auth().createUserWithEmailAndPassword(customer.email, password)
            .then((userCredential) => {
                console.log('Register execute success handle');
                console.log(userCredential);

                let actionCodeSettings = {
                    // URL you want to redirect back to. The domain (www.example.com) for this
                    // URL must be whitelisted in the Firebase Console.
                    url: window.location.protocol + '//' + window.location.hostname,
                    // This must be true.
                    handleCodeInApp: true,
                    // iOS: {
                    //   bundleId: 'com.example.ios'
                    // },
                    // android: {
                    //   packageName: 'com.example.android',
                    //   installApp: true,
                    //   minimumVersion: '12'
                    // },
                    // dynamicLinkDomain: 'example.page.link'
                };

                firebase.auth().currentUser.sendEmailVerification(actionCodeSettings)
                    .then(() => {
                        customer._id = userCredential.user.uid;
                        customer.provider = userCredential.user.providerData[0].providerId;
                        this.props.registerCustomer(customer);
                        firebase.auth().signOut();
                        this.setState({ submitting: false });
                        this.props.history.push('/sign-up-ack');
                    })
                    .catch((err) => {
                        console.log(err);
                        this.props.showLoading(false);
                        this.setState({ submitting: false });
                        const alert = this.props.alert.show('Register failed! ' + this.authErrorToMsg(err.code), {
                            timeout: 3000,
                            type: 'error',
                        });
                    });

            })
            .catch((err) => {
                console.log(err);
                this.props.showLoading(false);
                this.setState({ submitting: false });
                const alert = this.props.alert.show('Register failed! ' + this.authErrorToMsg(err.code), {
                    timeout: 3000,
                    type: 'error',
                });
            });
    }

    render() {
        const { submitting } = this.state;
        const { checkout, customer } = this.props;

        return (
            <Fragment>
                <Modal isOpen={this.state.pwModal} onClosed={this.confirmPassword.bind(this)}>
                    <ModalHeader>Confirm Password</ModalHeader>
                    <ModalBody>
                        <FormGroup row>
                            <Col >
                                <Input type="password" id="retype_password" name="retype_password" placeholder="Retype Password" onChange={this.handleInput.bind(this)}/>
                            </Col>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.togglePWModal}>Done</Button>{' '}
                    </ModalFooter>
                </Modal>

                <Form innerRef={(ref) => this.formRef = ref}>
                    <h2 style={{ textAlign: 'center' }}>Sign-up Form</h2>
                    <Container style={{ paddingTop: '5px', paddingBottom: '5px', maxWidth: '800px' }}>
                        <Card>
                            <CardHeader>
                                Customer Details
                        </CardHeader>
                            <CardBody>
                                <Popover placement='bottom' isOpen={this.state.popoverOpen} target={this.state.popoverTarget} toggle={this.closePopover.bind(this)}>
                                    <PopoverBody>{this.state.popoverText}</PopoverBody>
                                </Popover>
                                <FormGroup row className="required-field">
                                    <Label for="profile_name" sm={2}>Name</Label>
                                    <Col sm={10}>
                                        <Input type="text" id="profile_name" name="profile_name" placeholder="Name" value={this.state.profile_name} onChange={this.handleInput.bind(this)} onBlur={this.resetInputStyle.bind(this)} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="profile_company" sm={2}>Company</Label>
                                    <Col sm={10}>
                                        <Input type="text" id="profile_company" name="profile_company" placeholder="Company" value={this.state.profile_company} onChange={this.handleInput.bind(this)} onBlur={this.resetInputStyle.bind(this)} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="required-field">
                                    <Label for="profile_address_1" sm={2}>Address</Label>
                                    <Col sm={10}>
                                        <Input type="text" id="profile_address_1" name="profile_address_1" placeholder="Address Line 1" value={this.state.profile_address_1} onChange={this.handleInput.bind(this)} onBlur={this.resetInputStyle.bind(this)} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="profile_address_2" sm={2}></Label>
                                    <Col sm={10}>
                                        <Input type="text" id="profile_address_2" name="profile_address_2" placeholder="Address Line 2" value={this.state.profile_address_2} onChange={this.handleInput.bind(this)} onBlur={this.resetInputStyle.bind(this)} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="required-field">
                                    <Label for="profile_town_city" sm={2}>Town / City</Label>
                                    <Col sm={10}>
                                        <Input type="text" id="profile_town_city" name="profile_town_city" placeholder="Town / City" value={this.state.profile_town_city} onChange={this.handleInput.bind(this)} onBlur={this.resetInputStyle.bind(this)} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="required-field">
                                    <Label for="profile_postcode" sm={2}>Postal Code</Label>
                                    <Col sm={5}>
                                        <MaskedInput
                                            mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                                            className="form-control"
                                            placeholder="Postal Code"
                                            placeholderChar="X"
                                            guide={true}
                                            id="profile_postcode"
                                            name="profile_postcode"
                                            value={this.state.profile_postcode}
                                            onChange={this.handleInput.bind(this)}
                                            onBlur={this.resetInputStyle.bind(this)}
                                        />
                                    </Col>
                                    <Col sm={5} className="required-field">
                                        <Label for="profile_state" hidden>State</Label>
                                        <Input type="select" className="state_select" id="profile_state" name="profile_state" value={this.state.profile_state} placeholder="State" onChange={this.handleBillingStateChange.bind(this)} onBlur={this.resetInputStyle.bind(this)}>
                                            <option>State</option>
                                            {(checkout.shipping_rates.length > 0) &&
                                                checkout.shipping_rates.find((m) => m._id === this.state.profile_country).rates.map((n) => <option key={n.state}>{n.state}</option>)
                                            }
                                        </Input>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="required-field">
                                    <Label for="profile_country" sm={2}>Country</Label>
                                    <Col sm={10}>
                                        <Input type="text" id="profile_country" name="profile_country" placeholder="Country" value={this.state.profile_country} disabled />
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="required-field">
                                    <Label for="profile_mobile" sm={2}>Mobile</Label>
                                    <Col sm={10}>
                                        <MaskedInput
                                            mask={[/0/, /[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                            className="form-control"
                                            placeholder="Mobile Number"
                                            guide={false}
                                            id="profile_mobile"
                                            name="profile_mobile"
                                            value={this.state.profile_mobile}
                                            onChange={this.handleInput.bind(this)}
                                            onInput={this.showMobileHelpText.bind(this)}
                                            onBlur={this.resetInputStyle.bind(this)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="profile_phone" sm={2}>Phone</Label>
                                    <Col sm={10}>
                                        <MaskedInput
                                            mask={[/0/, /[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                            className="form-control"
                                            placeholder="Phone Number"
                                            guide={false}
                                            id="profile_phone"
                                            name="profile_phone"
                                            value={this.state.profile_phone}
                                            onChange={this.handleInput.bind(this)}
                                            onInput={this.showPhoneHelpText.bind(this)}
                                            onBlur={this.resetInputStyle.bind(this)}
                                        />
                                    </Col>
                                </FormGroup>
                                {
                                    (customer) &&
                                    <FormGroup row className="loginButtons">
                                        <Button className="signupBtn" onClick={this.handleUpdateProfile.bind(this, 'none')} disabled={submitting}>Update</Button>
                                    </FormGroup>
                                }
                            </CardBody>
                        </Card>
                    </Container>
                    {
                        (!customer) &&
                        <Container style={{ paddingTop: '5px', paddingBottom: '5px', maxWidth: '800px' }}>
                            <Card>
                                <CardHeader>Login Details</CardHeader>
                                <CardBody>
                                    <FormGroup row className="loginButtons">
                                        <Button color="" className="googleBtn" onClick={this.handleUpdateProfile.bind(this, 'google')} disabled={submitting}><FontAwesomeIcon icon={faGoogle} />&nbsp;&nbsp;Sign in with Google</Button>
                                    </FormGroup>
                                    <p className="hrBreak">OR</p>
                                    <FormGroup row>
                                        <Label for="profile_email" sm={2}>Email</Label>
                                        <Col sm={4}>
                                            <Input type="email" id="profile_email" name="profile_email" placeholder="Email" defaultValue={this.state.profile_email} style={{ textTransform: 'none' }} onBlur={this.resetInputStyle.bind(this)} />
                                        </Col>
                                        <Label for="login_password" sm={2}>Password</Label>
                                        <Col sm={4}>
                                            <Input type="password" id="login_password" name="login_password" placeholder="Password" onBlur={this.resetInputStyle.bind(this)} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className="loginButtons">
                                        <Button className="signupBtn" onClick={this.handleUpdateProfile.bind(this, 'password')} disabled={submitting}>Sign Up</Button>
                                    </FormGroup>
                                </CardBody>
                            </Card>
                        </Container>
                    }
                </Form>
            </Fragment>
        )
    }
}

const mapStateToProps = ({ checkout, main }) => {
    return { checkout, customer: main.customer, records: main.records };
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchShippingInfo: fetchShippingInfo,
        showLoading: showLoading,
        registerCustomer: registerCustomer,
        updateCustomerProfile: updateCustomerProfile
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withAlert(SignUpPage));