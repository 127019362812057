import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
} from 'reactstrap';
import { fetchBanners } from '../../../actions'
import * as firebase from 'firebase';

import './HomePage.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import bookDigitalPrinting from './book-digital-printing-177x197.jpg';
import brochureIcon from './brochure-icon-177x197.jpg';
import category5 from './category-5-177x197.jpg';
import category6 from './category-6-177x197.jpg';
import digitalBcIcon from './digital-bc-icon-177x197.jpg';
import flyerPrinting from './flyer-printing-177x197.jpg';
import offsetBcIcon from './offset-bc-icon-177x197.jpg';
import calendarNoHeader from './calendar-no-header-icon-177x197.jpg'
import calendarWithHeader from './calendar-with-header-icon-177x197.jpg'
import offsetBookletIcon from './offset-booklet-icon-177x197.jpg';

import fiveEasyStep from './5-easy-step.png';

const options = {
    items: 1,
    loop: true,
    dots: true,
    startPosition: 0,
    autoplay: true,
    autoplaySpeed: true,
    autoplayHoverPause: true,
    // URLhashListener:true,
    // startPosition: 'URLHash'
};

class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = { activeIndex: 0 };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
        props.fetchBanners();
    }

    componentWillMount() {
        // Confirm the link is a sign-in with email link.
        if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
            // Additional state parameters can also be passed via URL.
            // This can be used to continue the user's intended action before triggering
            // the sign-in operation.
            // Get the email if available. This should be available if the user completes
            // the flow on the same device where they started it.
            var email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                // User opened the link on a different device. To prevent session fixation
                // attacks, ask the user to provide the associated email again. For example:
                email = window.prompt('Please provide your email for confirmation');
            }
            // The client SDK will parse the code from the link for you.
            firebase.auth().signInWithEmailLink(email, window.location.href)
                .then(function (result) {
                    // Clear email from storage.
                    window.localStorage.removeItem('emailForSignIn');
                    // You can access the new user via result.user
                    // Additional user info profile not available via:
                    // result.additionalUserInfo.profile == null
                    // You can check if the user is new or existing:
                    // result.additionalUserInfo.isNewUser
                })
                .catch(function (error) {
                    // Some error occurred, you can inspect the code: error.code
                    // Common errors could be invalid email and invalid or expired OTPs.
                });
        }
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        const { banners } = this.props;

        if (this.animating) return;
        const nextIndex = this.state.activeIndex === banners.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        const { banners } = this.props;

        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? banners.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    handlePromoClick(index, e) {
        const { banners } = this.props;

        e.preventDefault();
        console.log('Promo ' + index + ' clicked!');
        if (banners[index].link && banners[index].link.length > 0) this.props.history.push(banners[index].link);
    }

    render() {
        const { activeIndex } = this.state;
        const { banners } = this.props;

        const slickSettings = {
            infinite: false,
            speed: 500,
            slidesToShow: 7,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 4
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        arrows: false,
                        slidesToShow: 3
                    }
                }
            ]
        };

        return (
            <div>
                {banners &&
                    <Carousel activeIndex={activeIndex} next={this.next} previous={this.previous} >
                        {
                            banners.map((banner, index) =>
                                (
                                    <CarouselItem
                                        onExiting={this.onExiting}
                                        onExited={this.onExited}
                                        key={banner.src}
                                    >
                                        <img src={banner.src} alt={banner.altText} onClick={this.handlePromoClick.bind(this, index)} />
                                    </CarouselItem>
                                ))}
                        <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                        <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                    </Carousel>
                }
                <Container>
                    <Slider className="product-slider" {...slickSettings}>
                        <Link to="/products/Racing Horse Calendar With Header" className="disabled-link">
                            <img className="product-slide" src={calendarWithHeader} alt="racing horse calendar with header" />
                        </Link>
                        <Link to="/products/Racing Horse Calendar Without Header" className="disabled-link">
                            <img className="product-slide" src={calendarNoHeader} alt="racing horse calendar no header" />
                        </Link>
                        <Link to="/products/Flyer">
                            <img className="product-slide" src={flyerPrinting} alt="flyer icon" />
                        </Link>
                        <Link to="/products/Brochure">
                            <img className="product-slide" src={brochureIcon} alt="brochure icon" />
                        </Link>
                        <Link to="/products/Digital Business Card">
                            <img className="product-slide" src={digitalBcIcon} alt="digital business card icon" />
                        </Link>
                        <Link to="/products/Offset Business Card">
                            <img className="product-slide" src={offsetBcIcon} alt="offset business card icon" />
                        </Link>
                        <Link to="/products/Digital Booklet">
                            <img className="product-slide" src={bookDigitalPrinting} alt="digital booklet icon" />
                        </Link>
                        {/* <Link to="/products/Offset Booklet">
                            <img className="product-slide" src={offsetBookletIcon} alt="offset booklet icon" />
                        </Link> */}
                    </Slider>
                    <Row>
                        <Col className="my-4 mt-lg-1 text-center">
                            <img className="img-fluid" src={fiveEasyStep} title="5-easy-steps" alt="5 Easy Steps" />
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = ({ product }) => {
    return { banners: product.banners };
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchBanners: fetchBanners
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(HomePage);
