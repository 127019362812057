import React, { Component } from 'react';

import {
  Container,
  Row,
  Col,
} from 'reactstrap';

import './FaqsPage.css';

class FaqsPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container className="my-3 faq-page">
        <Row>
          <Col>
          <h1 className="my-2">FAQS</h1>
          <h2 className="mt-3 my-2">Membership</h2>
          <ol>
            <li>
              <strong> Who can be a UTECH member?</strong><br />
              Anyone residing in Malaysia who is 18 years old and above is eligible for Membership. However, approval of
              membership is subjected to the discretion of the management of U-TECH ENTERPRISE.
            </li>

            <li>
              <strong> Why become a UTECH member?</strong><br />
              Being a UTECH member allows you to sell our products and services to your customers at a more competitive price.
              You can enjoy a higher profit margin and definitely a more lucrative direct income through our Agent pricing.
            </li>
            <li>
              <strong> How do I become a UTECH member?</strong><br />
              There are 2 ways to join as our authorised dealer:<br />
              i) Visit our official UTECH website at www.utech.com.my to sign up as our authorised dealer online.<br />
              ii) Visit our sales office to join as our authorised dealer.<br />
              <br />
              There is no registration fee or advance payment for the membership registration.
            </li>
            <li>
              <strong> How much profit can I make?</strong><br />
              As a UTECH member, you are guaranteed with a lower member pricing for every product you sell. This means that a
              higher profit margin to you. On top of that, we have special member promotions and programs which offer rebates as
              well as commissions that enable you to earn more from time to time. Of course the more you sell, the more you earn!
            </li>
            <li>
              <strong> Will UTECH ensure the confidentiality of my customers' data?</strong><br />
              Yes. All information sent to UTECH is kept with the strictest of confidentiality. Moreover, we only offer our
              services to design-print professionals, and not end-users.
            </li>
          </ol>

          <h2 className="mt-3 my-2">My Account</h2>
          <ol>
            <li>
              <strong> How do I create an Online Account?</strong><br />
              You will be provided with an Online Account automatically when you become a UTECH member. Only 1 online account per
              membership is allowed. For online <strong>Interbank Fund Transfer</strong>, we will reload your account once we receive the bank
              verification of your credited funds to U-TECH ENTERPRISE shown on the printout deposit slip that you upload the transaction slip for us. For <strong>cash deposit</strong>, your account will normally be reloaded within the very
              same day of payment. For <strong>cheque deposit</strong>, your account will be reloaded upon the clearance of the cheque.​​​
            </li>
            <li>
              <strong> What are Credits (account balance)?</strong><br />
              Your Credits are your credit balance. 1 Credit = RM1.00 (ringgit malaysia)
            </li>
            <li>
              <strong> How secure is my account?</strong><br />
              Your online transactions are done via a protected UTECH server. We routinely check and update our security
              perimeters to ensure all information and data stored on our server is not compromised.
            </li>
            <li>
              <strong> How do I terminate my Membership Account? If there is a balance in my account, is it refundable upon
                termination of my membership?</strong><br />
              Your balance is NON-REFUNDABLE. Therefore, we encourage you to spend all your balance before proceed to account
              terminating process. Kindly send us an email saying that you would like to terminate your Account.
            </li>
            <li>
              <strong> What happens if my account is inactive for a period of time?</strong><br />
              Your account balance will not be forfeited if your account is inactive for a period of time.
            </li>
            <li>
              <strong> Why do I get an error whenever I try to login?</strong><br />
              Your password might be incorrect. You will have to reset your password: Click on the "Forgotten Password" link and
              key-in the information according to the instructions. A new password will be sent to your email address. You will
              need to reset your password at My Account: Click on Change your password.
            </li>
          </ol>

          <h2 className="mt-3 my-2">Artwork Design</h2>
          <ol>
            <li>
              <strong>Do you offer artwork design services?</strong><br />
              No. You have to create your own design and we will do the printing.
            </li>
            <li>
              <strong>Does UTECH provide artwork proof checking services?</strong><br />
              No. You have to confirm the final artwork with your customer before sending it to us for printing.
            </li>
            <li>
              <strong>What file format should I use to save my artwork?</strong><br />
              We accept the artwork in: <br />
              i) Adobe Illustrator CS6 (ai) <br />
              ii) Adobe Acrobat Document (pdf) <br />
              iii) Coreldraw X5 (cdr)
            </li>
            <li>
              <strong>How do I send my artwork?</strong><br />
              You send your artwork to us by uploading it to our server during the ordering step (for file less than 20MB). If
              your file more than 20MB, there is an URL link field in ordering page which you can paste your URL link. We will
              download your file from the link given.
            </li>
            <li>
              <strong>What will happen to my order if my artwork does not meet UTECH artwork specifications?</strong><br />
              Your order will be put on hold. You will be notified via email from UTECH and you are required to resend the
              corrected artwork to email ( online@utech.com.my ). If we do not hear from you, or receive your corrected artwork
              within 30 days, your order will be considered null and void.<br />
              <br />
              UTECH will usually inform you of an error by email within 2 hours after your order is received.
              (Note: Orders that are submitted close to closing time will only be screened the following day, and if the error is
              detected, pending notice will only be forwarded to you after 9am on the same day.)
              (Refer to UTECH Process Ordering Cut-off Time.)
            </li>
            <li>
              <strong>Can I change or cancel my design after submitting it?</strong><br />
              Yes, you can change and cancel the design after submitting your artwork, ONLY IF your order is not yet in the
              printing process. Kindly contact Customer Service to check the status of your order.
            </li>
            <li>
              <strong>Can I design my artwork in other sizes?</strong><br />
              Yes, but you need get quote and check on the size availability for print. Please follow our product sizes. These
              are the sizes that we highly recommend that serve majority needs.
            </li>
            <li>
              <strong>Will the colour of my printed product match 100% to the original colour of my artwork?</strong><br />
              No. It is a known fact within the printing industry that 100% process colour accuracy is not presently possible
              with the current print technology. Therefore, even if a printing company were to employ highly experienced
              personnel including the latest press technology, the outcome of colour of the printed product will always differ
              from the original artwork. The level of difference however is subject from printing company to printing company.<br/>
              <br/>
              Other factors that may cause colour deviation:<br/>
              i) Paper Stock - The quality of paper, its surface and brightness can affect exact outcome of printing colours<br/>
              ii) Humidity Levels - damp weather can cause saturation of ink colours.<br/>
              iii) Make of Press Machines - different press machines produce different colour results.<br/>
              iv) Monitor Displays and Colour Settings - unmatching calibration of monitor colour or colour settings (RGB, CMYK) can misrepresent final colour of output.
            </li>
            <li>
              <strong>Can I use UTECH to print anything I want?</strong><br />
              Yes you can, as long as what you are printing does not conflict with the Malaysian printing and publication law.
              UTECH will not print jobs that contain offensive literature or images. This includes information that are
              politically, religiously or racially, sensitive. UTECH will not be responsible for any infringement of artwork
              content.
            </li>
          </ol>

          <h2 className="mt-3 my-2">Delivery</h2>
          <ol>
            <li>
              <strong>Will I have to pay for the delivery of my goods?</strong><br />
              Yes you will have to pay for delivery if you choose courier service.
            </li>
            <li>
              <strong>How will my goods be delivered?</strong><br />
              All goods are delivered by our appointed courier. UTECH only works with reliable courier service providers that
              have good track records.
            </li>
            <li>
              <strong>What time can I expect delivery?</strong><br />
              While we strive to send your completed order out on time, the exact time of its arrival to your doorstep will
              depend on the courier service company. Although the courier service company we have engaged delivers to most
              places, some areas require longer delivery time due to inconsistent demand and sometimes lack of road
              infrastructure.
            </li>
            <li>
              <strong>Do you provide rushed (overnight) delivery?</strong><br />
              No. We do not provide rush order services currently.
            </li>
            <li>
              <strong>Which areas do your delivery service cover?</strong><br />
              Our appointed courier service provider delivers to all states in Malaysia. However, some areas require longer
              delivery time due to inconsistent demand and sometimes lack of road infrastructure.
            </li>
            <li>
              <strong>Can I collect my orders personally from your office?</strong><br />
              Yes, you can. Kindly select “Self Collect” during ordering. Kindly refer our self pick up time.
            </li>
            <li>
              <strong>Can you send the orders to my customers?</strong><br />
              Yes. Please provide us your new billing details during the CHECKOUT process.
            </li>
            <li>
              <strong>What happens if I am not around to receive my goods?</strong><br />
              Our courier service provider would normally contact you in advance of delivery. Please inform them if you wish your
              goods to be delivered at a specific time. Another way would be to choose new billing address when you CHECKOUT your
              order. This option allows you to have your goods delivered to you at a different address other than your default
              address in your profile.
            </li>
            <li>
              <strong>Does UTECH guarantee punctual delivery of my goods?</strong><br />
              While we are committed to the timely delivery of goods to all our members, we cannot guarantee you 100% punctuality
              due to factors such as Acts of God, national emergencies and the efficiency of the courier service company. Also,
              some areas require longer delivery time due to inconsistent demand and sometimes lack of road infrastructure. Here
              are some reasons that can cause delay:<br/>
              i) Your courier service outlet in your area is inconsistent<br/>
              ii) Delivery day falls on Saturday<br/>
              iii) The courier service outlet in your zone is closed for a public holiday<br/>
              iv) Production delay due to re-print
            </li>
            <li>
              <strong>What should I do if I don't receive my orders on time or if there's a mix up in my order?</strong><br />
              Contact our Customer Service at customercare@utech.com.my or call 604-502 3256. Provide us your Order ID number and
              tell us the problem. We'll try to sort it out as soon as possible.
            </li>
            <li>
              <strong>How do I change my default Delivery Address?</strong><br />
              Go to My Account Address Book and update your address. (You need to do this before you place an order.) After you
              have updated your account, please contact our customer service so we can take note of the changes. Note: any change
              of information after your order is submitted may cause a delay in the production and delivery of your goods to your
              address.
            </li>
          </ol>

          {/* <h2 className="mt-3 my-2">Good & Service Tax (GST)</h2>
          <ol>
            <li>
              <strong>Is UTECH charging GST?</strong><br />
              Yes, transactions with UTECH are subject to GST.
            </li>
            <li>
              <strong>Why is UTECH charging 6% GST?</strong><br />
              UTECH, owned and managed by U-TECH SDN. BHD. is a GST registered company. For more details on GST,
              visithttp://gst.customs.gov.my/front.html
            </li>
            <li>
              <strong>What are UTECH's GST Details?</strong><br />
              GST NO.: 000953057280
              COMPANY NAME: PERCETAKAN U-TECH SDN BHD
              ADDRESS: 7, SOLOK SUNGEI PINANG 4, 11600 JELUTONG, PULAU PINANG.
            </li>
            <li>
              <strong>Are prices shown on the Ordering Page (website) inclusive of GST?</strong><br />
              No, they aren’t. The prices shown are exclusive of 6% GST.
            </li>
            <li>
              <strong>Is Langkawi, Labuan and Tioman subject to GST?</strong><br />
              No, they are not. Langkawi, Labuan and Tioman fall under the Designated Area (DA) category. Goods supplied from
              Malaysia to DA is to be zero rated except otherwise prescribed by the Minister. For more information
              visit http://gst.customs.gov.my/en/ib/Pages/ib_sr.aspx
            </li>
            <li>
              <strong>Do we need to provide GST form?</strong><br />
              Yes. You need to provide us your GST form only then can we issue the Tax Invoice with the correct details, so that
              you can make claims for input tax.
            </li>
            <li>
              <strong>When and where can I get the Tax Invoices for my transactions?</strong><br />
              The Tax Invoice shall be issued after the shipment of your goods. View and print your Tax Invoice from Order
              History.
            </li>
          </ol> */}

          <h2 className="mt-3 my-2">My Order</h2>
          <ol>
            <li>
              <strong>How do I place my order?</strong><br />
              All orders can only be made through our website only if you are an UTECH member.
              Step 1: Click "PRODUCT" link, choose product.
              Step 2: Select product specifications and upload your artwork (through URL link or upload file interface)
              Step 3: Add to shopping cart and view cart to confirm order details.
              Step 4: Checkout to confirm billing address, payment method and confirm order.
            </li>
            <li>
              <strong>How do I confirm my order?</strong><br />
              You are required to fill in all the necessary details in the Ordering Page (which includes uploading your artwork),
              click the "ADD TO CART" button. Your order will be added into Shopping Cart. To check your order, you have to View
              Cart. Then, Checkout to confirm order.
            </li>
            <li>
              <strong>How long will I have to wait for my goods to arrive?</strong><br />
              Each product has a different delivery duration. The exact delivery date will be stated on your Order Slip. You may
              view the Product Specifications for the delivery duration of all products.
            </li>
            <li>
              <strong>Can I cancel or change my order?</strong><br />
              If you have detected an error only after submitting your order to us, ONLY IF your order is not yet in the printing
              process. Kindly contact Customer Service immediately, so that we can put your job on hold until you make the
              necessary amendments. In such a case, please note that there will be a delay in the completion and delivery of your
              goods. UTECH will not be held responsible for any matter resulting from a member's failure to contact UTECH
              promptly or to convey the necessary instructions regarding his/her orders.
            </li>
            <li>
              <strong>How can I keep track of my orders and my credits (account balance)?</strong><br />
              Login to My Account to check your Order History and Your Credits.
            </li>
            <li>
              <strong>What do I do if I am dissatisfied with my order?</strong><br />
              If you are not satisfied with your printed goods, please write to our Customer Service (customercare@utech.com.my)
              within 1 week (7 days) of receiving your order. Please include your order number and reason for dissatisfaction. If
              you have not received your order within the specified delivery time, please write to our Customer Service
              (customercare@utech.com.my) not later than 1 week (7 days) from the date of the specified delivery time. Our
              Customer Service department will review your request and work with you to meet your expectations.<br/>
              <br/>
              PLEASE NOTE: We will only reprint or refund the cost of any product that fails to meet the conditions under the
              General Expectation on Printing Outcome (Terms & Conditions). However, we are not responsible for typing, image, or
              design errors introduced by customers in the artwork/document creation process. In an effort to keep costs down and
              pass those savings along to our customers, UTECH does not review artwork/documents for content or spelling.
            </li>
            <li>
              <strong>How I know there is a Pending Job and what should I do if I have a Pending Job?</strong><br />
              You can check your order status at Order History. You will also receive an email notification. You need to resend
              us the corrected file by email. Any pending orders might caused unncessary delay. Therefore always refer to our
              artwork guidelines and product size template.
            </li>
          </ol>
          <h2 className="mt-3 my-2">Payment</h2>
          <ol>
            <li>
              <strong>How do I pay for my order?</strong><br />
              UTECH uses a prepaid system whereby you need to deposit credit into your UTECH account as your store credits. Each
              time you make an order, payment for it will be deducted from the your credits in your account. You must have
              sufficient credits in your account to pay for your order. You may top-up/reload your account anytime.
            </li>
            <li>
              <strong>How do I top-up Your Credits?</strong><br />
              Credit reload methods:<br /><br />

              (i) cash bank-in to U-TECH ENTERPRISE and deposit into UTECH bank account at<br />
              Public Bank: PBB 381 623 1200<br /><br />

              (ii) cheque made payable to U-TECH ENTERPRISE and deposit into UTECH bank account at<br />
              Public Bank: PBB 381 623 1200<br /><br />

              (iii) interbank funds transfer to<br />
              Public Bank: PBB 381 623 1200
            </li>
            <li>
              <strong>What is the recommended credit amount to top-up into UTECH Account?</strong><br />
              There is no minimum reload credit as long as the top-up credit is sufficient to make your order.
            </li>
            <li>
              <strong>What is the purpose of Store Credits?</strong><br />
              Your Credits serve as advance credits for your future orders. Each time you make an order, order amount for it will
              be deducted from the Your Credits in your account. You cannot make an order if you do not have enough credits in
              your account, so make sure there are sufficient credits in My Account at all times. You can reload your account
              whenever necessary.
            </li>
            <li>
              <strong>Will I get an Tax Invoice for my order? And how will I get it?</strong><br />
              You will be issued an electronic Tax Invoice for every order you make only after the shipment of goods. To view or
              print out an the invoice, you will need to access My Account and go to Order History.
            </li>
            <li>
              <strong>How do I report a billing error to UTECH?</strong><br />
              Just send us an email reporting the error at customercare@utech.com.my. Describe the problem to us and we will get
              back to you as soon as possible.
            </li>
          </ol>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default FaqsPage;
