import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import { checkArray } from "../Utility";
/**
 * FormGroup for Hot Stamping configuration
 *
 * @param {string} name - unique id for this element *
 * @param {string} label - Display for the drop down
 * @param {function} onChange - callback to handle changes
 * @param {Array} optionItemsSource - list of items
 * @param {*} selectedOption - current selected value
 * @param {Array} colourItemsSource - list of colors
 * @param {*} selectedColour1 - color 1
 * @param {*} selectedColour2 - color 2
 */
export default class HotStampingFormGroup extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        // checks
        if (Array.isArray(this.props.optionItemsSource) === false ||
            Array.isArray(this.props.colourItemsSource) === false ||
            this.props.selectedOption === null) {
            console.log(`${this.constructor.name} not rendering due to unfullfilled prerequisites`)
            return null;
        }

        let colour1Name = `${this.props.name}_colour_1`;
        let colour1Label = `${this.props.label} 1`;
        let colour2Name = `${this.props.name}_colour_2`;
        let colour2Label = `${this.props.label} 2`;
        return (
            <Fragment>
                <FormGroup row>
                    <Label htmlFor={this.props.name} sm={3}>{this.props.label}<br/><small style={{ color: 'red' }}>(optional finishing)</small></Label>
                    <Col sm={9}>
                        <Input type="select" name={this.props.name} id={this.props.name} value={this.props.selectedOption} onChange={this.props.onChange}>
                            {
                                this.props.optionItemsSource.map(x =>
                                    <option key={x} value={x}>{x}</option>
                                )
                            }
                        </Input>
                    </Col>
                </FormGroup>
                {
                    this.props.selectedOption.includes('C') &&
                    <FormGroup key={colour1Name} row>
                        <Label htmlFor={colour1Name} sm={3}>{colour1Label}</Label>
                        <Col sm={9}>
                            <Input type="select" name={colour1Name} id={colour1Name} value={this.props.selectedColour1} onChange={this.props.onChange}>
                                {
                                    this.props.colourItemsSource.map(value =>
                                        <option key={value} value={value}>{value}</option>
                                    )
                                }
                            </Input>
                        </Col>
                    </FormGroup>
                }
                {
                    this.props.selectedOption.includes('2C') &&
                    <FormGroup key={colour2Name} row>
                        <Label htmlFor={colour2Name} sm={3}>{colour2Label}</Label>
                        <Col sm={9}>
                            <Input type="select" name={colour2Name} id={colour2Name} value={this.props.selectedColour2} onChange={this.props.onChange}>
                                {
                                    this.props.colourItemsSource.map(value =>
                                        <option key={value} value={value}>{value}</option>
                                    )
                                }
                            </Input>
                        </Col>
                    </FormGroup>
                }
            </Fragment>
        )
    }
}