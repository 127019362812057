import React, { Component } from 'react';

import {
  Container,
  Row,
  Col,
} from 'reactstrap';

import './AboutPage.css';

import aboutImage from './about-img.jpg';
import offsetImage from './offset-img.jpg';

class AboutPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container className="my-3">
        <Row>
          <Col>
            <h1 className="my-2">ABOUT US</h1>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="5">
            <img className="img-fluid" src={aboutImage} title="about" alt="About"/>
          </Col>
          <Col xs="12" md="7">
            <p>Owing to team commitment and tireless effort in their duties (in line with expansion programme), Percetakan U-Tech Sdn Bhd was incorporated in the year 2003 replacing U-Tech Enterprise which was established in the year 1990.</p>

            <p>We are a printing company which major in offset printing, digital printing, product finishing, PVC card, and UV/Eco Solvent inkjet printing. Our company is well equipped with present technology and machineries from printing to finishing process which are handled by a team of experienced and skilled personnel.</p>

            <p>Present streams of customers ranges from: Multinational companies, corporate companies, commercial and non commercial offices, advertising agencies and designers.</p>

            <h2>Company Policy</h2>
            <p>Provide added value services with cost efficient towards customer borderless requirements and expectations are vital in our company.</p>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="7">
            <h2>Offset &amp; Digital</h2>
            <p>Our company is equipped with offset 4 colour machine - Komori Lithrone and 2 Colour machine for mass printing demand and digital printer for low volume demand.</p>

            <h2>Finishing</h2>
            <p>Beside printing services, we also provide one-stop finishing services.</p>

            <p>Finishing available:</p>
            <ul>
              <li>Matte or Gloss Lamination</li>
              <li>Die Cutting</li>
              <li>Folding</li>
              <li>Book Saddle Stitching</li>
              <li>Book Perfect Binding</li>
            </ul>
          </Col>
          <Col xs="12" md="5">
            <img className="img-fluid d-none d-md-inline-block float-right" src={offsetImage} title="offset" alt="Offset"/>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default AboutPage;
