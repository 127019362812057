import { START_FILE_UPLOAD, UPDATE_TOTAL_UPLOADS, UPLOAD_FILENAME, FILE_UPLOAD_SUCCESS, FILE_UPLOAD_FAILED, RESET_UPLOAD_COUNT, ADD_TO_CART, UPDATE_CART, EMPTY_CART, CALCULATE_SHIPPING } from '../actions/types';
const uuid = require('uuid/v1');

const INIT_STATE = {
  upload_started: false,
  total_uploads: 0,
  upload_count: 0,
  upload_filename: '',
  upload_url: '',
  filenames: [],
  download_url: null,
  items: (localStorage.getItem('utech_cart')) ? JSON.parse(localStorage.getItem('utech_cart')).items : [],
  cover_artwork: '',
  cover_artwork_path: null,
  inner_artwork: '',
  inner_artwork_path: null,
  jpeg_reference: '',
  jpeg_reference_path: null,
  total: (localStorage.getItem('utech_cart')) ? JSON.parse(localStorage.getItem('utech_cart')).total : 0.0,
  delivery_option: 'SELF COLLECT',
  shipping_fee: 0.0,
  shipping_disc: 0.0
}

export default (state = INIT_STATE, action) => {
  let uploaded_list = state.filenames;

  switch (action.type) {
    case START_FILE_UPLOAD:
      console.log(state);
      return {
        ...state,
        upload_started: true
      }

    case UPLOAD_FILENAME:
      return {
        ...state,
        upload_filename: action.payload
      }

    case UPDATE_TOTAL_UPLOADS:
      return {
        ...state,
        total_uploads: action.payload
      }

    case FILE_UPLOAD_SUCCESS:
      uploaded_list.push(state.upload_filename);
      let addition = {}

      if (action.payload.name.includes('cover')) {
        addition.cover_artwork = state.upload_filename;
        addition.cover_artwork_path = action.payload.url;
      } else if (action.payload.name.includes('inner')) {
        addition.inner_artwork = state.upload_filename;
        addition.inner_artwork_path = action.payload.url;
      } else if (action.payload.name.includes('jpeg_ref')) {
        addition.jpeg_reference = state.upload_filename;
        addition.jpeg_reference_path = action.payload.url;
      } else if (action.payload.name.includes('header')) {
        addition.header_artwork = state.upload_filename;
        addition.header_artwork_path = action.payload.url;
      }

      return {
        ...state,
        upload_count: state.upload_count + 1,
        filenames: [],
        ...addition
      }

    case FILE_UPLOAD_FAILED:
      return {
        ...state,
        upload_count: -1,
        total_uploads: -1
      }

    case RESET_UPLOAD_COUNT:
      return {
        ...state,
        upload_started: false,
        total_uploads: 0,
        upload_count: 0,
        upload_filename: '',
        upload_url: '',
        filenames: []
      }

    case ADD_TO_CART:
      let items = state.items;
      let total = 0.0;
      action.payload.id = uuid();

      console.log(action.payload);
      items.push(action.payload);

      items.forEach((n) => {
        total += n.price;
      });

      localStorage.setItem('utech_cart', JSON.stringify({ items: items, total: total }))
      return { ...state, items: items, total: total }

    case UPDATE_CART:
      localStorage.setItem('utech_cart', JSON.stringify(action.payload))
      return {
        ...state,
        ...action.payload
      }

    case EMPTY_CART:
      localStorage.removeItem('utech_cart');
      return {
        ...state,
        items: [],
        total: 0.0,
        shipping_fee: 0.0,
        shipping_disc: 0.0,
        delivery_option: 'SELF COLLECT',
        voucher_code: null,
        voucher_discount: null,
        voucher_code_error: null
      }

    case CALCULATE_SHIPPING:
      return { ...state, ...action.payload }

    default:
      return state;
  }
}