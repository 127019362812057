import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import './OrientationFormGroup.css'
import { checkArray } from "../Utility";
/**
 * FormGroup for Orientation configuration
 * 
 * @param {String} name - Name of the configuration. Used to identify which configuration has changed.
 * @param {String} label - Display for the drop down
 * @param {*} selectedItem - current selected value
 * @param {Array} itemsSource - list of items
 * @param {Function} onChange - callback to handle changes
 */
export default class OrientationFormGroup extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if(Array.isArray(this.props.itemsSource) === false) {
            console.log(`${this.constructor.name} not rendering due to unfullfilled prerequisites`)
            return null
        }

        return (
            <FormGroup row>
                <Label htmlFor={this.props.name} sm={3}>{this.props.label}</Label>
                <Col sm={9}>
                    {
                        this.props.itemsSource.map(value =>
                            {
                                let orientationId = `orientation_${value}`;
                                let orientationClassName = `orientation_img orientation_${value}`;
                                return (
                                    <FormGroup check inline key={value}>
                                        <Label check>
                                            <Input type="radio"
                                                name={this.props.name}
                                                id={orientationId}
                                                value={value}
                                                checked={value === this.props.selectedItem}
                                                onChange={this.props.onChange} />
                                            {value}
                                            <div id={orientationId} className={orientationClassName}></div>
                                        </Label>
                                    </FormGroup>
                                );
                            }
                        )
                    }
                </Col>
            </FormGroup>
        )
    }
}