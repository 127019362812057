import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAlert } from 'react-alert';
import { urlToProperty } from "query-string-params";
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Progress, Popover, PopoverBody } from 'reactstrap';
import { getProductSetup, getProductTimeAndCost, resetProductTimeAndCost, uploadFiles, resetUploads, addToCart } from '../../../actions'
import './ProductPage.css';
import SizeFormGroup from './Components/SizeFormGroup';
import GenericDropdownsFormGroup from './Components/GenericDropdownsFormGroup';
import TimeAndCostFormGroup from './Components/TimeAndCostFormGroup';
import SelectFileFormGroup from './Components/SelectFileFormGroup';
import JsonDisplay from './Components/JsonDisplay';
import { isObjValueEqual, matchCurrentConfigToVariant, readFileToBase64StringPromise, productSpecUrl, productPriceUrl } from "./Utility";
import BookletPrintColourDropdownsFormGroup from './Components/BookletPrintColourDropdownsFormGroup';
import ManualInputFormGroup from './Components/ManualInputFormGroup';
import Specification from './Components/Specifications';
import LoadingIndicator from './Components/LoadingIndicator';
import SteppingDropdownsFormGroup from './Components/SteppingDropdownsFormGroup';

class DigitalBooklet1Color extends Component {
    constructor(props) {
        super(props);
        this.product = '1 Colour Digital Booklet';
        this.state = {
            /**
             * Dynamic options for certain configuration
             */
            dynamic_options: {
                lamination: []
            },
            /**
             * Current configuration selected by end-user
             */
            configurations: {
                size: null,
                binding: null,
                quantity: 0,
                cover_material: null,
                print: null,
                print_inner: null,
                lamination: null,
                inner_material: null,
                pages: null,
                cover_artwork: null,
                cover_artwork_path: null,
                cover_artwork_download_url: null,
                inner_artwork: null,
                inner_artwork_path: null,
                inner_artwork_download_url: null,
                jpeg_reference: null,
                jpeg_reference_path: null,
                jpeg_reference_download_url: null,
                weight: 0.0
            },
            /**
             * Time and cost looked up based on configurations
             */
            price_and_duration: {
                price: NaN,
                duration: 0
            },
            isLoadingData: true,
            invalid: false
        };

        this.props.getProductSetup('Digital Booklet');
    }

    /**
     * Invoked immediately after a component is mounted (inserted into the tree).
     * Initialization that requires DOM nodes should go here.
     * If you need to load data from a remote endpoint, this is a good place to instantiate the network request.
     */
    componentDidMount() {
        document.title = `U-Tech - ${this.product}`
    }

    /**
     * Invoked immediately after updating occurs.
     * This method is not called for the initial render.
     * Use this as an opportunity to operate on the DOM when the component has been updated.
     * This is also a good place to do network requests as long as you compare the current props to previous props (e.g. a network request may not be necessary if the props have not changed).
     *
     * @param {*} previousProps Previous properties before this completed rendering cycle
     * @param {*} previousState Previous state before this already completed rendering cycle
     */
    componentDidUpdate(previousProps, previousState) {
        if ((previousProps.product.setup !== this.props.product.setup) && this.props.product.setup.options) {
            let newConfigurations = this.getDefaultConfigurations(this.props.product.setup.options);
            let newDynamicOptions = this.getDynamicOptions(newConfigurations, this.props.product.setup.options);

            this.setState({
                dynamic_options: { ...this.props.product.setup.options, ...newDynamicOptions },
                configurations: newConfigurations,
                isLoadingData: false
            });
        }

        if (previousState.configurations !== this.state.configurations) {
            let newConfig = this.state.configurations;
            let newPriceAndDuration = this.state.price_and_duration;
            let newDynamicOptions = this.getDynamicOptions(newConfig, this.props.product.setup.options);

            // After getting Dynamic Options, lamination and binding configs need to be defaulted to new dynamic option defaults otherwise it is stale
            if (!newDynamicOptions.lamination.includes(this.state.configurations.lamination)) newConfig.lamination = newDynamicOptions.lamination[0];
            if (newConfig.binding !== previousState.configurations.binding) {
                if (!newDynamicOptions.cover_material.includes(this.state.configurations.cover_material)) {
                    // Defaulting Cover Material
                    newConfig.cover_material = newDynamicOptions.cover_material[0];
                    window.alert('Cover material reset due to book binding changed');
                }

                let pages = parseInt(newConfig.pages);

                if ((pages < newDynamicOptions.pages.range.min)
                    || (pages > newDynamicOptions.pages.range.max)
                    || (((pages - newDynamicOptions.pages.range.min) % newDynamicOptions.pages.range.step) !== 0)) {
                    // Defaulting Inner Pages
                    newConfig.pages = newDynamicOptions.pages.range.min;
                    window.alert('Inner pages reset due to book binding changed');
                }
            }

            // Price calculation
            if (this.props.product.setup) {
                // console.log(this.props.product.setup.variants[0]);
                const { size, cover_material, lamination, print, binding, inner_material } = this.state.configurations;
                let quantity = parseInt(this.state.configurations.quantity);
                let pages = parseInt(newConfig.pages);

                let pricing = this.props.product.setup.variants[0];

                // Calculate cover paper price
                let cover_paper = pricing.material.prices.find((el) => cover_material.includes(el.paper_type) && cover_material.includes(el.weight));
                let cover_paper_price = null;

                // Assuming it is only A5 or 4x6 as both calculation are same, if not paper sizes are added will need more conditions
                let adj_cover_qty = Math.ceil(((size.type === 'A4') || (size.type === 'B5')) ? quantity + 5 : (quantity + 5) / 2);
                let adj_inner_qty = quantity + 5;
                console.log('Adj Cover Qty = ' + adj_cover_qty);
                console.log('Adj Inner Qty = ' + adj_inner_qty);

                if (cover_paper) {
                    cover_paper_price = adj_cover_qty * cover_paper.unit_price;
                }

                console.log('Cover Paper Unit Price = ' + cover_paper.unit_price);
                console.log('Cover Paper Price = ' + cover_paper_price);

                if (cover_material.toUpperCase().includes('PAPER')) {
                    if (cover_paper_price < pricing.material.min_paper_price) {
                        cover_paper_price = pricing.material.min_paper_price;
                    }
                } else {
                    if (cover_paper_price < pricing.material.min_card_price) {
                        cover_paper_price = pricing.material.min_card_price;
                    }
                }
                // console.log('Updated Cover Paper Price = ' + cover_paper_price);


                // Calculate cover lamination price
                let lamination_price = NaN;

                if (lamination.toUpperCase().includes('FRONT')) {
                    lamination_price = ((adj_cover_qty - 35) * 12.5 * 18.5 * pricing.lamination.price_per_sq_inch) + pricing.lamination.one_sided_min_price;
                    if (lamination_price < pricing.lamination.one_sided_min_price) {
                        lamination_price = pricing.lamination.one_sided_min_price;
                    }
                } else if (lamination.toUpperCase().includes('BOTH')) {
                    lamination_price = ((adj_cover_qty - 40) * 12.5 * 18.5 * pricing.lamination.price_per_sq_inch * 2) + pricing.lamination.two_sided_min_price;
                    if (lamination_price < pricing.lamination.two_sided_min_price) {
                        lamination_price = pricing.lamination.two_sided_min_price;
                    }
                } else {
                    lamination_price = 0.0;
                }

                // Calculate inner paper price
                let sheets_per_book = ((size.type === 'A4') || (size.type === 'B5')) ? Math.ceil(pages / 4) : (Math.ceil(pages / 4) / 2);
                let inner_paper = pricing.material.prices.find((el) => inner_material.includes(el.paper_type) && inner_material.includes(el.weight));
                let inner_paper_price = adj_inner_qty * sheets_per_book * inner_paper.unit_price;
                console.log('Inner paper unit price = ' + inner_paper.unit_price);

                if (inner_material.toUpperCase().includes('PAPER')) {
                    if (inner_paper_price < pricing.material.min_paper_price) {
                        inner_paper_price = pricing.material.min_paper_price;
                    }
                } else {
                    if (inner_paper_price < pricing.material.min_card_price) {
                        inner_paper_price = pricing.material.min_card_price;
                    }
                }
                console.log('Inner paper price = ' + inner_paper_price);

                // Calculate printing price
                let unit_cover_print_price = NaN;        // Default unit print price
                let unit_inner_print_price = NaN;        // Default unit print price
                let printing_price = NaN;
                let cover_printing_price = NaN;
                let inner_printing_price = NaN;

                // Calculate cover printing price
                let cover_print_price_entry = pricing.printing.find((el) => ((el.material.toUpperCase() === 'PAPER') && (el.type.toUpperCase() === '4 COLOUR')));
                if (cover_print_price_entry) {
                    let cover_sides = (print.toUpperCase().includes('OUTER') && print.toUpperCase().includes('INNER')) ? 2 : 1;
                    let cover_print_qty = adj_cover_qty * cover_sides;
                    console.log('Cover print quantity = ' + cover_print_qty);

                    cover_print_price_entry.prices.forEach((el) => {
                        if (el.from < cover_print_qty) unit_cover_print_price = el.unit_price;
                    });
                    console.log('Unit cover print price = ' + unit_cover_print_price);

                    cover_printing_price = cover_print_qty * unit_cover_print_price;

                    if (cover_printing_price < cover_print_price_entry.min_printing_price) cover_printing_price = cover_print_price_entry.min_printing_price;
                }

                // Calculate inner printing price for 1 colour
                let inner_print_price_entry = pricing.printing.find((el) => (inner_material.toUpperCase().includes(el.material.toUpperCase()) && (el.type.toUpperCase() === '1 COLOUR')));
                if (inner_print_price_entry) {
                    let inner_print_qty = adj_inner_qty * sheets_per_book;
                    console.log('Inner print quantity = ' + inner_print_qty);

                    inner_print_price_entry.prices.forEach((el) => {
                        if (el.from < inner_print_qty) unit_inner_print_price = el.unit_price;
                    });
                    console.log('Unit inner print price = ' + unit_inner_print_price);

                    inner_printing_price = inner_print_qty * unit_inner_print_price * 2;

                    if (inner_printing_price < inner_print_price_entry.min_printing_price) inner_printing_price = inner_print_price_entry.min_printing_price;
                }

                printing_price = cover_printing_price + inner_printing_price;

                // Calculate binding price
                let binding_price = null;
                if (binding.trim().toUpperCase() === 'SADDLE STITCH') {
                    binding_price = (quantity + 5 - pricing.misc.saddlestitch_cutoff_qty) * pricing.misc.saddlestitch_price_increment + pricing.misc.saddlestitch_min_price;
                    if (binding_price < pricing.misc.saddlestitch_min_price) {
                        binding_price = pricing.misc.saddlestitch_min_price;
                    }
                } else if (binding.trim().toUpperCase() === 'PERFECT BINDING') {
                    binding_price = (quantity + 5 - pricing.misc.perfectbind_cutoff_qty) * pricing.misc.perfectbind_price_increment + pricing.misc.perfectbind_min_price;
                    if (binding_price < pricing.misc.perfectbind_min_price) {
                        binding_price = pricing.misc.perfectbind_min_price;
                    }
                }

                // Calculating total price
                console.log('Sheets per book = ' + sheets_per_book);
                console.log('Cover Paper = ' + cover_paper_price);
                console.log('Inner Paper = ' + inner_paper_price);
                console.log('Lamination = ' + lamination_price);
                console.log('Cover Printing = ' + cover_printing_price);
                console.log('Inner Printing = ' + inner_printing_price);
                console.log('Binding = ' + binding_price);
                if ((cover_paper_price !== NaN) && (lamination_price !== NaN) && (inner_paper_price !== NaN) && (printing_price && binding_price !== NaN)) {
                    newPriceAndDuration.price = Math.ceil(cover_paper_price + lamination_price + inner_paper_price + printing_price + binding_price);
                    newPriceAndDuration.duration = 3;   // Process Day is defaulted to 3 days until further notice
                    // Special pricing handling requested for newly added B5 and 4x6 by discounting total price by 1% as differentiation
                    if ((size.type === 'B5') || (size.type === '4x6')) newPriceAndDuration.price *= 0.99;
                } else {
                    newPriceAndDuration.price = NaN;
                    newPriceAndDuration.duration = 0;
                }
            }

            this.setState({
                dynamic_options: newDynamicOptions,
                price_and_duration: newPriceAndDuration,
                configurations: newConfig
            });

            console.log(newDynamicOptions);
            console.log(newConfig);
        }

        if (previousProps.cart.upload_count !== this.props.cart.upload_count) {
            if ((this.props.cart.total_uploads > 0) && (this.props.cart.total_uploads === this.props.cart.upload_count)) {
                let newConfig = this.state.configurations;
                newConfig.cover_artwork = this.props.cart.cover_artwork;
                newConfig.cover_artwork_path = this.props.cart.cover_artwork_path;
                newConfig.inner_artwork = this.props.cart.inner_artwork;
                newConfig.inner_artwork_path = this.props.cart.inner_artwork_path;
                newConfig.jpeg_reference = this.props.cart.jpeg_reference;
                newConfig.jpeg_reference_path = this.props.cart.jpeg_reference_path;
                let newDynamicOptions = this.getDynamicOptions(newConfig, this.props.product.setup.options);
                this.setState({
                    dynamic_options: newDynamicOptions,
                    configurations: newConfig
                });
            }
        }
    }

    /**
     * Generate configurations with default value, based on the provided options
     *
     * @param {*} productOptions Product options
     *
     * @returns {Object} configurations with default value
     */
    getDefaultConfigurations(productOptions) {
        let newConfigurations = { ...this.state.configurations }

        const stateConfigKeys = Object.keys(this.state.configurations);
        const optionKeys = Object.keys(productOptions);

        stateConfigKeys.forEach(name => {
            if (optionKeys.includes(name)) {
                let defaultValue = null;

                if (['cover_artwork', 'inner_artwork', 'jpeg_reference',
                    'cover_artwork_download_url',
                    'inner_artwork_download_url',
                    'jpeg_reference_download_url'].includes(name)) {
                    // Use null as default
                    defaultValue = null;
                } else if (['lamination'].includes(name)) {
                    // Use first value's type as default
                    defaultValue = productOptions[name].values[0].type;
                } else if (['quantity'].includes(name)) {
                    defaultValue = 1;
                } else if (['pages'].includes(name)) {
                    defaultValue = productOptions[name].range.min;
                } else if (['print_inner'].includes(name)) {
                    defaultValue = "1 Colour Both Sides";
                } else {
                    // Use first value as default
                    defaultValue = productOptions[name].values[0];
                }

                newConfigurations[name] = defaultValue;
            }
        });

        return newConfigurations
    }

    /**
     * Get latest dynamic options based on the provided configurations
     *
     * @param {Object} productConfigurations Configurations
     * @param {Object} productOptions Product options. If not supplied, component's props.product.options will be used.
     *
     * @returns {Object} New dynamic options. Return existing state's dynamic options if no static product data is available.
     */
    getDynamicOptions(productConfigurations, productOptions = this.props.product.options) {
        // stop if we do not have the data
        if (productOptions == undefined) {
            console.log("Skip update dynamic options due to no data")
            return this.state.dynamic_options;
        }

        let newDynamicOptions = { ...this.state.dynamic_options };

        const selectedBinding = productConfigurations.binding;
        const selectedCoverMaterial = productConfigurations.cover_material;
        const staticLaminationOptions = productOptions.lamination;
        const staticCoverMaterialOptions = productOptions.cover_material;

        // perfect bind -> only gloss art cards allowed
        let dynCoverMaterialOptions = [];
        staticCoverMaterialOptions.values.forEach((element) => {
            if (selectedBinding.toUpperCase() === 'PERFECT BINDING') {
                if (element.toUpperCase().includes('GLOSS ART CARD')) {
                    dynCoverMaterialOptions.push(element);
                }
            } else {
                dynCoverMaterialOptions.push(element);
            }
        });

        // cover_material -> lamination
        let dynLaminationOptions = [];
        staticLaminationOptions.values.forEach((element) => {
            if (element.cover_material.includes(selectedCoverMaterial)) {
                dynLaminationOptions.push(element.type);
            }
        })

        // perfect bind -> inner pages (48 to 250 step of 2), saddle stitch -> inner pages (4 to 80 step of 4) and only front lamination allowed
        let dynInnerPagesOptions;
        if (selectedBinding.toUpperCase() === 'PERFECT BINDING') {
            dynInnerPagesOptions = { range: { min: 48, max: 250, step: 2 } }
            dynLaminationOptions = dynLaminationOptions.filter((lamination) => lamination.includes('None') || lamination.includes('Front'));
        } else {
            dynInnerPagesOptions = { range: { min: 4, max: 80, step: 4 } }
        }

        newDynamicOptions.cover_material = dynCoverMaterialOptions;
        newDynamicOptions.lamination = dynLaminationOptions;
        newDynamicOptions.pages = dynInnerPagesOptions;

        return newDynamicOptions;
    }

    /**
     * Handle the input change event.
     * Configuration & options change resulted by the event will be performed here.
     *
     * @param {*} e eventData
     */
    handleInputChange(e) {
        const options = this.props.product.setup.options;
        let futureConfigs = { ...this.state.configurations };

        if (e.target.type === 'checkbox') {
            // Use checked value instead of normal value
            futureConfigs[e.target.name] = e.target.checked;
        } else {
            // everything else just let it slide
            futureConfigs[e.target.name] = e.target.value;
        }

        if (e.target.name === 'size') {
            // convert json string to obj
            futureConfigs.size = JSON.parse(e.target.value)
        }

        if (e.target.name === 'quantity') {
            if (e.target.value > 700) {
                this.setState({ invalid: true });
            } else {
                this.setState({ invalid: false });
            }
        }

        let futureDynOptions = this.getDynamicOptions(futureConfigs, this.props.product.setup.options);

        this.setState({
            dynamic_options: futureDynOptions,
            configurations: futureConfigs
        });
    }

    /**
     * Handle file selection event.
     * Acquires token from backend and upload files to AliCloud storage.
     *
     * @param {*} e eventData
     */
    handleFileSelect(type, e) {
        e.stopPropagation();
        e.preventDefault();
        let files = e.target.files;

        console.log(files);
        if (files.length > 0) {
            this.props.uploadFiles(files, type);
        }
    }

    /**
     * Generate DOM elements based on product options.
     *
     * @param {string} configName Name of the configuration
     * @param {object} configSettings Configuration data
     */
    generateProductOptionElement(configName, configSettings) {
        const { options } = this.props.product.setup;
        const { configurations, dynamic_options, invalid } = this.state;

        switch (configName) {
            case 'size':
                // static options
                return (
                    <SizeFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        itemsSource={options[configName].values}
                        selectedItem={configurations[configName]}
                        onChange={this.handleInputChange.bind(this)}
                        showType={true}
                    />
                )

            case 'pages':
                // manual input
                return (
                    <SteppingDropdownsFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        value={configurations[configName]}
                        step={dynamic_options[configName].range.step}
                        min={dynamic_options[configName].range.min}
                        max={dynamic_options[configName].range.max}
                        onChange={this.handleInputChange.bind(this)}
                    />
                )

            case 'quantity':
                // manual input
                return (
                    <ManualInputFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        value={configurations[configName]}
                        inputAttributes={{ type: 'number', min: 1 }}
                        onChange={this.handleInputChange.bind(this)}
                        invalid={invalid}
                        errorText='Please use custom quote for quantity exceeding 700 booklets for better pricing!'
                    />
                )

            case 'print':
                return (
                    <BookletPrintColourDropdownsFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        onChange={this.handleInputChange.bind(this)}
                        itemsSource={options[configName].values}
                        selectedItem={configurations[configName]}
                    />
                )
            case 'print_inner':
                // STATIC dropdown options
                return (
                    <BookletPrintColourDropdownsFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        onChange={this.handleInputChange.bind(this)}
                        itemsSource={["1 COLOUR BOTH SIDES"]}
                        selectedItem={configurations[configName]}
                    />
                )

            case 'lamination':
                // DYNAMIC dropdown options
                return (
                    <GenericDropdownsFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        onChange={this.handleInputChange.bind(this)}
                        itemsSource={dynamic_options[configName]}
                        selectedItem={configurations[configName]}
                    />
                )

            case 'cover_material':
                // DYNAMIC dropdown options with section separator
                return (
                    <Fragment key='Cover'>
                        <h5 style={{ textDecoration: 'underline', margin: '2em 0 1em 0' }}>Cover</h5>
                        <GenericDropdownsFormGroup
                            key={configName}
                            name={configName}
                            label='Cover Material'
                            onChange={this.handleInputChange.bind(this)}
                            itemsSource={dynamic_options[configName]}
                            selectedItem={configurations[configName]}
                        />
                    </Fragment>
                )

            case 'inner_material':
                // STATIC dropdown options with section separator
                return (
                    <Fragment key='Inner'>
                        <h5 style={{ textDecoration: 'underline', margin: '2em 0 1em 0' }}>Inner</h5>
                        <GenericDropdownsFormGroup
                            key={'inner_material'}
                            name={'inner_material'}
                            label={`Inner Material`}
                            onChange={this.handleInputChange.bind(this)}
                            itemsSource={options['inner_material'].values}
                            selectedItem={configurations['inner_material']}
                        />
                    </Fragment>
                )

            case 'binding':
                // STATIC dropdown options
                return (
                    <GenericDropdownsFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        onChange={this.handleInputChange.bind(this)}
                        itemsSource={options[configName].values}
                        selectedItem={configurations[configName]}
                    />
                )

            case 'cover_artwork':
                return (
                    <SelectFileFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        accept=".pdf, .rar, .zip"
                        onChange={this.handleFileSelect.bind(this, 'digital_booklet_cover')}
                    />
                )

            case 'inner_artwork':
                return (
                    <SelectFileFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        accept=".pdf, .rar, .zip"
                        onChange={this.handleFileSelect.bind(this, 'digital_booklet_inner')}
                    />
                )

            case 'jpeg_reference':
                return (
                    <SelectFileFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        accept=".jpg, .jpeg"
                        onChange={this.handleFileSelect.bind(this, 'digital_booklet_jpeg_ref')}
                    />
                )

            case 'cover_artwork_download_url':
                return (
                    <ManualInputFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        value={configurations[configName]}
                        inputAttributes={{ placeholder: 'ARTWORK DOWNLOAD URL', style: { textTransform: 'none' } }}
                        onChange={this.handleInputChange.bind(this)}
                    />
                )

            case 'inner_artwork_download_url':
                return (
                    <ManualInputFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        value={configurations[configName]}
                        inputAttributes={{ placeholder: 'INNER ARTWORK DOWNLOAD URL', style: { textTransform: 'none' } }}
                        onChange={this.handleInputChange.bind(this)}
                    />
                )

            case 'jpeg_reference_download_url':
                return (
                    <ManualInputFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        value={configurations[configName]}
                        inputAttributes={{ placeholder: 'JPEG REFERENCE DOWNLOAD URL', style: { textTransform: 'none' } }}
                        onChange={this.handleInputChange.bind(this)}
                    />
                )
            default:
                break;
        }
    }

    /**
     * Check if the required configurations is supplied.
     *
     * @returns True if all required configurations is supplied. False otherwise.
     */
    checkRequiredConfigurations() {
        let missingConfigurations = [];

        // Add checks for required configuration here
        if (this.state.configurations['cover_artwork_path'] == null && (
            this.state.configurations['cover_artwork_download_url'] == null ||
            this.state.configurations['cover_artwork_download_url'] == ""
        )) {
            missingConfigurations.push("Cover Artwork");
        }

        if (this.state.configurations['inner_artwork_path'] == null && (
            this.state.configurations['inner_artwork_download_url'] == null ||
            this.state.configurations['inner_artwork_download_url'] == ""
        )) {
            missingConfigurations.push("Inner Artwork");
        }

        if (this.state.configurations['jpeg_reference_path'] == null && (
            this.state.configurations['jpeg_reference_download_url'] == null ||
            this.state.configurations['jpeg_reference_download_url'] == ""
        )) {
            missingConfigurations.push("JPEG for Reference");
        }

        console.log(missingConfigurations);
        if (missingConfigurations.length > 0) {
            window.alert(`Please supply the missing information: ${missingConfigurations.join(", ")}.`);
            return false;
        }

        if ((this.state.price_and_duration.price <= 0) || (this.state.price_and_duration.duration <= 0)) {
            window.alert(`Invalid product configurations.`);
            return false;
        }

        // everything OK
        return true;
    }

    addToCart() {
        const { cover_material, inner_material, pages, size, quantity } = this.state.configurations;

        if (this.checkRequiredConfigurations() === false) {
            this.setState({ popoverOpen: true });
            return;
        }

        let paper_density = parseInt(cover_material);
        let weight = Math.ceil(2 * quantity * size.height * size.width * paper_density / 1e9) + 2;  // size in mm and density in g to convert to kg (2pc for front and back), round up to nearest kg then add 2kg

        paper_density = parseInt(inner_material);
        weight += Math.ceil((pages / 2) * quantity * size.height * size.width * paper_density / 1e9) + 2;  // size in mm and density in g to convert to kg (2 pages = 1 pc), round up to nearest kg then add 2kg

        let config = { ...this.state.configurations, weight }
        Object.entries(config).forEach(pair => {
            if (pair[1] == null) config[pair[0]] = undefined;
            if (typeof pair[1] === 'boolean') {
                config[pair[0]] = (pair[1]) ? 'Yes' : 'No';
            }
        });

        this.props.addToCart({
            product: this.product,
            configurations: config,
            price: this.state.price_and_duration.price,
            duration: this.state.price_and_duration.duration
        });

        const alert = this.props.alert.show('Item added to cart', {
            timeout: 3000,
            type: 'success',
        });

        this.props.history.push('/cart');
    }

    render() {
        const { setup } = this.props.product;
        const { upload_started, upload_count, upload_filename, total_uploads } = this.props.cart;
        const { configurations, dynamic_options, price_and_duration, isLoadingData, invalid } = this.state;

        if (isLoadingData === true) {
            return (
                <Container>
                    <LoadingIndicator />
                </Container>
            )
        }

        return (
            <Container>
                <Modal isOpen={upload_started} className='scrolling'>
                    <ModalHeader>File Upload</ModalHeader>
                    <ModalBody>
                        {
                            (total_uploads === 0)
                                ? (
                                    <div>
                                        <span>Contacting server . . . <br /><br /></span>
                                        <Progress multi>
                                            <Progress bar animated value={10} />
                                        </Progress>
                                    </div>
                                )
                                : ((total_uploads < 0)
                                    ? (<span>Upload Failed! <br /><br /><br /></span>)
                                    : ((upload_count === total_uploads)
                                        ? (
                                            <div>
                                                <span>Upload Completed!<br /><br /></span>
                                                <Progress multi>
                                                    <Progress bar color="success" value={100} > 100% </Progress>
                                                </Progress>
                                            </div>
                                        )
                                        : (
                                            <div>
                                                <span>Uploading <i>{upload_filename}</i><br /><br /></span>
                                                <Progress multi>
                                                    <Progress bar animated value={20} />
                                                </Progress>
                                            </div>
                                        )))
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color='success' onClick={() => this.props.resetUploads()} disabled={(total_uploads === 0) || (upload_count !== total_uploads)}>Close</Button>
                    </ModalFooter>
                </Modal>
                {(setup && dynamic_options && configurations) &&
                    <Fragment>
                        <h1 id="product-digital-book">{this.product.toUpperCase()}</h1>
                        <Specification productSpecs={productSpecUrl("DigitalBooklet.pdf")} priceList="#" />
                        <Form className="product-info">
                            {setup.options &&
                                Object.entries(setup.options).map((entry) => {
                                    let configName = entry[0];
                                    let configSettings = entry[1];
                                    return this.generateProductOptionElement(configName, configSettings);
                                })
                            }
                            {
                                (configurations.quantity > 0) && (!invalid) && (price_and_duration.price > 0 && price_and_duration.price != NaN) && (
                                    <Fragment>
                                        <TimeAndCostFormGroup
                                            price={price_and_duration.price}
                                            processDay={price_and_duration.duration} />
                                        <Button id="add-to-cart" onClick={this.addToCart.bind(this)}>ADD TO CART</Button>
                                    </Fragment>
                                )
                            }
                        </Form>
                    </Fragment>
                }
            </Container>
        )
    }
}

const mapStateToProps = ({ product, cart }) => {
    return { product, cart };
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getProductSetup: getProductSetup,
        getProductTimeAndCost: getProductTimeAndCost,
        resetProductTimeAndCost: resetProductTimeAndCost,
        uploadFiles: uploadFiles,
        resetUploads: resetUploads,
        addToCart: addToCart
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withAlert(DigitalBooklet1Color));
