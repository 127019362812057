import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import './FoldingStyleFormGroup.css'
import { checkArray } from '../Utility'
/**
 * Formgroup for Round Corner configuration
 * 
 * @param {String} name - Name of the configuration. Used to identify which configuration has changed.
 * @param {String} label - Display for the drop down
 * @param {Function} onChange - callback to handle changes
 * @param {*} selectedItem - current selected option
 * @param {Array} itemsSource - list of round corner optinmsitems
 */
export default class FoldingStyleFormGroup extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if(Array.isArray(this.props.itemsSource) === false) {
            console.log(`${this.constructor.name} not rendering due to unfullfilled prerequisites`)
            return null
        }

        return (
            <FormGroup key={this.props.name} row>
                <Label htmlFor={this.props.name} sm={3}>{this.props.label}</Label>
                <Col sm={9}>
                    {
                        this.props.itemsSource.map(value => {
                            let optionId = `${this.props.name} ${value}`.toLowerCase().replace(/[^a-z0-9]+/g, '_');
                            return (
                                <FormGroup check inline key={value}>
                                    <Label check>
                                        <Input type="radio"
                                            name={this.props.name}
                                            id={optionId}
                                            value={value}
                                            checked={value === this.props.selectedItem}
                                            onChange={this.props.onChange} />
                                        {value}
                                        <div htmlFor={optionId} id={optionId} className={`folding_option ${optionId}`} />
                                    </Label>
                                </FormGroup>
                            );
                        })
                    }
                </Col>
            </FormGroup>
        )
    }
}