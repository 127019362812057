import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import {
    Container,
    Row,
    Collapse,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Dropdown,
    Badge
} from 'reactstrap';
import { showLoginModal, registerLoginSuccessHandle } from '../../../actions';
import './UtechNavbar.css';
import logo_mobile from './utech_logo_mobile.png';

class UtechNavbar extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            navOpen: false,
            cartItemCount: 0
        };
    }

    componentDidUpdate(prevProps) {
            if (this.props !== prevProps) {
                this.setState({ navOpen: false });
            }
        }

    toggle() {
        this.setState({
            navOpen: !this.state.navOpen
        });
    }

    redirectAfterLogin(link) {
        window.location.href = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + link;
    }

    showLogin(link) {
        if (link) this.props.registerLoginSuccessHandle(this.redirectAfterLogin.bind(this, link));
        this.props.showLoginModal(true);
    }

    handleLogout = (evt) => {
        evt.preventDefault();
        this.props.firebaseApp.auth().signOut();
        this.props.history.push('/');
    }

    render() {
        const { customer, cart } = this.props;

        return (
            <div className='navbar-bg'>
                <Container>
                    <Row>
                        <Navbar dark expand="md">
                            {/* <a href="/"> */}
                                <NavbarBrand style={{color: 'white', fontSize: '1.5em'}} onClick={() => this.props.history.push('/')}><img src={logo_mobile} title="U-Tech Enterprise" alt="U-Tech Enterprise" style={{ height: '50px', width: 'auto' }} /><span style={{fontSize: '0.7em', fontWeight: '600', verticalAlign: '-25%', color: 'red'}}>Shop Online. Print Online.</span></NavbarBrand>
                            {/* </a> */}
                            <NavbarToggler onClick={this.toggle} />
                            <Collapse isOpen={this.state.navOpen} navbar>
                                <Nav className="m-auto" navbar>
                                    <NavItem><Link to="/" className="nav-link">HOME</Link></NavItem>
                                    <NavItem><Link to="/about-us" className="nav-link">ABOUT US</Link></NavItem>
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret>PRODUCTS</DropdownToggle>
                                        <DropdownMenu>
                                        {
                                            (customer)
                                            ?
                                            <Fragment>
                                                <Link to="/products/1 Colour Digital Booklet">
                                                    <DropdownItem key="1 Colour Digital Booklet">
                                                        1 Colour Digital Booklet
                                                    </DropdownItem>
                                                </Link>
                                                <Link to="/products/4 Colour Digital Booklet">
                                                    <DropdownItem key="4 Colour Digital Booklet">
                                                        4 Colour Digital Booklet
                                                    </DropdownItem>
                                                </Link>
                                                <Link to="/products/Digital Business Card">
                                                    <DropdownItem key="Digital Business Card" >
                                                        Digital Business Card
                                                    </DropdownItem>
                                                </Link>
                                                <Link to="/products/Offset Business Card">
                                                    <DropdownItem key="Offset Business Card" >
                                                        Offset Business Card
                                                    </DropdownItem>
                                                </Link>
                                                <Link to="/products/Flyer">
                                                    <DropdownItem key="Flyer" >
                                                        Flyer
                                                    </DropdownItem>
                                                </Link>
                                                <Link to="/products/Brochure">
                                                    <DropdownItem key="Brochure">
                                                        Brochure
                                                    </DropdownItem>
                                                </Link>
                                                {/* <Link to="/products/Racing Horse Calendar With Header"> */}
                                                    <DropdownItem key="Racing Horse Calendar With Header"  className="out-of-stock">
                                                        Racing Horse Calendar With Header <Badge href="#" color="warning">Out Of Stock</Badge>
                                                    </DropdownItem>
                                                {/* </Link> */}
                                                {/* <Link to="/products/Racing Horse Calendar Without Header"> */}
                                                    <DropdownItem key="Racing Horse Calendar Without Header"  className="out-of-stock">
                                                        Racing Horse Calendar Without Header <Badge href="#" color="warning">Out Of Stock</Badge>
                                                    </DropdownItem>
                                                {/* </Link> */}
                                                <Link to="/products/Sticker Label">
                                                    <DropdownItem key="Sticker Label">
                                                        Sticker Label
                                                    </DropdownItem>
                                                </Link>
                                            </Fragment>
                                            :
                                            <Fragment>
                                            <button className="dropdown-item" onClick={this.showLogin.bind(this, '/products/1 Colour Digital Booklet')}>1 Colour Digital Booklet</button>
                                            <button className="dropdown-item" onClick={this.showLogin.bind(this, '/products/4 Colour Digital Booklet')}>4 Colour Digital Booklet</button>
                                            <button className="dropdown-item" onClick={this.showLogin.bind(this, '/products/Offset Business Card')}>Offset Business Card</button>
                                            <button className="dropdown-item" onClick={this.showLogin.bind(this, '/products/Flyer')}>Flyer</button>
                                            <button className="dropdown-item" onClick={this.showLogin.bind(this, '/products/Brochure')}>Brochure</button>
                                            <button className="dropdown-item" onClick={this.showLogin.bind(this, '/products/Digital Booklet')}>Digital Booklet</button>
                                            <button style={{color:'gray'}} className="dropdown-item" onClick={this.showLogin.bind(this, '/products/Racing Horse Calendar With Header')}>Racing Horse Calendar With Header <Badge href="#" color="warning">Out Of Stock</Badge></button>
                                            <button style={{color:'gray'}} className="dropdown-item" onClick={this.showLogin.bind(this, '/products/Racing Horse Calendar Without Header')}>Racing Horse Calendar Without Header <Badge href="#" color="warning">Out Of Stock</Badge></button>
                                            <button className="dropdown-item" onClick={this.showLogin.bind(this, '/products/Sticker Label')}>Sticker Label</button>
                                            </Fragment>
                                        }
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret>CUSTOM QUOTE</DropdownToggle>
                                        <DropdownMenu>
                                        {
                                            (customer)
                                            ?
                                            <Fragment>
                                                <Link to="/custom-quote-booklet"><DropdownItem>Custom Quote - Booklet</DropdownItem></Link>
                                                <Link to="/custom-quote-general"><DropdownItem>Custom Quote - General</DropdownItem></Link>
                                            </Fragment>
                                            :
                                            <Fragment>
                                                <button className="dropdown-item" onClick={this.showLogin.bind(this, '/custom-quote-booklet')}>Custom Quote - Booklet</button>
                                                <button className="dropdown-item" onClick={this.showLogin.bind(this, '/custom-quote-general')}>Custom Quote - General</button>
                                            </Fragment>
                                        }
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    <NavItem><Link to="/faqs" className="nav-link">FAQ</Link></NavItem>
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret>UTECH PROCESS</DropdownToggle>
                                        <DropdownMenu>
                                            <Link to="/how-we-do"><DropdownItem>How We Do</DropdownItem></Link>
                                            <Link to="/how-to-top-up-account"><DropdownItem>How To Top Up Account</DropdownItem></Link>
                                            <Link to="/how-to-order"><DropdownItem>How To Order</DropdownItem></Link>
                                            <Link to="/how-to-count-process-day"><DropdownItem>How To Count Process Day</DropdownItem></Link>
                                            <Link to="/how-to-join"><DropdownItem>How To Join</DropdownItem></Link>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret>PRODUCT GUIDE</DropdownToggle>
                                        <DropdownMenu>
                                            <Link to="/art-work-specification"><DropdownItem>Artwork Specification</DropdownItem></Link>
                                            <Link to="/product-size-template"><DropdownItem>Product Size Template</DropdownItem></Link>
                                            <Link to="/product-specifications"><DropdownItem>Product Specification</DropdownItem></Link>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    <NavItem><Link to="/contact-form" className="nav-link">CONTACT US</Link></NavItem>
                                    <NavItem>
                                        {
                                            (customer)
                                            ? <Link to="/my-account" className="nav-link">MY ACCOUNT</Link>
                                            : <span className="nav-link" onClick={this.showLogin.bind(this, '/')}>LOGIN</span>
                                        }
                                    </NavItem>
                                    {(customer !== null) &&<NavItem className='navbar-cart'> <Link to="#" className="nav-link" onClick={this.handleLogout.bind(this)}>LOGOUT</Link></NavItem> }
                                    <NavItem className='navbar-cart'><Link to="/cart" className="nav-link">CART - {cart.items.length} ITEM(S)</Link></NavItem>
                                </Nav>
                            </Collapse>
                        </Navbar>
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = ({ main, product, cart }) => {
    const { firebaseApp} = main;
    return { customer: main.customer, products: product.products, cart ,firebaseApp};
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        showLoginModal: showLoginModal,
        registerLoginSuccessHandle: registerLoginSuccessHandle
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(UtechNavbar);
