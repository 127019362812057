import { FETCH_SHIPPING_INFO, RESET_SHIPPING, SAVE_ORDER, VERIFY_VOUCHER, RESET_VOUCHER } from '../actions/types';

const INIT_STATE = {
    billing_country: 'MALAYSIA',
    shipping_country: 'MALAYSIA',
    shipping_rates: [],
    voucher_discount: null,
    order: null,
}

export default (state = INIT_STATE, action) => {

    switch (action.type) {
        case RESET_SHIPPING:
            return INIT_STATE;

        case SAVE_ORDER:
            return { ...state, order: action.payload }

        case FETCH_SHIPPING_INFO:
            return { ...state, shipping_rates: action.payload }

        case VERIFY_VOUCHER:
            console.log(action.payload);
            return { ...state, voucher_discount: action.payload }

        case RESET_VOUCHER:
            console.log(action.payload);
            return { ...state, voucher_discount: null }

        default:
            return state;
    }
}