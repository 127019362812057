import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import { checkArray } from '../Utility'
/**
 * FormGroup for Size configuration
 *
 * @param {String} name - Name of the configuration. Used to identify which configuration has changed.
 * @param {String} label - Display for the drop down
 * @param {*} selectedItem - current selected value
 * @param {Array} itemsSource - list of items
 * @param {Function} onChange - callback to handle changes
 * @param {Boolean} showType - Show paper type, default is false if not specified.
 */
export default class SizeFormGroup extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (Array.isArray(this.props.itemsSource) === false) {
            console.log(`${this.constructor.name} not rendering due to unfullfilled prerequisites`)
            return null
        }

        return (
            <FormGroup row>
                <Label htmlFor={this.props.name} sm={3}>{this.props.label}</Label>
                <Col sm={9}>
                    <Input type="select" name={this.props.name} id={this.props.name} value={JSON.stringify(this.props.selectedItem)} onChange={this.props.onChange}>
                        {
                            this.props.itemsSource.map(value => {
                                // let itemDisplayString = `${value.height} mm X ${value.width} mm`; // utech existing implementation: Business card is height x width, but flyer is width x height
                                let itemDisplayString = `${value.width} mm x ${value.height} mm`;
                                if(this.props.showType === true) {
                                    // itemDisplayString = `${value.type} (${itemDisplayString})`;
                                    itemDisplayString = `${value.type}`;
                                }

                                return (
                                    <option key={value.type} value={JSON.stringify(value)}>{itemDisplayString}</option>
                                )
                            })
                        }
                    </Input>
                </Col>
            </FormGroup>
        )
    }
}