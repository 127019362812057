import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
} from 'reactstrap';

import './HowToJoinPage.css';

import joinImage from './icon-join.png';
import buttonSignUp from './button-sign-up.png';

class HowToJoinPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container className="my-3">
        <Row>
          <Col>
            <h1 className="my-2">HOW TO JOIN</h1>

            <div className="process-link text-center">
              <Link to="/how-we-do">How We Do</Link>
              <Link to="/how-to-top-up-account">How To Top Up Account</Link>
              <Link to="/how-to-order">How To Order</Link>
              <Link to="/how-to-count-process-day">How To Count Process Day</Link>
              <Link to="/how-to-join" class="selected">How To Join</Link>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="5" className="text-right">
            <img className="img-fluid my-3" src={joinImage} title="How To Join" alt="howToJoin" style={{maxHeight: '250px'}}/>
          </Col>
          <Col xs="12" md="7">
            <h2>1. Register</h2>
            <p>
              Fill up the registration form. <br />
              Lifetime membership.
            </p>
            <h2>2. Top-Up</h2>
            <p>
              No registration fee is required. <br />
              Just top-up to make your order.
            </p>
            <h2>3. Verification</h2>
            <p>
              Kindly email the bank in slip to us. <br />
              Our support team will reload the amount in your account after verifying the slip. <br /><br />

              {/* <strong>Fax :</strong> +604-502 3256 <br /> */}
              <strong>Email :</strong> online@utech.com.my
            </p>

            <p>
              <Link to="/signup"><img className="img-fluid" src={buttonSignUp} alt="button-sign-up"/></Link>
            </p>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default HowToJoinPage;
