import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { urlToProperty } from "query-string-params";
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { getProductOptions, getProductVariants, getProductTimeAndCost, resetProductTimeAndCost, uploadFiles, resetUploads } from '../../../actions'
import './ProductPage.css';
import SizeFormGroup from './Components/SizeFormGroup';
import GenericDropdownsFormGroup from './Components/GenericDropdownsFormGroup';
import TimeAndCostFormGroup from './Components/TimeAndCostFormGroup';
import SelectFileFormGroup from './Components/SelectFileFormGroup';
import JsonDisplay from './Components/JsonDisplay';
import FoldingStyleFormGroup from './Components/FoldingStyleFormGroup';
import { isObjValueEqual, matchCurrentConfigToVariant, readFileToBase64StringPromise, productSpecUrl, productPriceUrl } from "./Utility";
import Specification from './Components/Specifications';
import ManualInputFormGroup from './Components/ManualInputFormGroup';

class StoreCredit extends Component {
    constructor(props) {
        super(props);
        this.product = 'Store Credit';
        this.state = {
            configurations: {
                credit: 0
            },
        };
    }

    /**
     * Invoked immediately after a component is mounted (inserted into the tree).
     * Initialization that requires DOM nodes should go here.
     * If you need to load data from a remote endpoint, this is a good place to instantiate the network request.
     */
    componentDidMount() {
        document.title = `U-Tech - ${this.product}`
    }

    /**
     * Handle the input change event.
     * Configuration & options change resulted by the event will be performed here.
     *
     * @param {*} e eventData
     */
    handleInputChange(e) {
        let futureConfigs = { ...this.state.configurations };

        if (e.target.type === 'checkbox') {
            // Use checked value instead of normal value
            futureConfigs[e.target.name] = e.target.checked;
        } else {
            // everything else just let it slide
            futureConfigs[e.target.name] = e.target.value;
        }

        this.setState({
            configurations: futureConfigs
        });
    }

    addToCart() {
        console.log(this.state);
    }

    render() {
        const { options } = this.props.product;
        const { upload_count, upload_filename, total_uploads } = this.props.order;
        const { configurations, time_and_cost } = this.state;

        return (
            <Container>
                {/* <JsonDisplay object={this.state} style={{ position: 'absolute', background: 'white', zIndex: '2', right: '-300px', fontSize: '0.75em', border: '1px blue solid' }} /> */}
                <h1 id="product-digital-book">{this.product.toUpperCase()}</h1>
                <h3>1 CREDIT = RM 1</h3>
                <Form className="product-info">
                    <ManualInputFormGroup name="credit" label="Credit"  inputAttributes={{type: 'number'}} value={this.state.configurations.credit} onChange={this.handleInputChange.bind(this)} />
                    <Button onClick={this.addToCart.bind(this)}>ADD TO CART</Button>
                </Form>
            </Container>
        )
    }
}

const mapStateToProps = ({ product, order }) => {
    return { product, order };
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getProductOptions: getProductOptions,
        getProductVariants: getProductVariants,
        getProductTimeAndCost: getProductTimeAndCost,
        resetProductTimeAndCost: resetProductTimeAndCost,
        uploadFiles: uploadFiles,
        resetUploads: resetUploads
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(StoreCredit);
