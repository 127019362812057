import React, { Component } from 'react';

import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';

import './CustomQuoteEnquiryPage.css';

class CustomQuoteEnquiryPage extends Component {
  render() {
    return (
      <Container className="my-3">
        <Row>
          <Col>
            <div>
              <h1 className="my-2">ENQUIRY FORM</h1>
              <Form>
                <FormGroup row>
                  <Label htmlFor="other-cover-material" sm={4}><span className="mandatory">*</span>Name</Label>
                  <Col sm={5}>
                    <Input id="other-cover-material" type="text" name="other-cover-material" />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="other-cover-material" sm={4}><span className="mandatory">*</span>Company Name</Label>
                  <Col sm={5}>
                    <Input id="other-cover-material" type="text" name="other-cover-material" />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="other-cover-material" sm={4}><span className="mandatory">*</span>Address</Label>
                  <Col sm={5}>
                    <Input id="other-cover-material" type="text" name="other-cover-material" />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="other-cover-material" sm={4}>Company Registration No</Label>
                  <Col sm={5}>
                    <Input id="other-cover-material" type="text" name="other-cover-material" />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="other-cover-material" sm={4}>Company GST ID No.</Label>
                  <Col sm={5}>
                    <Input id="other-cover-material" type="text" name="other-cover-material" />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="other-cover-material" sm={4}>Business Nature</Label>
                  <Col sm={5}>
                    <Input id="other-cover-material" type="text" name="other-cover-material" />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="other-cover-material" sm={4}><span className="mandatory">*</span>Office Tel</Label>
                  <Col sm={5}>
                    <Input id="other-cover-material" type="text" name="other-cover-material" />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="other-cover-material" sm={4}>Office Fax</Label>
                  <Col sm={5}>
                    <Input id="other-cover-material" type="text" name="other-cover-material" />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="other-cover-material" sm={4}><span className="mandatory">*</span>Mobile</Label>
                  <Col sm={5}>
                    <Input id="other-cover-material" type="number" name="other-cover-material" />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="other-cover-material" sm={4}><span className="mandatory">*</span>Email</Label>
                  <Col sm={5}>
                    <Input id="other-cover-material" type="email" name="other-cover-material" />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="other-cover-material" sm={4}>How do you get to know about us</Label>
                  <Col sm={5}>
                  <Input type="select" name="selectMulti" id="exampleSelectMulti" defaultValue="">
                    <option value=""> --- Please Select --- </option>
                    <option value="60">Internet</option>
                    <option value="61">Advertisement</option>
                    <option value="62">Agent</option>
                    <option value="63">Email</option>
                    <option value="64">Friends Recommendation</option>
                    <option value="65">Our Marketing Team</option>
                    <option value="66">Others</option>
                  </Input>
                  </Col>
                </FormGroup>

                <FormGroup tag="fieldset" row>
                  <Label htmlFor="message" sm={4}><span className="mandatory">*</span>Message</Label>
                  <Col sm={10}>
                    <Input type="textarea" name="message" id="message" /> <br/>
                    Thank you for your enquiry. We will get back to you within 2 working days.
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Col sm={12}>
                    <Button>Submit</Button>
                  </Col>
                </FormGroup>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default CustomQuoteEnquiryPage;
