import { combineReducers } from 'redux';
import MainReducer from './MainReducer';
import ProductReducer from './ProductReducer';
import CartReducer from './CartReducer';
import CheckoutReducer from './CheckoutReducer';

export default combineReducers({
    main: MainReducer,
    product: ProductReducer,
    cart: CartReducer,
    checkout: CheckoutReducer
});