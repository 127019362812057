import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAlert } from 'react-alert';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Progress, Dropdown, Alert, Tooltip } from 'reactstrap';
import { getProductSetup, getProductTimeAndCost, resetProductTimeAndCost, uploadFiles, resetUploads, addToCart } from '../../../actions'
import './ProductPage.css';
import SelectFileFormGroup from './Components/SelectFileFormGroup';
import ManualInputFormGroup from './Components/ManualInputFormGroup';
import GenericDropdownsFormGroup from './Components/GenericDropdownsFormGroup';
import TimeAndCostFormGroup from './Components/TimeAndCostFormGroup';
import { productSpecUrl, productPriceUrl } from "./Utility";
import Specification from './Components/Specifications';
import LoadingIndicator from './Components/LoadingIndicator';

// Dimension constants defined in MM
const min_sticker_width = 25;
const max_sticker_width = 297;
const min_sticker_height = 25;
const max_sticker_height = 420;
const paper_width = 300;
const paper_height = 460;

class Sticker extends Component {
    constructor(props) {
        super(props);
        this.product = 'Sticker Label';
        this.state = {
            /**
             * Dynamic options for certain configuration
             */
            dynamic_options: {
            },
            /**
             * Current configuration selected by end-user
             */
            configurations: {
                width: 0,
                height: 0,
                quantity: 0,
                stickers_per_sheet: 0,
                orientation: '',
                print: null,
                material: null,
                plotting: null,
                cover_artwork: null,
                cover_artwork_path: null,
                cover_artwork_download_url: null,
                weight: NaN
            },
            /**
             * Time and cost looked up based on configurations
             */
            price_and_duration: {
                price: 0.0,
                duration: 0
            },
            isLoadingData: true,
            invalidWidth: false,
            invalidHeight: false
        };

        this.props.getProductSetup(this.product);
    }

    /**
     * Invoked immediately after a component is mounted (inserted into the tree).
     * Initialization that requires DOM nodes should go here.
     * If you need to load data from a remote endpoint, this is a good place to instantiate the network request.
     */
    componentDidMount() {
        document.title = `U-Tech - ${this.product}`
        // this.props.getProductSetup(this.product);
    }

    /**
     * Invoked immediately after updating occurs.
     * This method is not called for the initial render.
     * Use this as an opportunity to operate on the DOM when the component has been updated.
     * This is also a good place to do network requests as long as you compare the current props to previous props (e.g. a network request may not be necessary if the props have not changed).
     *
     * @param {*} previousProps Previous properties before this completed rendering cycle
     * @param {*} previousState Previous state before this already completed rendering cycle
     */
    componentDidUpdate(previousProps, previousState) {
        if ((previousProps.product.setup !== this.props.product.setup) && this.props.product.setup.options) {
            let newConfigurations = this.getDefaultConfigurations(this.props.product.setup.options);
            let newDynamicOptions = this.getDynamicOptions(newConfigurations, this.props.product.setup.options);
            newConfigurations = this.getDefaultConfigurations({ ...this.props.product.setup.options, ...newDynamicOptions });
            console.log(newConfigurations);

            this.setState({
                dynamic_options: { ...this.props.product.setup.options, ...newDynamicOptions },
                configurations: newConfigurations,
                isLoadingData: false
            });
        }

        if (previousState.configurations !== this.state.configurations) {
            let newConfig = this.state.configurations;
            let newPriceAndDuration = this.state.price_and_duration;
            let totalWeight = NaN;

            // Make sure configuration is valid
            if ((newConfig.width.length > 0) && (newConfig.height.length > 0)) {
                // Get the correct options based on the latest configurations
                let newDynamicOptions = this.getDynamicOptions(newConfig, this.props.product.setup.options);
                let sticker_width = parseFloat(newConfig.width);
                let sticker_height = parseFloat(newConfig.height);

                // TODO: Price calculation
                if ((this.props.product.setup)
                    && (sticker_width >= min_sticker_width) && (sticker_width <= max_sticker_width)
                    && (sticker_height >= min_sticker_height) && (sticker_height <= max_sticker_height)
                    && (newConfig.quantity > 0)) {
                    let pricing = this.props.product.setup.variants[0];
                    console.log(pricing);
                    console.log(this.state.configurations);

                    // Calculate number of sheets to be used

                    // Calculating total number of stickers per sheet in portrait orientation
                    let stickers_per_row = Math.floor((paper_width + 2) / (parseFloat(newConfig.width) + 2));
                    let num_of_rows = Math.floor((paper_height + 2) / (parseFloat(newConfig.height) + 2));

                    let num_stickers_portrait = stickers_per_row * num_of_rows;
                    console.log('Num stickers in portrait = ' + num_stickers_portrait);

                    // Calculating total number of stickers per sheet in landscape orientation
                    stickers_per_row = Math.floor((paper_width + 2) / (parseFloat(newConfig.height) + 2));
                    num_of_rows = Math.floor((paper_height + 2) / (parseFloat(newConfig.width) + 2));

                    let num_stickers_landscape = stickers_per_row * num_of_rows;
                    console.log('Num stickers in landscape = ' + num_stickers_landscape);

                    if (num_stickers_landscape > num_stickers_portrait) {
                        newConfig.stickers_per_sheet = num_stickers_landscape;
                        newConfig.orientation = 'Landscape';
                    } else {
                        newConfig.stickers_per_sheet = num_stickers_portrait;
                        newConfig.orientation = 'Portrait';
                    }

                    let num_sheets = Math.ceil(newConfig.quantity / newConfig.stickers_per_sheet);
                    console.log('Num sheets = ' + num_sheets);

                    // Calculate material cost
                    let material_cost = NaN;

                    let material = pricing.material.prices.find((item) => item.paper_type === newConfig.material);
                    console.log(material);
                    if (material) {
                        num_sheets += 5;    // Adding 5pcs wastage to the number of sheets
                        material_cost = parseFloat(Math.max(num_sheets * material.unit_price, material.min_price).toFixed(2));
                    }
                    console.log('Material cost = ' + material_cost);

                    // Calculate printing cost
                    let printing_cost = NaN;
                    if (pricing.printing) {
                        printing_cost = parseFloat(Math.max(num_sheets * pricing.printing.unit_price, pricing.printing.min_price).toFixed(2));
                    }
                    console.log('Printing cost = ' + printing_cost);

                    let plotting_cost = NaN;
                    if (this.state.configurations.plotting == "Yes") {
                        // Calculate plotting cost

                        let unit_plotting_price = NaN;
                        pricing.plotting.prices.forEach((item) => {
                            if (num_sheets >= item.quantity_from) unit_plotting_price = item.unit_price;
                        });
                        plotting_cost = parseFloat((num_sheets * unit_plotting_price).toFixed(2));
                        console.log('Plotting cost = ' + plotting_cost);
                    }
                    else {
                        plotting_cost = 0.00;
                        console.log('Plotting cost = ' + plotting_cost);
                    }

                    let total_price = parseFloat((material_cost + printing_cost + plotting_cost).toFixed(2));
                    console.log('Total = ' + total_price);

                    newPriceAndDuration.price = total_price;
                    newPriceAndDuration.duration = pricing.misc.duration;

                    // Calculate weight for shipping fee
                    totalWeight = 50 * num_sheets;      // weight 50g per pcs
                    newConfig.weight = totalWeight / 1000;
                    console.log('Total weight (g) = ' + totalWeight);
                }

                this.setState({
                    dynamic_options: newDynamicOptions,
                    price_and_duration: newPriceAndDuration,
                    configurations: newConfig
                });
            }
        }
        console.log(this.props)

        if (previousProps.cart.upload_count !== this.props.cart.upload_count) {
            if ((this.props.cart.total_uploads > 0) && (this.props.cart.total_uploads === this.props.cart.upload_count)) {
                let newConfig = this.state.configurations;

                newConfig.cover_artwork = this.props.cart.cover_artwork;
                newConfig.cover_artwork_path = this.props.cart.cover_artwork_path;
                let newDynamicOptions = this.getDynamicOptions(newConfig, this.props.product.setup.options);
                this.setState({
                    dynamic_options: newDynamicOptions,
                    configurations: newConfig
                });
            }
        }
    }

    /**
     * Get latest dynamic options based on the provided configurations
     *
     * @param {Object} productConfigurations Configurations
     * @param {Object} productOptions Product options. If not supplied, component's props.product.setup.options will be used.
     * @param {Object} productVariants Product variants. If not supplied, component's props.product.setup.variants will be used.
     *
     * @returns {Object} New dynamic options. Return existing state's dynamic options if no static product data is available.
     */
    getDynamicOptions(productConfigurations, productOptions = this.props.product.setup.options, productVariants = this.props.product.setup.variants) {
        // stop if we do not have the data
        if (productOptions == undefined) {
            console.log("Skip update dynamic options due to no data")
            return this.state.dynamic_options;
        }

        let newDynamicOptions = { ...this.state.dynamic_options };

        // Populate material list
        let selectedVariant = productVariants.find((variant) => {
            return ((variant.name === productConfigurations.name));
        });

        console.log(selectedVariant);

        let dynMaterialOptions = (selectedVariant) ? selectedVariant.material.prices.map((item) => item.paper_type) : [];

        newDynamicOptions.material = productOptions.material;
        newDynamicOptions.material.values = dynMaterialOptions;

        return newDynamicOptions;
    }

    /**
     * Handle the input change event.
     * Configuration & options change resulted by the event will be performed here.
     *
     * @param {*} e eventData
     */
    handleInputChange(e) {
        let futureConfigs = { ...this.state.configurations };

        // everything just let it slide
        futureConfigs[e.target.name] = e.target.value;

        if (e.target.name === 'width') {
            if (e.target.value < min_sticker_width) {
                this.setState({ invalidWidth: true, errorText: 'Minimum is 25mm' });
            }
            else if (e.target.value > max_sticker_width) {
                this.setState({ invalidWidth: true, errorText: 'Maximum is 297mm' });
            } else {
                this.setState({ invalidWidth: false });
            }
        }

        if (e.target.name === 'height') {
            if (e.target.value < min_sticker_height) {
                this.setState({ invalidHeight: true, errorText: 'Minimum is 25mm' });
            }
            else if (e.target.value > max_sticker_height) {
                this.setState({ invalidHeight: true, errorText: 'Maximum is 420mm' });
            } else {
                this.setState({ invalidHeight: false });
            }
        }

        let futureDynOptions = this.getDynamicOptions(futureConfigs, this.props.product.setup.options);

        this.setState({
            dynamic_options: futureDynOptions,
            configurations: futureConfigs
        });
    }

    getDefaultConfigurations(productOptions) {
        let newConfigurations = { ...this.state.configurations }
        const stateConfigKeys = Object.keys(this.state.configurations);
        const optionKeys = Object.keys(productOptions);

        stateConfigKeys.forEach(name => {
            if (optionKeys.includes(name)) {
                let defaultValue = null;

                if (['width'].includes(name)) {
                    defaultValue = '';
                }
                else if (['height'].includes(name)) {
                    defaultValue = '';
                }
                else if (['quantity'].includes(name)) {
                    defaultValue = 0;
                }
                else if (['cover_artwork', 'cover_artwork_path', 'cover_artwork_download_url'].includes(name)) {
                    // Use null as default
                    defaultValue = null;
                }
                else {
                    // Use first value as default
                    defaultValue = productOptions[name].values[0];
                }

                newConfigurations[name] = defaultValue;
            }
        });

        return newConfigurations
    }
    /**
     * Generate DOM elements based on product options.
     *
     * @param {string} configName Name of the configuration
     * @param {object} configSettings Configuration data
     */
    generateProductOptionElement(configName, configSettings) {
        const { options } = this.props.product.setup;
        const { configurations, dynamic_options, invalidWidth, invalidHeight, errorText } = this.state;

        switch (configName) {

            case 'width':
                return (
                    <ManualInputFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label + '(mm)'}
                        value={configurations[configName]}
                        inputAttributes={{ type: 'number', min: 1, placeholder: 'Min Width: 25mm / Max Width 297 mm' }}
                        onChange={this.handleInputChange.bind(this)}
                        invalid={invalidWidth}
                        errorText={errorText}
                    />
                )
            case 'height':
                return (
                    <ManualInputFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label + '(mm)'}
                        value={configurations[configName]}
                        inputAttributes={{ type: 'number', min: 1, placeholder: 'Min Height: 25mm / Max Height 420 mm' }}
                        onChange={this.handleInputChange.bind(this)}
                        invalid={invalidHeight}
                        errorText={errorText}
                    />
                )
            case 'material':
                // DYNAMIC dropdown options
                return (
                    <GenericDropdownsFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        onChange={this.handleInputChange.bind(this)}
                        itemsSource={dynamic_options[configName].values}
                        selectedItem={configurations[configName]}
                    />
                )

            case 'print':
            case 'plotting':
                // STATIC dropdown options
                return (
                    <GenericDropdownsFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        onChange={this.handleInputChange.bind(this)}
                        itemsSource={options[configName].values}
                        selectedItem={configurations[configName]}
                    />
                )

            case 'quantity':
                // Manual Input
                return (
                    <ManualInputFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        value={configurations[configName]}
                        inputAttributes={{ type: 'number', min: 1, placeholder: 'Quantity' }}
                        onChange={this.handleInputChange.bind(this)}
                    />
                )

                case 'cover_artwork':
                    return (
                        <SelectFileFormGroup
                            key={configName}
                            name={configName}
                            label={configSettings.label}
                            accept=".pdf, .rar, .zip"
                            onChange={this.handleFileSelect.bind(this, 'sticker_cover')}
                        />
                    )

                case 'cover_artwork_download_url':
                    return (
                        <ManualInputFormGroup
                            key={configName}
                            name={configName}
                            label={configSettings.label}
                            value={configurations[configName]}
                            inputAttributes={{ placeholder: 'ARTWORK DOWNLOAD URL', style: { textTransform: 'none' } }}
                            onChange={this.handleInputChange.bind(this)}
                        />
                    )
            default:
                break;
        }
    }


    /**
     * Check if the required configurations is supplied.
     *
     * @returns True if all required configurations is supplied. False otherwise.
     */
    checkRequiredConfigurations() {
        let missingConfigurations = [];

        // Add checks for required configuration here
        if (!this.state.configurations['width']) {
            missingConfigurations.push("Width");
        }

        if (!this.state.configurations['height']) {
            missingConfigurations.push("Height");
        }

        if (this.state.configurations['cover_artwork_path'] == null && (
            this.state.configurations['cover_artwork_download_url'] == null ||
            this.state.configurations['cover_artwork_download_url'] == ""
        )) {
            missingConfigurations.push("Artwork");
        }

        console.log(missingConfigurations);
        if (missingConfigurations.length > 0) {
            window.alert(`Please supply the missing information: ${missingConfigurations.join(", ")}.`);
            return false;
        }

        // if ((this.state.price_and_duration.price <= 0) || (this.state.price_and_duration.duration <= 0)) {
        //     window.alert(`Invalid product configurations.`);
        //     return false;
        // }

        // everything OK
        return true;
    }

    addToCart() {
        if (this.checkRequiredConfigurations() === false) {
            return;
        }

        let config = { ...this.state.configurations }
        Object.entries(config).forEach(pair => {
            if (pair[1] == null) config[pair[0]] = undefined;
            if (typeof pair[1] === 'boolean') {
                config[pair[0]] = (pair[1]) ? 'Yes' : 'No';
            }
        });

        this.props.addToCart({
            product: this.product,
            configurations: config,
            price: this.state.price_and_duration.price,
            duration: this.state.price_and_duration.duration
        });

        const alert = this.props.alert.show('Item added to cart', {
            timeout: 3000,
            type: 'success',
        });

        this.props.history.push('/cart');
    }

    handleFileSelect(type, e) {
        e.stopPropagation();
        e.preventDefault();
        let files = e.target.files;

        console.log(files);
        if (files.length > 0) {
            this.props.uploadFiles(files, type);
        }
    }

    render() {
        const { setup } = this.props.product;
        const { upload_started, upload_count, upload_filename, total_uploads } = this.props.cart;
        const { configurations, dynamic_options, price_and_duration, isLoadingData, invalidWidth, invalidHeight } = this.state;

        if (isLoadingData === true) {
            return (
                <Container>
                    <LoadingIndicator />
                </Container>
            )
        }

        return (
            <Container>
                <Modal isOpen={upload_started} className='scrolling'>
                    <ModalHeader>File Upload</ModalHeader>
                    <ModalBody>
                    {
                            (total_uploads === 0)
                                ? (
                                    <div>
                                        <span>Contacting server . . . <br/><br/></span>
                                        <Progress multi>
                                            <Progress bar animated value={10} />
                                        </Progress>
                                    </div>
                                )
                                : ((total_uploads < 0)
                                    ? (<span>Upload Failed! <br/><br/><br/></span>)
                                    : ((upload_count === total_uploads)
                                        ? (
                                            <div>
                                                <span>Upload Completed!<br/><br/></span>
                                                <Progress multi>
                                                    <Progress bar color="success" value={100} > 100% </Progress>
                                                </Progress>
                                            </div>
                                        )
                                        : (
                                            <div>
                                                <span>Uploading <i>{upload_filename}</i><br/><br/></span>
                                                <Progress multi>
                                                    <Progress bar animated value={20} />
                                                </Progress>
                                            </div>
                                        )))
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color='success' onClick={() => this.props.resetUploads()} disabled={(total_uploads === 0) || (upload_count !== total_uploads)}>Close</Button>
                    </ModalFooter>
                </Modal>
                {(setup && dynamic_options && configurations) &&
                    <Fragment>
                        <h1 id="product-digital-book" style={{ display: "flex", alignDirection: "column", fontSize: "2.5em" }}>Sticker Label</h1>
                        <Form className="product-info">
                            {setup.options &&
                                Object.entries(setup.options).map((entry) => {
                                    let configName = entry[0];
                                    let configSettings = entry[1];
                                    return this.generateProductOptionElement(configName, configSettings);
                                })
                            }
                            {
                                (configurations.quantity > 0) && (!invalidWidth) && (!invalidHeight) && (
                                    <Fragment>
                                        <TimeAndCostFormGroup
                                            price={price_and_duration.price}
                                            processDay={price_and_duration.duration} />

                                        <Button onClick={this.addToCart.bind(this)}>ADD TO CART</Button>
                                    </Fragment>
                                )
                            }
                        </Form>
                    </Fragment>
                }
            </Container>
        )
    }
}

const mapStateToProps = ({ product, cart }) => {
    return { product, cart };
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getProductSetup: getProductSetup,
        getProductTimeAndCost: getProductTimeAndCost,
        resetProductTimeAndCost: resetProductTimeAndCost,
        uploadFiles: uploadFiles,
        resetUploads: resetUploads,
        addToCart: addToCart
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withAlert(Sticker));
