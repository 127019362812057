import * as types from './types';
import { serverURI, firebaseConfig } from '../config';
import * as firebase from 'firebase';
import moment from 'moment';

const oss = require('ali-oss');
const co = require('co');
const request = require('superagent');

export const registerFirebase = () => {
    return async (dispatch) => {
        if (!firebase.apps.length) {
            console.log('Registering with firebase');
            try {
                let firebaseApp = firebase.initializeApp(firebaseConfig);
                if (firebaseApp) {
                    console.log('Successful: ' + firebaseApp.name);
                    if (firebase.auth().currentUser) {
                        console.log(firebase.auth().currentUser);
                    }

                    firebase.auth().onAuthStateChanged((user) => {
                        console.log(user);
                        if (user) {
                            if ((user.providerData[0].providerId === 'password') && (!user.emailVerified)) {
                                console.log('Email not verified');

                                dispatch({
                                    type: types.USER_INIT,
                                    payload: { status: 'failed', message: 'Please check your email for activation link.' }
                                });
                                firebase.auth().signOut();
                            } else {
                                console.log('User logged in.\n' + JSON.stringify(user));
                                request.post(serverURI + '/v1/retrieve_customer')
                                    .set('Content-Type', 'application/json')
                                    .send({ uid: user.uid })
                                    .then((resp) => {
                                        console.log(resp);
                                        let customer = resp.body;

                                        if (resp.status === 200) {
                                            dispatch({
                                                type: types.USER_INIT,
                                                payload: { status: 'success', customer: customer }
                                            });
                                            // }
                                        } else {
                                            console.log('User not found in database');
                                            dispatch({
                                                type: types.USER_INIT,
                                                payload: { status: 'failed', message: 'User not found!' }
                                            });
                                        }
                                    })
                                    .catch((err) => {
                                        if (err.response && (err.response.status === 404)) {
                                            console.log(user.providerData);
                                            console.log('User not registered with UTech');
                                        }
                                        dispatch({
                                            type: types.USER_INIT,
                                            payload: { status: 'failed', message: 'User not found!' }
                                        });
                                        console.log('User not found in database');
                                    });

                            }
                        } else {
                            console.log('User has logged out!');
                            dispatch({
                                type: types.USER_INIT,
                                payload: { status: 'success', customer: null }
                            });
                        }
                    });
                    dispatch({
                        type: types.FIREBASE_INIT,
                        payload: firebaseApp
                    });
                } else {
                    dispatch({
                        type: types.FIREBASE_INIT,
                        payload: null
                    });
                }
            } catch (err) {
                console.log(err);
                dispatch({
                    type: types.FIREBASE_INIT,
                    payload: null
                });
            }
        }
    }
}

export const registerCustomer = (customer) => {
    return async (dispatch) => {
        try {
            let resp = await request.post(serverURI + '/v1/register_customer')
                .set('Content-Type', 'application/json')
                .send(customer);

            if (resp.status === 200) {
                if (customer.provider === 'password') {
                    dispatch({
                        type: types.USER_INIT,
                        payload: { status: 'success' }
                    });
                } else {
                    dispatch({
                        type: types.USER_INIT,
                        payload: { status: 'success', customer: customer }
                    });
                }
            } else {
                dispatch({
                    type: types.USER_INIT,
                    payload: { status: 'failed', message: 'Register customer failed' }
                });
            }
        } catch (err) {
            console.log(err);
            dispatch({
                type: types.USER_INIT,
                payload: { status: 'failed', message: 'Register customer failed' }
            })
        }
    }
}

export const fetchUserRecords = (uid) => {
    console.log('fetchUserRecords: ' + uid);

    return async (dispatch) => {
        try {
            let resp = await request.post(serverURI + '/v1/fetch_user_records')
                .set('Content-Type', 'application/json')
                .send({ uid: uid });
            console.log(JSON.stringify(resp.body));
            dispatch({
                type: types.FETCH_USER_RECORDS,
                payload: resp.body
            });
        } catch (err) {
            console.log(err);
            dispatch({
                type: types.FETCH_USER_RECORDS,
                payload: {}
            });
        }
    }
}

export const updateCustomerProfile = (user) => {
    console.log('Updating customer profile');
    console.log(serverURI);
    return async (dispatch) => {
        try {
            let resp = await request.post(serverURI + '/v1/update_customer_profile')
                .set('Content-Type', 'application/json')
                .send(user);

            console.log(resp);
            if (resp.status === 200) {
                console.log(resp.body);
                dispatch({
                    type: types.USER_INIT,
                    payload: resp.body
                });
            } else {
                dispatch({
                    type: types.USER_INIT,
                    payload: null
                });
            }
        } catch (err) {
            console.log(err);
            dispatch({
                type: types.USER_INIT,
                payload: null
            })
        }
    }
}

export const registerLoginSuccessHandle = (handle) => {
    return ({
        type: types.LOGIN_SUCCESS_CB,
        payload: handle
    });
}

export const showLoginModal = (open) => {
    return {
        type: types.LOGIN_MODAL,
        payload: open
    }
}

export const showLoading = (enable) => {
    return ({
        type: types.SHOW_LOADING,
        payload: enable
    });
}

export const fetchShippingInfo = (countries) => {
    return async (dispatch) => {
        try {
            let resp = await request.post(serverURI + '/v1/fetch_shipping_info')
                .set('Content-Type', 'application/json')
                .send({ _id: { $in: countries } });

            if (resp.status === 200) {
                dispatch({
                    type: types.FETCH_SHIPPING_INFO,
                    payload: resp.body
                });
            } else {
                console.log('Failed to fetch shipping info');
                dispatch({
                    type: types.FETCH_SHIPPING_INFO,
                    payload: resp.body
                });
            }
        } catch (err) {
            console.log(err);
            dispatch({
                type: types.FETCH_SHIPPING_INFO,
                payload: {}
            });
        }
    }
}

export const calculateShipping = (params) => {
    return async (dispatch) => {
        console.log(params);
        if (params.delivery_option.toUpperCase() === 'SELF COLLECT') {
            console.log('SELF COLLECT');
            dispatch({
                type: types.CALCULATE_SHIPPING,
                payload: {
                    delivery_option: params.delivery_option,
                    shipping_fee: 0.0
                }
            });
        } else {
            try {
                let resp = await request.post(serverURI + '/v1/calculate_shipping')
                    .set('Content-Type', 'application/json')
                    .send(params);

                if (resp.status === 200) {
                    dispatch({
                        type: types.CALCULATE_SHIPPING,
                        payload: {
                            delivery_option: params.delivery_option,
                            ...resp.body
                        }
                    });
                } else {
                    dispatch({
                        type: types.CALCULATE_SHIPPING,
                        payload: {
                            delivery_option: params.delivery_option,
                            shipping_fee: 0.0
                        }
                    });
                }
            } catch (err) {
                console.log(err);
                dispatch({
                    type: types.CALCULATE_SHIPPING,
                    payload: {
                        delivery_option: params.delivery_option,
                        shipping_fee: 0.0
                    }
                });
            }
        }
    }
}

export const resetShipping = () => {
    console.log('resetShipping');
    return {
        type: types.RESET_SHIPPING
    }
}

/**
 * Get all product async and dispatch it via Redux
 *
 * @returns {Object} All products
 */
export const getProducts = () => {
    return async (dispatch) => {
        // dispatch({
        //     type: types.GET_PRODUCTS,
        //     payload: expectedProducts
        // })

        try {
            let resp = await request.post(serverURI + '/v1/get_products')
                .set('Content-Type', 'application/json')
                .send();

            if (resp.status === 200) {
                dispatch({
                    type: types.GET_PRODUCTS,
                    payload: resp.body
                });
            }
        } catch (err) {
            console.error(err);
            dispatch({
                type: types.GET_PRODUCTS,
                payload: null
            });
        }
    }
}

/**
 * Get specific product's available options & it's choices and dispatch it via Redux.
 * Primarily use to populate UI selection as component properties.
 *
 * @param {String} productName Product name
 *
 * @returns {Function} async function
 */
export const getProductOptions = (productName) => {
    return async (dispatch) => {
        try {
            let resp = await request.post(serverURI + '/v1/get_product_options')
                .set('Content-Type', 'application/json')
                .send({ product: productName });

            if (resp.status === 200) {
                dispatch({
                    type: types.GET_PRODUCT_OPTIONS,
                    payload: resp.body
                });
            }
        } catch (err) {
            console.error(err);
            dispatch({
                type: types.GET_PRODUCT_OPTIONS,
                payload: null
            });
        }
        // }
    }
}

/**
 * Get specific product's variants
 *
 * @param {String} productName Product name
 *
 * @returns {Object} Product variants
 */
export const getProductVariants = (productName) => {

    return async (dispatch) => {
        try {
            let resp = await request.post(serverURI + '/v1/get_product_variants')
                .set('Content-Type', 'application/json')
                .send({ product: productName });

            if (resp.status === 200) {
                dispatch({
                    type: types.GET_PRODUCT_VARIANTS,
                    payload: resp.body
                });
            }
        } catch (err) {
            console.error(err);
            dispatch({
                type: types.GET_PRODUCT_VARIANTS,
                payload: []
            });
        }
        // }
    }
}

/**
 * Get specific product's setup
 *
 * @param {String} productName Product name
 *
 * @returns {Object} Product options and variants
 */
export const getProductSetup = (productName) => {

    return async (dispatch) => {
        try {
            let resp = await request.post(serverURI + '/v1/get_product_setup')
                .set('Content-Type', 'application/json')
                .send({ product: productName });

            if (resp.status === 200) {
                console.log('getProductSetup successful');
                console.log(resp.body);

                dispatch({
                    type: types.GET_PRODUCT_SETUP,
                    payload: resp.body
                });
            }
        } catch (err) {
            console.error(err);
            console.log('getProductSetup failed');
            dispatch({
                type: types.GET_PRODUCT_SETUP,
                payload: {}
            });
        }
    }
}

/**
 * Indicate that there's no valid info for a variant by simulating server response.
 */
export const resetProductTimeAndCost = () => {
    return async (dispatch) => {
        dispatch({
            type: types.GET_PRODUCT_TIME_COST,
            payload: null
        });
    }
}
/**
 * Get specific product's time and cost, with the provided configuration
 *
 * @param {String} productName Product name
 * @param {Object} configuration Product selected configuration
 * @param {string} variant_id Known variant id
 *
 * @returns {Object} Time and cost of the configuration
 */
export const getProductTimeAndCost = (productName, configuration, variantId) => {

    return async (dispatch) => {
        // DEBUG: Temporary bypass while waiting for backend to complete
        let mockedProducts = [
            'Offset Business Card',
            'Digital Business Card',
            'Flyer',
            'Brochure',
            'Offset Booklet',
            'Digital Booklet',
            'Plastic Business Card',
            'Plastic Member Card',
            'CD Label',
            'Racing Horse Calendar'
        ]

        // if (mockedProducts.includes(productName)) {
        //     dispatch({
        //         type: types.GET_PRODUCT_TIME_COST,
        //         payload: expectedProductTimeAndCost
        //     });
        // } else {
        try {
            let resp = await request.post(serverURI + '/v1/get_product_time_cost')
                .set('Content-Type', 'application/json')
                .send({
                    product: productName,
                    configuration: configuration,
                    variant_id: variantId
                });

            if (resp.status === 200) {
                dispatch({
                    type: types.GET_PRODUCT_TIME_COST,
                    payload: resp.body
                });
            }
        } catch (err) {
            console.error(err);
            dispatch({
                type: types.GET_PRODUCT_TIME_COST,
                payload: []
            });
        }
        // }
    }
}

/**
 * @deprecated Use getProductTimeCost instead
 *
 * @param {*} product
 */
export const getProductPricing = (product) => {
    return async (dispatch) => {
        try {
            let resp = await request.post(serverURI + '/v1/get_product_pricing')
                .set('Content-Type', 'application/json')
                .send({ product: product });

            if (resp.status === 200) {
                dispatch({
                    type: types.GET_PRODUCT_PRICING,
                    payload: resp.body
                });
            }
        } catch (err) {
            console.error(err);
            dispatch({
                type: types.GET_PRODUCT_PRICING,
                payload: []
            });
        }
    }
}

/**
 * @deprecated Use getProductVariant instead
 *
 * @param {*} query
 */
export const getProductCosts = (query) => {
    return async (dispatch) => {
        try {
            let resp = await request.post(serverURI + '/v1/get_product_costs')
                .set('Content-Type', 'application/json')
                .send(query);

            if (resp.status === 200) {
                dispatch({
                    type: types.GET_PRODUCT_COSTS,
                    payload: resp.body
                });
            }
        } catch (err) {
            console.error(err);
            dispatch({
                type: types.GET_PRODUCT_COSTS,
                payload: []
            });
        }
    }
}

export const uploadFiles = (files, type) => {
    let timestamp = moment().format('YYYYMMDD_HHmm');

    return async (dispatch) => {
        dispatch({
            type: types.START_FILE_UPLOAD,
            payload: null
        });

        try {
            let resp = await request.post(serverURI + '/v1/request_access')
                .set('Content-Type', 'application/json')
                .send();
            if (resp.status === 200) {
                console.log('Resp 200 received!');
                let credentials = resp.body.credentials;
                let ossclient = new oss({
                    region: 'oss-ap-southeast-1',
                    accessKeyId: credentials.AccessKeyId,
                    accessKeySecret: credentials.AccessKeySecret,
                    stsToken: credentials.SecurityToken,
                    bucket: 'utech'
                });

                dispatch({
                    type: types.UPDATE_TOTAL_UPLOADS,
                    payload: files.length
                });

                co(function* () {
                    for (let i = 0; i < files.length; i++) {
                        dispatch({
                            type: types.UPLOAD_FILENAME,
                            payload: files[i].name
                        });

                        let result = yield ossclient.put('uploads/' + timestamp + '_' + type + '_' + files[i].name, files[i]);

                        if (result) {
                            console.log(result);
                            dispatch({
                                type: types.FILE_UPLOAD_SUCCESS,
                                payload: result
                            });
                        } else {
                            console.log(files[i].name + ' upload failed!');
                            dispatch({
                                type: types.FILE_UPLOAD_FAILED,
                                payload: null
                            });
                        }
                    }
                }).catch(function (err) {
                    console.log(err);
                    dispatch({
                        type: types.FILE_UPLOAD_FAILED,
                        payload: null
                    });
                });
            } else {
                console.log('Request failed with response ' + resp.status);
                dispatch({
                    type: types.FILE_UPLOAD_FAILED,
                    payload: null
                });
            }
        } catch (err) {
            console.log(err);
            dispatch({
                type: types.FILE_UPLOAD_FAILED,
                payload: null
            });
        }
    }
}

export const resetUploads = () => {
    return {
        type: types.RESET_UPLOAD_COUNT
    }
}

export const updateTotalUploads = (total) => {
    return {
        type: types.UPDATE_TOTAL_UPLOADS,
        payload: total
    }
}

export const addToCart = (item) => {
    console.log('addToCart: ' + JSON.stringify(item));

    return {
        type: types.ADD_TO_CART,
        payload: item
    }
}

export const updateCart = (cart) => {
    console.log('updateCart: ' + JSON.stringify(cart));

    return {
        type: types.UPDATE_CART,
        payload: cart
    }
}

export const emptyCart = () => {
    console.log('Emptying shopping cart');

    return {
        type: types.EMPTY_CART
    }
}

export const saveOrder = (order) => {
    console.log('Saving order');
    return {
        type: types.SAVE_ORDER,
        payload: order
    }
}

export const verifyVoucher = (customerID, voucherCode, itemList) => {
    console.log('Verify voucher');

    return async (dispatch) => {
        try {
            let resp = await request.post(serverURI + '/v1/verify_voucher')
                .set('Content-Type', 'application/json')
                .send({ customer_id: customerID, voucher_id: voucherCode, item_list: itemList });
            if (resp.status === 200) {
                dispatch({
                    type: types.VERIFY_VOUCHER,
                    payload: resp.body
                });
            } else {
                dispatch({
                    type: types.VERIFY_VOUCHER,
                    payload: resp.body
                });
            }
        } catch (err) {
            dispatch({
                type: types.VERIFY_VOUCHER,
                payload: err.response
            });
        }
    }
}

// Clear Voucher When Switching Page
export const resetVoucher = () => {
    console.log('Emptying voucher');

    return {
        type: types.RESET_VOUCHER
    }
}

export const fetchBanners = () => {
    console.log('Fetch Banners');

    return async (dispatch) => {
        try {
            let resp = await request.get(serverURI + '/v1/fetch_banners')
                .set('Accept', 'application/json');

            console.log(resp);
            if (resp.status === 200) {
                dispatch({
                    type: types.FETCH_BANNERS,
                    payload: resp.body
                });
            } else {
                dispatch({
                    type: types.FETCH_BANNERS,
                    payload: null
                });
            }
        } catch (err) {
            dispatch({
                type: types.FETCH_BANNERS,
                payload: null
            });
        }
    }
}
