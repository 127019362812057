import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { urlToProperty } from "query-string-params";
import { Container, Row, Col, Card, CardDeck, CardHeader, CardBody, CardImg, CardText, Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import { resetShipping, emptyCart, fetchUserRecords } from '../../../actions';

class Acknowledge extends Component {
    constructor(props) {
        super(props);
        this.search_query = urlToProperty(props.location.search);
    }

    render() {
        return (
            <Container fluid={false} className="my-3">
                <h2 className="text-center">Thank You!</h2>
                <CardDeck>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col>
                                    <p className="text-center">Your {this.search_query.title} has been submitted successfully.</p>
                                    <p className="text-center">We will get back to you within 2 working days.</p>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </CardDeck>
            </Container>
        )
    }
}

const mapStateToProps = ({ main }) => {
    return { main };
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        resetShipping: resetShipping,
        emptyCart: emptyCart,
        fetchUserRecords: fetchUserRecords
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Acknowledge);