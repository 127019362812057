import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  Alert,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Progress
} from 'reactstrap';
import { uploadFiles, resetUploads, showLoading, showLoginModal } from '../../../actions'
import './CustomQuoteGeneralPage.css';
import { serverURI } from '../../../config';

const request = require('superagent');

class CustomQuoteGeneralPage extends Component {
  constructor(props) {
    super(props);
    this.product = 'Custom Quote - General';
    this.state = {
      /**
       * Current configuration selected by end-user
       */
      configurations: {
        cover_artwork: null,
        cover_artwork_path: null,
        cover_artwork_download_url: null
      },
      isLoadingData: true,
      showOtherInnerMat: false,
      showOtherCoverMat: false,
      requestLogin: false,
      showError: false
    };
    this.otherPaperMatRef = React.createRef();
  }

  /**
   * Invoked immediately after a component is mounted (inserted into the tree).
   * Initialization that requires DOM nodes should go here.
   * If you need to load data from a remote endpoint, this is a good place to instantiate the network request.
   */
  componentDidMount() {
    document.title = `U-Tech - ${this.product}`
  }


  /**
   * Invoked immediately after updating occurs.
   * This method is not called for the initial render.
   * Use this as an opportunity to operate on the DOM when the component has been updated.
   * This is also a good place to do network requests as long as you compare the current props to previous props (e.g. a network request may not be necessary if the props have not changed).
   *
   * @param {*} previousProps Previous properties before this completed rendering cycle
   * @param {*} previousState Previous state before this already completed rendering cycle
   */
  componentDidUpdate(previousProps, previousState) {

    if (previousProps.cart.upload_count !== this.props.cart.upload_count) {
      if ((this.props.cart.total_uploads > 0) && (this.props.cart.total_uploads === this.props.cart.upload_count)) {
        let newConfig = this.state.configurations;
        newConfig.cover_artwork = this.props.cart.cover_artwork;
        newConfig.cover_artwork_path = this.props.cart.cover_artwork_path;
        this.setState({
          configurations: newConfig
        });

      }
    }
  }

  /**
   * Handle file selection event.
   * Acquires token from backend and upload files to AliCloud storage.
   *
   * @param {*} e eventData
   */
  handleFileSelect(type, e) {
    e.stopPropagation();
    e.preventDefault();
    let files = e.target.files;

    console.log(files);
    if (files.length > 0) {
      this.props.uploadFiles(files, type);
    }
  }

  handleRadioClicked(e) {
    if (e.target.name === 'paper-material') {
      if (e.target.value === 'others') {
        this.setState({ showOtherPaperMat: true });
      } else {
        this.setState({ showOtherPaperMat: false });
      }
    }
  }

  async handleSubmit(e) {
    const { configurations } = this.state;
    const { customer } = this.props.main;

    e.preventDefault();

    let newConfig = {
      product: e.target.elements['product-type'].value,
      opened_size: {
        width: parseInt(e.target.elements['open-size-width'].value),
        height: parseInt(e.target.elements['open-size-height'].value)
      },
      closed_size: {
        width: parseInt(e.target.elements['closed-size-width'].value),
        height: parseInt(e.target.elements['closed-size-height'].value)
      },
      paper_material: e.target.elements['paper-material'].value,
      other_paper_material: e.target.elements['other-paper-material'].value,
      quantity: [parseInt(e.target.elements['quantity-1'].value), parseInt(e.target.elements['quantity-2'].value), parseInt(e.target.elements['quantity-3'].value)],
      print: e.target.elements['print'].value,
      lamination: e.target.elements['lamination'].value,
      folding: e.target.elements['folding'].value,
      spot_uv: e.target.elements['spot-uv'].value,
      hot_stamping: e.target.elements['hot-stamping'].value,
      round_corner: e.target.elements['round-corner'].value,
      hole_punching: e.target.elements['hole-punching'].value,
      cover_artwork: configurations.cover_artwork,
      cover_artwork_path: configurations.cover_artwork_path,
      cover_artwork_download_url: e.target.elements['cover_artwork_download_url'].value,
      shipping: e.target.elements['shipping'].value,
      remarks: e.target.elements['remarks'].value
    }

    if (this.checkRequiredConfigurations(newConfig) === false) {
      return;
    }

    if (customer) {
      let quote_request = {
        product: this.product,
        customer: customer,
        configurations: newConfig
      }

      try {
        let resp = await request.post(serverURI + '/v1/request_quote')
          .set('Content-Type', 'application/json')
          .send(quote_request);
        if (resp.status === 200) {
          // this.formRef.reset();
          this.setState({
            configurations: {
              cover_artwork: null,
              cover_artwork_path: null
            },
            isLoadingData: true,
            showOtherPaperMat: false,
            requestLogin: false,
            showError: false
          });
          this.props.showLoading(false);
          this.props.showLoginModal(false);
          this.props.history.push('/acknowledge?title=General Custom Quote');
        }
      } catch (err) {
        console.log(err);
        this.setState({ showError: true });
      }

    } else {
      this.setState({
        requestLogin: true
      });
    }
  }

  /**
   * Check if the required configurations is supplied.
   *
   * @returns True if all required configurations is supplied. False otherwise.
   */
  checkRequiredConfigurations(newConfig) {
    let missingConfigurations = [];

    // Add checks for required configuration here
    if (newConfig['cover_artwork_path'] == null && (
      newConfig['cover_artwork_download_url'] == null ||
      newConfig['cover_artwork_download_url'] == ""
    )) {
      missingConfigurations.push("Artwork File or URL");
    }

    console.log(missingConfigurations);
    if (missingConfigurations.length > 0) {
      window.alert(`Please supply the missing information: ${missingConfigurations.join(", ")}.`);
      return false;
    }

    // everything OK
    return true;
  }

  render() {
    const { requestLogin, showError } = this.state;
    const { upload_started, upload_count, upload_filename, total_uploads } = this.props.cart;
    const { customer } = this.props.main;

    return (
      <Container className="my-3">
        <Modal isOpen={upload_started} className='scrolling'>
          <ModalHeader>File Upload</ModalHeader>
          <ModalBody>
            {
              (total_uploads === 0)
                ? (
                  <div>
                    <span>Contacting server . . . <br /><br /></span>
                    <Progress multi>
                      <Progress bar animated value={10} />
                    </Progress>
                  </div>
                )
                : ((total_uploads < 0)
                  ? (<span>Upload Failed! <br /><br /><br /></span>)
                  : ((upload_count === total_uploads)
                    ? (
                      <div>
                        <span>Upload Completed!<br /><br /></span>
                        <Progress multi>
                          <Progress bar color="success" value={100} > 100% </Progress>
                        </Progress>
                      </div>
                    )
                    : (
                      <div>
                        <span>Uploading <i>{upload_filename}</i><br /><br /></span>
                        <Progress multi>
                          <Progress bar animated value={20} />
                        </Progress>
                      </div>
                    )))
            }
          </ModalBody>
          <ModalFooter>
            <Button color='success' onClick={() => this.props.resetUploads()} disabled={(total_uploads === 0) || (upload_count !== total_uploads)}>Close</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showError}>
          <ModalHeader>{this.product}</ModalHeader>
          <ModalBody>
            Failed to submit request. Please try again.
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => this.setState({ showError: false })}>OK</Button>
          </ModalFooter>
        </Modal>
        <Row>
          <Col>
            <div>
              <h1 className="my-2">General</h1>
              <Form innerRef={(ref) => this.formRef = ref} onSubmit={this.handleSubmit.bind(this)}>
                <h5>Product Specification</h5>
                <FormGroup row>
                  <Label htmlFor="product-type" sm={2}><span className="mandatory">*</span>Product</Label>
                  <Col sm={5}>
                    <Input id="product-type" type="text" name="product-type" required />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="open-size" sm={2}><span className="mandatory">*</span>Open Size</Label>
                  <Col sm={5}>
                    <InputGroup>
                      <Input id="open-size" type="number" name="open-size-width" placeholder="width" required />
                      <InputGroupAddon addonType="append">mm</InputGroupAddon>
                    </InputGroup>
                  </Col>
                  <Col sm={5}>
                    <InputGroup>
                      <Input type="number" name="open-size-height" placeholder="height" required />
                      <InputGroupAddon addonType="append">mm</InputGroupAddon>
                    </InputGroup>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="closed-size" sm={2}><span className="mandatory">*</span>Closed Size</Label>
                  <Col sm={5}>
                    <InputGroup>
                      <Input id="closed-size" type="number" name="closed-size-width" placeholder="width" required />
                      <InputGroupAddon addonType="append">mm</InputGroupAddon>
                    </InputGroup>
                  </Col>
                  <Col sm={5}>
                    <InputGroup>
                      <Input type="number" name="closed-size-height" placeholder="height" required />
                      <InputGroupAddon addonType="append">mm</InputGroupAddon>
                    </InputGroup>
                  </Col>
                </FormGroup>
                <FormGroup tag="fieldset" row onChange={this.handleRadioClicked.bind(this)}>
                  <legend className="col-form-label col-sm-2">Paper Material</legend>
                  <Col sm={10}>
                    <FormGroup check><Label check><Input type="radio" name="paper-material" value="80gsm Simili Paper" required />{' '}80gsm Simili Paper</Label></FormGroup>
                    <FormGroup check><Label check><Input type="radio" name="paper-material" value="100gsm Simili Paper" />{' '}100gsm Simili Paper</Label></FormGroup>
                    <FormGroup check><Label check><Input type="radio" name="paper-material" value="100gsm Gloss Art Paper" />{' '}100gsm Gloss Art Paper</Label></FormGroup>
                    <FormGroup check><Label check><Input type="radio" name="paper-material" value="128gsm Gloss Art Paper" />{' '}128gsm Gloss Art Paper</Label></FormGroup>
                    <FormGroup check><Label check><Input type="radio" name="paper-material" value="157gsm Gloss Art Paper" />{' '}157gsm Gloss Art Paper</Label></FormGroup>
                    <FormGroup check><Label check><Input type="radio" name="paper-material" value="230gsm Gloss Art Card" />{' '}230gsm Gloss Art Card</Label></FormGroup>
                    <FormGroup check><Label check><Input type="radio" name="paper-material" value="260gsm Gloss Art Card" />{' '}260gsm Gloss Art Card</Label></FormGroup>
                    <FormGroup check><Label check><Input type="radio" name="paper-material" value="300gsm Gloss Art Card" />{' '}300gsm Gloss Art Card</Label></FormGroup>
                    <FormGroup check><Label check><Input type="radio" name="paper-material" value="350gsm Gloss Art Card" />{' '}350gsm Gloss Art Card</Label></FormGroup>
                    <FormGroup check><Label check><Input type="radio" name="paper-material" value="others" />{' '}others</Label></FormGroup>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="other-paper-material" sm={2}>Other Paper Material</Label>
                  <Col sm={5}>
                    <Input id="other-paper-material" ref={this.otherPaperMatRef} type="text" name="other-paper-material" disabled={!this.state.showOtherPaperMat} required />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="quantity-1" sm={2}><span className="mandatory">*</span>Quantity 1</Label>
                  <Col sm={5}>
                    <InputGroup>
                      <Input id="quantity-1" type="number" name="quantity-1" required />
                      <InputGroupAddon addonType="append">pcs</InputGroupAddon>
                    </InputGroup>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="quantity-2" sm={2}>Quantity 2</Label>
                  <Col sm={5}>
                    <InputGroup>
                      <Input id="quantity-2" type="number" name="quantity-2" />
                      <InputGroupAddon addonType="append">pcs</InputGroupAddon>
                    </InputGroup>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="quantity-3" sm={2}>Quantity 3</Label>
                  <Col sm={5}>
                    <InputGroup>
                      <Input id="quantity-3" type="number" name="quantity-3" />
                      <InputGroupAddon addonType="append">pcs</InputGroupAddon>
                    </InputGroup>
                  </Col>
                </FormGroup>
                <FormGroup tag="fieldset" row>
                  <legend className="col-form-label col-sm-2"><span className="mandatory">*</span>Print Colour</legend>
                  <Col sm={10}>
                    <FormGroup check><Label check><Input type="radio" name="print" value="4 Colour (Front)" required />{' '}4 Colour (Front)</Label></FormGroup>
                    <FormGroup check><Label check><Input type="radio" name="print" value="4 Colour (Both)" />{' '}4 Colour (Both)</Label></FormGroup>
                  </Col>
                </FormGroup>
                <FormGroup tag="fieldset" row>
                  <legend className="col-form-label col-sm-2"><span className="mandatory">*</span>Lamination</legend>
                  <Col sm={10}>
                    <FormGroup check><Label check><Input type="radio" name="lamination" value="None" required />{' '}None</Label></FormGroup>
                    <FormGroup check><Label check><Input type="radio" name="lamination" value="Gloss Lamination (Front)" />{' '}Gloss Lamination (Front)</Label></FormGroup>
                    <FormGroup check><Label check><Input type="radio" name="lamination" value="Gloss Lamination (Both)" />{' '}Gloss Lamination (Both)</Label></FormGroup>
                    <FormGroup check><Label check><Input type="radio" name="lamination" value="Matte Lamination (Front)" />{' '}Matte Lamination (Front)</Label></FormGroup>
                    <FormGroup check><Label check><Input type="radio" name="lamination" value="Matte Lamination (Both)" />{' '}Matte Lamination (Both)</Label></FormGroup>
                  </Col>
                </FormGroup>
                <FormGroup tag="fieldset" row>
                  <legend className="col-form-label col-sm-2"><span className="mandatory">*</span>Folding</legend>
                  <Col sm={10}>
                    <FormGroup check><Label check><Input type="radio" name="folding" value="Yes" required />{' '}Yes</Label></FormGroup>
                    <FormGroup check><Label check><Input type="radio" name="folding" value="No" />{' '}No</Label></FormGroup>
                  </Col>
                </FormGroup>
                <FormGroup tag="fieldset" row>
                  <legend className="col-form-label col-sm-2"><span className="mandatory">*</span>Spot UV</legend>
                  <Col sm={10}>
                    <FormGroup check><Label check><Input type="radio" name="spot-uv" value="Yes" required />{' '}Yes</Label></FormGroup>
                    <FormGroup check><Label check><Input type="radio" name="spot-uv" value="No" />{' '}No</Label></FormGroup>
                  </Col>
                </FormGroup>
                <FormGroup tag="fieldset" row>
                  <legend className="col-form-label col-sm-2"><span className="mandatory">*</span>Hot Stamping Colour</legend>
                  <Col sm={10}>
                    <FormGroup check><Label check><Input type="radio" name="hot-stamping" value="None" required />{' '}None</Label></FormGroup>
                    <FormGroup check><Label check><Input type="radio" name="hot-stamping" value="1 Colour (Front)" />{' '}1 Colour (Front)</Label></FormGroup>
                    <FormGroup check><Label check><Input type="radio" name="hot-stamping" value="1 Colour (Back)" />{' '}1 Colour (Back)</Label></FormGroup>
                    <FormGroup check><Label check><Input type="radio" name="hot-stamping" value="2 Colour (Front)" />{' '}2 Colour (Front)</Label></FormGroup>
                    <FormGroup check><Label check><Input type="radio" name="hot-stamping" value="2 Colour (Back)" />{' '}2 Colour (Back)</Label></FormGroup>
                    <p className="form-notes">
                      * 1 side only (Front or Back) <br />
                      * 6 colours available: Black, Blue, Gold, Green, Red, Silver (Max. 2 colours)
                    </p>
                  </Col>
                </FormGroup>
                <FormGroup tag="fieldset" row>
                  <legend className="col-form-label col-sm-2"><span className="mandatory">*</span>Round Corner</legend>
                  <Col sm={10}>
                    <FormGroup check><Label check><Input type="radio" name="round-corner" value="Yes" required />{' '}Yes</Label></FormGroup>
                    <FormGroup check><Label check><Input type="radio" name="round-corner" value="No" />{' '}No</Label></FormGroup>
                  </Col>
                </FormGroup>
                <FormGroup tag="fieldset" row>
                  <legend className="col-form-label col-sm-2"><span className="mandatory">*</span>Hole Punching</legend>
                  <Col sm={10}>
                    <FormGroup check><Label check><Input type="radio" name="hole-punching" value="Yes" required />{' '}Yes</Label></FormGroup>
                    <FormGroup check><Label check><Input type="radio" name="hole-punching" value="No" />{' '}No</Label></FormGroup>
                  </Col>
                </FormGroup>
                <FormGroup tag="fieldset" row>
                  <legend className="col-form-label col-sm-2"><span className="mandatory">*</span>Require Shipping</legend>
                  <Col sm={10}>
                    <FormGroup check><Label check><Input type="radio" name="shipping" value="Self Collect" required />{' '}Self Collect</Label></FormGroup>
                    <FormGroup check><Label check><Input type="radio" name="shipping" value="Courier Service" />{' '}Courier Service</Label></FormGroup>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="custom-general-cover" sm={2}><span className="mandatory">*</span>Artwork File</Label>
                  <Col sm={10}>
                    <Input type="file" name='custom-general-cover' id='custom-general-cover' onChange={this.handleFileSelect.bind(this, 'custom_quote_cover_artwork')} accept='.pdf, .rar, .zip' />
                    <p className="form-notes">
                      Accepted upload file format: .pdf, .rar, .zip
                    </p>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="cover-artwork-url" sm={2}>Artwork URL</Label>
                  <Col sm={5}>
                      <Input id="cover-artwork-url" type="text" name="cover_artwork_download_url" placeholder="ARTWORK DOWNLOAD URL" style={{ textTransform: 'none' }}/>
                  </Col>
                </FormGroup>
                <br/>
                <FormGroup tag="fieldset" row>
                  <Label htmlFor="remarks" sm={2}><span className="mandatory">*</span>Remarks</Label>
                  <Col sm={10}>
                    <Input type="textarea" name="remarks" id="remarks" required /> <br />
                    Thank you for your enquiry. We will get back to you within 2 working days.
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Col sm={12}>
                    <Button>Submit</Button>
                  </Col>
                </FormGroup>
                {requestLogin && (customer === null) &&
                  <Alert color="warning">
                    You need to login to submit custom quote. Click <a style={{ cursor: 'pointer' }} onClick={() => this.props.showLoginModal(true)}><b>HERE</b></a> to login.
                  </Alert>
                }
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ main, cart }) => {
  return { main, cart };
}

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators({
    uploadFiles: uploadFiles,
    resetUploads: resetUploads,
    showLoading: showLoading,
    showLoginModal: showLoginModal
  }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(CustomQuoteGeneralPage);
