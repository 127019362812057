import React, { Component } from 'react';

import {
  Container,
  Row,
  Col,
  Table,
} from 'reactstrap';

import booklet from '../../../assets/product-guide/booklet.png';
import brochure from '../../../assets/product-guide/brochure.png';
import businessCard from '../../../assets/product-guide/business-card.png';
import flyer from '../../../assets/product-guide/flyer.png';
import plasticCard from '../../../assets/product-guide/plastic-card.png';
import cdLabel from '../../../assets/product-guide/cd-label.png';
import jpgLogo from '../../../assets/product-guide/jpg-logo.png';
import coredrawLogo from '../../../assets/product-guide/coredraw-logo.png';
import iconDownload from '../../../assets/product-guide/icon-download.png';
import illustratorLogo from '../../../assets/product-guide/illustrator-logo.png';
import pdfLogo from '../../../assets/product-guide/pdf-logo.png';

import './ProductSizeTemplatePage.css';

class ProductSizeTemplatePage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container className="my-3">
        <Row>
          <Col>
            <h1 className="my-2">Product Size Template</h1>
            <p>Begin your artwork with the standard product size template that we print. Below are templates for you to download. Template file format included in each file is an Adobe Illustrator (*.ai) file, CorelDraw (*.cdr) file and Adobe PDF (*.pdf) file completed with guides for bleed, trim and safety zone.</p>
          </Col>
        </Row>
        <Row>
          <Col className="mt-2">
            <p><strong>Product:</strong></p>
          </Col>
        </Row>
        <Row>
          <Col className="text-center mb-2">
            <a href="#booklet">
              <img className="product-image" src={booklet} alt="booklet" />
              <br/>
              Booklet
            </a>
          </Col>
          <Col className="text-center">
            <a href="#brochure">
              <img className="product-image" src={brochure} alt="brochure" />
              <br/>
              Brochure
            </a>
          </Col>
          <Col className="text-center">
            <a href="#business_card">
              <img className="product-image" src={businessCard} alt="business-card" />
              <br/>
              Business Card
            </a>
          </Col>
          <Col className="text-center">
            <a href="#flyer">
              <img className="product-image" src={flyer} alt="flyer" />
              <br/>
              Flyer
            </a>
          </Col>
          {/* <Col className="text-center">
            <a href="#">
              <img className="product-image" src={plasticCard} alt="plastic-card" />
              <br/>
              Plastic Card
            </a>
          </Col>
          <Col className="text-center">
            <a href="#">
              <img className="product-image" src={cdLabel} alt="cd-label" />
              <br/>
              Cd Label
            </a>
          </Col> */}
        </Row>
        <Row id="booklet">
          <Col className="mt-3">
            <h2>Booklet</h2>
            <Table bordered={true}>
              <thead className="specification-table-header">
                <tr>
                  <th className="align-middle">Closed Size (mm)</th>
                  <th className="text-center">
                    <img className="table-img" src={illustratorLogo} alt="illustrator-logo" />
                  </th>
                  <th className="text-center">
                    <img className="table-img" src={coredrawLogo} alt="coredraw-logo" />
                  </th>
                  <th className="text-center">
                    <img className="table-img" src={pdfLogo} alt="pdf-logo" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Saddle Stitching - A4 Portrait 210 (w) x 297 (h)</td>
                  <td rowSpan="6" className="download-col text-center align-middle">
                    <a href="/files/artwork-specifications/general-artwork-specifications.pdf" target="_blank">
                      <img className="download-image" src={iconDownload} alt="icon-download" />
                    </a>
                  </td>
                  <td rowSpan="6" className="download-col text-center align-middle">
                    <a href="/files/artwork-specifications/general-artwork-specifications.pdf" target="_blank">
                      <img className="download-image" src={iconDownload} alt="icon-download" />
                    </a>
                  </td>
                  <td rowSpan="6" className="download-col text-center align-middle">
                    <a href="/files/artwork-specifications/general-artwork-specifications.pdf" target="_blank">
                      <img className="download-image" src={iconDownload} alt="icon-download" />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Saddle Stitching - A5 Portrait 148 (w) x 210 (h)</td>
                </tr>
                <tr>
                  <td>Saddle Stitching - A5 Landscape 210 (w) x 148 (h)</td>
                </tr>
                <tr>
                  <td>Perfect Binding - A4 Portrait 210 (w) x 297 (h)</td>
                </tr>
                <tr>
                  <td>Perfect Binding - A5 Portrait 148 (w) x 210 (h)</td>
                </tr>
                <tr>
                  <td>Perfect Binding - A5 Landscape 210 (w) x 148 (h)</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row id="brochure">
          <Col className="mt-3">
            <h2>Brochure</h2>
            <Table bordered={true}>
              <thead className="specification-table-header">
                <tr>
                  <th className="align-middle">Open Size (mm)</th>
                  <th className="text-center">
                    <img className="table-img" src={illustratorLogo} alt="illustrator-logo" />
                  </th>
                  <th className="text-center">
                    <img className="table-img" src={coredrawLogo} alt="coredraw-logo" />
                  </th>
                  <th className="text-center">
                    <img className="table-img" src={pdfLogo} alt="pdf-logo" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>A3 Landscape 420 (w) x 297 (h)</td>
                  <td rowSpan="3" className="download-col text-center align-middle">
                    <a href="/files/artwork-specifications/general-artwork-specifications.pdf" target="_blank">
                      <img className="download-image" src={iconDownload} alt="icon-download" />
                    </a>
                  </td>
                  <td rowSpan="3" className="download-col text-center align-middle">
                    <a href="/files/artwork-specifications/general-artwork-specifications.pdf" target="_blank">
                      <img className="download-image" src={iconDownload} alt="icon-download" />
                    </a>
                  </td>
                  <td rowSpan="3" className="download-col text-center align-middle">
                    <a href="/files/artwork-specifications/general-artwork-specifications.pdf" target="_blank">
                      <img className="download-image" src={iconDownload} alt="icon-download" />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>A4 Landscape 297 (w) x 210 (h)</td>
                </tr>
                <tr>
                  <td>A5 Landscape 210 (w) x 148 (h)</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row id="business_card">
          <Col className="mt-3">
            <h2>Business Card</h2>
            <Table bordered={true}>
              <thead className="specification-table-header">
                <tr>
                  <th className="align-middle">Open Size (mm)</th>
                  <th className="text-center">
                    <img className="table-img" src={illustratorLogo} alt="illustrator-logo" />
                  </th>
                  <th className="text-center">
                    <img className="table-img" src={coredrawLogo} alt="coredraw-logo" />
                  </th>
                  <th className="text-center">
                    <img className="table-img" src={pdfLogo} alt="pdf-logo" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Standard - 86 (w) x 52 (h)</td>
                  <td rowSpan="10" className="download-col text-center align-middle">
                    <a href="/files/artwork-specifications/general-artwork-specifications.pdf" target="_blank">
                      <img className="download-image" src={iconDownload} alt="icon-download" />
                    </a>
                  </td>
                  <td rowSpan="10" className="download-col text-center align-middle">
                    <a href="/files/artwork-specifications/general-artwork-specifications.pdf" target="_blank">
                      <img className="download-image" src={iconDownload} alt="icon-download" />
                    </a>
                  </td>
                  <td rowSpan="10" className="download-col text-center align-middle">
                    <a href="/files/artwork-specifications/general-artwork-specifications.pdf" target="_blank">
                      <img className="download-image" src={iconDownload} alt="icon-download" />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Standard - 89 (w) x 54 (h)</td>
                </tr>
                <tr>
                  <td>Fat Fold - 86 (w) x 104 (h)</td>
                </tr>
                <tr>
                  <td>Fat Fold - 104 (w) x 86 (h)</td>
                </tr>
                <tr>
                  <td>Fat Fold - 89 (w) x 108 (h)</td>
                </tr>
                <tr>
                  <td>Fat Fold - 108 (w) x 89 (h)</td>
                </tr>
                <tr>
                  <td>Thin Fold - 52 (w) x 172 (h)</td>
                </tr>
                <tr>
                  <td>Thin Fold - 54 (w) x 178 (h)</td>
                </tr>
                <tr>
                  <td>Thin Fold - 178 (w) x 54 (h)</td>
                </tr>
                <tr>
                  <td>Thin Fold - 172 (w) x 52 (h)</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row id="flyer">
          <Col className="mt-3">
            <h2>Flyer</h2>
            <Table bordered={true}>
              <thead className="specification-table-header">
                <tr>
                  <th className="align-middle">Open Size (mm)</th>
                  <th className="text-center">
                    <img className="table-img" src={illustratorLogo} alt="illustrator-logo" />
                  </th>
                  <th className="text-center">
                    <img className="table-img" src={coredrawLogo} alt="coredraw-logo" />
                  </th>
                  <th className="text-center">
                    <img className="table-img" src={pdfLogo} alt="pdf-logo" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>A3 Portrait 297 (w) x 420 (h)</td>
                  <td rowSpan="4" className="download-col text-center align-middle">
                    <a href="/files/artwork-specifications/general-artwork-specifications.pdf" target="_blank">
                      <img className="download-image" src={iconDownload} alt="icon-download" />
                    </a>
                  </td>
                  <td rowSpan="4" className="download-col text-center align-middle">
                    <a href="/files/artwork-specifications/general-artwork-specifications.pdf" target="_blank">
                      <img className="download-image" src={iconDownload} alt="icon-download" />
                    </a>
                  </td>
                  <td rowSpan="4" className="download-col text-center align-middle">
                    <a href="/files/artwork-specifications/general-artwork-specifications.pdf" target="_blank">
                      <img className="download-image" src={iconDownload} alt="icon-download" />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>A4 Portrait 210 (w) x 297 (h)</td>
                </tr>
                <tr>
                  <td>A5 Portrait 148 (w) x 210 (h)</td>
                </tr>
                <tr>
                  <td>DL Portrait 99 (w) x 210 (h))</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        {/* <Row>
          <Col className="mt-3">
            <h2>Plastic Card</h2>
            <Table bordered={true}>
              <thead className="specification-table-header">
                <tr>
                  <th className="align-middle">Open Size (mm)</th>
                  <th className="text-center">
                    <img className="table-img" src={illustratorLogo} alt="illustrator-logo" />
                  </th>
                  <th className="text-center">
                    <img className="table-img" src={coredrawLogo} alt="coredraw-logo" />
                  </th>
                  <th className="text-center">
                    <img className="table-img" src={jpgLogo} alt="jpg-logo" />
                  </th>
                  <th className="text-center">
                    <img className="table-img" src={pdfLogo} alt="pdf-logo" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>PVC 85.5 x 54 Emboss Magnetic Strip Guidelines</td>
                  <td rowSpan="2" className="download-col text-center align-middle">
                    <a href="/files/artwork-specifications/general-artwork-specifications.pdf" target="_blank">
                      <img className="download-image" src={iconDownload} alt="icon-download" />
                    </a>
                  </td>
                  <td rowSpan="2" className="download-col text-center align-middle">
                    <a href="/files/artwork-specifications/general-artwork-specifications.pdf" target="_blank">
                      <img className="download-image" src={iconDownload} alt="icon-download" />
                    </a>
                  </td>
                  <td rowSpan="2" className="download-col text-center align-middle">
                    <a href="/files/artwork-specifications/general-artwork-specifications.pdf" target="_blank">
                      <img className="download-image" src={iconDownload} alt="icon-download" />
                    </a>
                  </td>
                  <td rowSpan="2" className="download-col text-center align-middle">
                    <a href="/files/artwork-specifications/general-artwork-specifications.pdf" target="_blank">
                      <img className="download-image" src={iconDownload} alt="icon-download" />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>PVC 85.5 x 54 Emboss Magnetic Strip sample</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3">
            <h2>CD Label</h2>
            <Table bordered={true}>
              <thead className="specification-table-header">
                <tr>
                  <th className="align-middle">CD Size (mm)</th>
                  <th className="text-center">
                    <img className="table-img" src={illustratorLogo} alt="illustrator-logo" />
                  </th>
                  <th className="text-center">
                    <img className="table-img" src={coredrawLogo} alt="coredraw-logo" />
                  </th>
                  <th className="text-center">
                    <img className="table-img" src={pdfLogo} alt="pdf-logo" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>CD Label 118</td>
                  <td rowSpan="2" className="download-col text-center align-middle">
                    <a href="/files/artwork-specifications/general-artwork-specifications.pdf" target="_blank">
                      <img className="download-image" src={iconDownload} alt="icon-download" />
                    </a>
                  </td>
                  <td rowSpan="2" className="download-col text-center align-middle">
                    <a href="/files/artwork-specifications/general-artwork-specifications.pdf" target="_blank">
                      <img className="download-image" src={iconDownload} alt="icon-download" />
                    </a>
                  </td>
                  <td rowSpan="2" className="download-col text-center align-middle">
                    <a href="/files/artwork-specifications/general-artwork-specifications.pdf" target="_blank">
                      <img className="download-image" src={iconDownload} alt="icon-download" />
                    </a>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row> */}
      </Container>
    );
  }
}

export default ProductSizeTemplatePage;
