import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, FormFeedback } from 'reactstrap'
import { checkArray } from '../Utility'
/**
 * FormGroup to provide user with manual input.
 * 
 * @param {String}      name            - Name of the configuration. Used to identify which configuration has changed.
 * @param {String}      label           - Display for the drop down
 * @param {*}           value           - Current value.
 * @param {Object}      inputAttributes - Type specific attributes in a simple dictionary with no nested value. See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input for list of possible values.
 * @param {Function}    onChange        - Callback to handle changes.
 */
export default class ManualInputFormGroup extends Component {
    constructor(props) {
        super(props);
        // should do some checks in the input attributes to make sure it's valid
    }

    render() {
        let inputAttributes = this.props.inputAttributes != null ? this.props.inputAttributes : {};

        return (
            <FormGroup row>
                <Label htmlFor={this.props.name} sm={3}>{this.props.label}</Label>
                <Col sm={9}>
                    <Input
                        id={this.props.name}
                        name={this.props.name}
                        value={this.props.value}
                        {...inputAttributes}
                        onChange={this.props.onChange}
                        invalid={this.props.invalid}
                    />
                    <FormFeedback>{this.props.errorText}</FormFeedback>
                </Col>
            </FormGroup>
        )
    }
}