import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Container, Row, Col, Badge, FormGroup, Label, Input, Button, UncontrolledCollapse } from 'reactstrap';
import DigitalBusinessCard from './DigitalBusinessCard';
import OffsetBusinessCard from './OffsetBusinessCard';
import Flyer from "./Flyer";
import Brochure from "./Brochure";
import './ProductPage.css';
import OffsetBooklet from './OffsetBooklet';
import DigitalBooklet from './DigitalBooklet';
import StoreCredit from './StoreCredit';
import RacingHorseCalendar from './RacingHorseCalendar';
import RacingHorseCalendarInset from './RacingHorseCalendarInset';
import Sticker from './Sticker';
import DigitalBooklet1Color from './DigitalBooklet1Color';

class ProductPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product_name: props.match.params.type
        }
    }

    componentWillMount() {
        if (!this.props.customer) {
            this.props.history.push('/');
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.match.params !== nextProps.match.params) {
            this.setState({ product_name: nextProps.match.params.type });
            console.log({ product_name: nextProps.match.params.type });
        }
    }

    handleProductClick(name, subCat, e) {
        e.stopPropagation();
        e.preventDefault();
        if(subCat != null) {
            this.setState({sub_category: subCat})
        }
        this.props.history.push('/products/' + name);

    }

    switchProduct() {
        switch (this.state.product_name) {
            case 'Digital Business Card':
                return <DigitalBusinessCard history={this.props.history} />

            case 'Offset Business Card':
                return <OffsetBusinessCard history={this.props.history} />

            case 'Flyer':
                return <Flyer history={this.props.history} />

            case 'Brochure':
                return <Brochure history={this.props.history} />

            case '4 Colour Digital Booklet':
                return <DigitalBooklet history={this.props.history} />

            case '1 Colour Digital Booklet':
                return <DigitalBooklet1Color history={this.props.history} />

            case 'Offset Booklet':
                return <OffsetBooklet history={this.props.history} />

            case 'Racing Horse Calendar With Header':
                return <RacingHorseCalendar history={this.props.history} />

            case 'Racing Horse Calendar Without Header':
                return <RacingHorseCalendarInset history={this.props.history} />

            case 'Sticker Label':
                return <Sticker history={this.props.history} />

            case 'Store Credit':
                return <StoreCredit />

            default:
                return null;
        }
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col id="sidebar" xs="3" style={{ textAlign: 'left' }}>
                        <div className="box-category">
                            <ul className="box-category">
                                <li>
                                    <h3 id="product-title"><b>PRODUCTS</b></h3>
                                    <ul className="box-category">
                                        <li>
                                            <Link to="/products/1 Colour Digital Booklet"><h4><b>1 COLOUR DIGITAL BOOKLET</b></h4></Link>
                                        </li>
                                        <li>
                                            <Link to="/products/4 Colour Digital Booklet"><h4><b>4 COLOUR DIGITAL BOOKLET</b></h4></Link>
                                        </li>
                                        <li>
                                            <Link to="/products/Digital Business Card"><h4><b>DIGITAL BUSINESS CARD</b></h4></Link>
                                        </li>
                                        <li>
                                            <Link to="/products/Offset Business Card"><h4><b>OFFSET BUSINESS CARD</b></h4></Link>
                                        </li>
                                        <li>
                                            <Link to="/products/Flyer"><h4><b>FLYER</b></h4></Link>
                                        </li>
                                        <li>
                                            <Link to="/products/Brochure"><h4><b>BROCHURE</b></h4></Link>
                                        </li>
                                        <li>
                                            <Link to="/products/Racing Horse Calendar With Header" className="disabled-link"><h4 style={{color:'gray'}}><b>RACING HORSE CALENDAR WITH HEADER</b></h4><Badge href="#" color="warning">Out Of Stock</Badge></Link>
                                        </li>
                                        <li>
                                            <Link to="/products/Racing Horse Calendar Without Header" className="disabled-link"><h4 style={{color:'gray'}}><b>RACING HORSE CALENDAR WITHOUT HEADER</b></h4><Badge href="#" color="warning">Out Of Stock</Badge></Link>
                                           
                                        </li>
                                        <li>
                                            <Link to="/products/Sticker Label"><h4><b>STICKER LABEL</b></h4></Link>
                                        </li>
                                        {/* <li>
                                            <Link to="/products/Offset Booklet"><h4><b>OFFSET BOOKLET</b></h4></Link>
                                        </li> */}
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col>
                        {
                            this.switchProduct()
                        }
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = ({ main, product }) => {
    return { customer: main.customer, product };
}

export default connect(mapStateToProps)(ProductPage);
