import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
} from 'reactstrap';

import './HowToOrderPage.css';

import iconMouse from './icon-mouse.png';

class HowToOrderPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container className="my-3">
        <Row>
          <Col>
            <h1 className="my-2">HOW TO ORDER</h1>
            <div className="process-link text-center">
              <Link to="/how-we-do">How We Do</Link>
              <Link to="/how-to-top-up-account">How To Top Up Account</Link>
              <Link to="/how-to-order" class="selected">How To Order</Link>
              <Link to="/how-to-count-process-day">How To Count Process Day</Link>
              <Link to="/how-to-join">How To Join</Link>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>1. Choose Spec <img className="img-fluid" src={iconMouse} title="Icon Mouse" alt="iconMouse"/></h2>
            <p>Choose product spec:</p>
            <ul>
              <li>Size</li>
              <li>Material</li>
              <li>Quantity</li>
              <li>Print Colour</li>
              <li>Finishing</li>
            </ul>
          </Col>
          <Col>
            <h2>2. Add To Cart<img className="img-fluid" src={iconMouse} title="Icon Mouse" alt="iconMouse"/></h2>
            <p>&nbsp;</p>
            <ul>
              <li>Upload design file</li>
              <li>Add to cart</li>
              <li>Continue uploading new design file and add to cart if you have multiple job orders.</li>
            </ul>
          </Col>
          <Col>
            <h2>3. Confirm<img className="img-fluid" src={iconMouse} title="Icon Mouse" alt="iconMouse"/></h2>
            <p>&nbsp;</p>
            <ul>
              <li>Check Out your orders at shopping cart. You need to check your order details carefully before confirming the order.</li>
            </ul>
          </Col>
          <Col>
            <h2>4. Print Invoice<img className="img-fluid" src={iconMouse} title="Icon Mouse" alt="iconMouse"/></h2>
            <p>&nbsp;</p>
            <ul>
              <li>After completed the job, print out invoice for your reference.</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>How To Upload Design File</h2>
            <p>
              You must provide us your own design file for every job order. Please always refer to our product guide while preparing your artwork. Kindly check all of your specifications and criteria before uploading file.
            </p>

            <h2>Methods of file upload</h2>
            <ul>
              <li>
                <strong>UTECH Server</strong><br />
                You can upload files to our server when placing your order. There is an artwork file upload interface at our ordering page. Files which are <strong>LESS than 20MB</strong> are recommended.
              </li>
              <li>
                <strong>Dropbox / Google Drive / Sendspace / You Send It / WeTransfer</strong><br />
                There is an URL link field in ordering page which you can paste your URL link. We will download your file from the link given. Files which are <strong>MORE than 20MB</strong> are recommended.
              </li>
            </ul>

            <h2>Shopping Cart</h2>
            <p>After uploading design file, you need to click “Add to Cart” interface. A pop out message stated your order is successfully added to your shopping cart will be appeared on top. To track your order, click on the shopping cart link. Continue shopping if you have multiple job orders. Check Out your orders at shopping cart. You need to check your order details carefully before confirming the orders.</p>

            <h2>Confirm Order</h2>
            <p>After order is confirmed, you are not allowed to cancel the order. However, you can change and cancel the design after submitting your artwork, <strong>ONLY IF</strong> your order is not yet in the printing process. Kindly contact our Customer Service to check the status of your order.</p>

            <h2>Track Order</h2>
            <p>You can track your order status in My Account - View Your Order History.</p>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default HowToOrderPage;
