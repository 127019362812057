import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Alert,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Progress
} from 'reactstrap';
import { uploadFiles, resetUploads, showLoading, showLoginModal } from '../../../actions'
import './CustomQuoteBookletPage.css';
import { serverURI } from '../../../config';

const request = require('superagent');

class CustomQuoteBookletPage extends Component {
  constructor(props) {
    super(props);
    this.product = 'Custom Quote - Booklet';
    this.state = {
      /**
       * Current configuration selected by end-user
       */
      configurations: {
        cover_artwork: null,
        cover_artwork_path: null,
        inner_artwork: null,
        inner_artwork_path: null,
        jpeg_reference: null,
        jpeg_reference_path: null
      },
      isLoadingData: true,
      showOtherInnerMat: false,
      showOtherCoverMat: false,
      requestLogin: false,
      showError: false
    };
    this.otherCoverMatRef = React.createRef();
    this.otherInnerMatRef = React.createRef();
  }

  /**
   * Invoked immediately after a component is mounted (inserted into the tree).
   * Initialization that requires DOM nodes should go here.
   * If you need to load data from a remote endpoint, this is a good place to instantiate the network request.
   */
  componentDidMount() {
    document.title = `U-Tech - ${this.product}`
  }


  /**
   * Invoked immediately after updating occurs.
   * This method is not called for the initial render.
   * Use this as an opportunity to operate on the DOM when the component has been updated.
   * This is also a good place to do network requests as long as you compare the current props to previous props (e.g. a network request may not be necessary if the props have not changed).
   *
   * @param {*} previousProps Previous properties before this completed rendering cycle
   * @param {*} previousState Previous state before this already completed rendering cycle
   */
  componentDidUpdate(previousProps, previousState) {

    if (previousProps.cart.upload_count !== this.props.cart.upload_count) {
      if ((this.props.cart.total_uploads > 0) && (this.props.cart.total_uploads === this.props.cart.upload_count)) {
        let newConfig = this.state.configurations;
        newConfig.cover_artwork = this.props.cart.cover_artwork;
        newConfig.cover_artwork_path = this.props.cart.cover_artwork_path;
        newConfig.inner_artwork = this.props.cart.inner_artwork;
        newConfig.inner_artwork_path = this.props.cart.inner_artwork_path;
        newConfig.jpeg_reference = this.props.cart.jpeg_reference;
        newConfig.jpeg_reference_path = this.props.cart.jpeg_reference_path;
        this.setState({
          configurations: newConfig
        });

      }
    }
  }

  /**
   * Handle file selection event.
   * Acquires token from backend and upload files to AliCloud storage.
   *
   * @param {*} e eventData
   */
  handleFileSelect(type, e) {
    e.stopPropagation();
    e.preventDefault();
    let files = e.target.files;

    console.log(files);
    if (files.length > 0) {
      this.props.uploadFiles(files, type);
    }
  }

  handleRadioClicked(e) {
    if (e.target.name === 'inner-material') {
      if (e.target.value === 'others') {
        this.setState({ showOtherInnerMat: true });
      } else {
        this.setState({ showOtherInnerMat: false });
      }
    } else if (e.target.name === 'cover-material') {
      if (e.target.value === 'others') {
        this.setState({ showOtherCoverMat: true });
      } else {
        this.setState({ showOtherCoverMat: false });
      }
    }
  }

  async handleSubmit(e) {
    const { configurations } = this.state;
    const { customer } = this.props.main;

    e.preventDefault();

    let newConfig = {
      book_closed_size: {
        width: parseInt(e.target.elements['book-closed-size-width'].value),
        height: parseInt(e.target.elements['book-closed-size-height'].value)
      },
      book_opened_size: {
        width: parseInt(e.target.elements['book-open-size-width'].value),
        height: parseInt(e.target.elements['book-open-size-height'].value)
      },
      binding: e.target.elements['book-binding'].value,
      quantity: [parseInt(e.target.elements['quantity-1'].value), parseInt(e.target.elements['quantity-2'].value), parseInt(e.target.elements['quantity-3'].value)],
      cover_material: e.target.elements['other-cover-material'].value,
      other_cover_material: e.target.elements['other-cover-material'].value,
      print: e.target.elements['print-colour'].value,
      inner_material: e.target.elements['inner-material'].value,
      other_inner_material: e.target.elements['other-inner-material'].value,
      pages: parseInt(e.target.elements['inner-pages'].value),
      cover_artwork: configurations.cover_artwork,
      cover_artwork_path: configurations.cover_artwork_path,
      cover_artwork_download_url: e.target.elements['cover_artwork_download_url'].value,
      inner_artwork: configurations.inner_artwork,
      inner_artwork_path: configurations.inner_artwork_path,
      inner_artwork_download_url: e.target.elements['inner_artwork_download_url'].value,
      jpeg_reference: configurations.jpeg_reference,
      jpeg_reference_path: configurations.jpeg_reference_path,
      jpeg_reference_download_url: e.target.elements['jpeg_reference_download_url'].value,
      shipping: e.target.elements['shipping'].value,
      remarks: e.target.elements['remarks'].value
    }

    if (this.checkRequiredConfigurations(newConfig) === false) {
      return;
    }

    if (customer) {
      let quote_request = {
        product: this.product,
        customer: customer,
        configurations: newConfig
      }

      try {
        let resp = await request.post(serverURI + '/v1/request_quote')
          .set('Content-Type', 'application/json')
          .send(quote_request);
        if (resp.status === 200) {
          // this.formRef.reset();
          this.setState({
            configurations: {
              cover_artwork: null,
              cover_artwork_path: null,
              cover_artwork_download_url: null,
              inner_artwork: null,
              inner_artwork_path: null,
              inner_artwork_download_url: null,
              jpeg_reference: null,
              jpeg_reference_path: null,
              jpeg_reference_download_url: null,
            },
            isLoadingData: true,
            showOtherInnerMat: false,
            showOtherCoverMat: false,
            requestLogin: false,
            showError: false
          });
          this.props.showLoading(false);
          this.props.showLoginModal(false);
          this.props.history.push('/acknowledge?title=Booklet Custom Quote');
        }
      } catch (err) {
        console.log(err);
        this.setState({ showError: true });
      }

    } else {
      this.setState({
        requestLogin: true
      });
    }
  }

  /**
   * Check if the required configurations is supplied.
   *
   * @returns True if all required configurations is supplied. False otherwise.
   */
  checkRequiredConfigurations(newConfig) {
    let missingConfigurations = [];

    // Add checks for required configuration here
    if (newConfig['cover_artwork_path'] == null && (
      newConfig['cover_artwork_download_url'] == null ||
      newConfig['cover_artwork_download_url'] == ""
    )) {
      missingConfigurations.push("Cover Artwork");
    }

    if (newConfig['inner_artwork_path'] == null && (
      newConfig['inner_artwork_download_url'] == null ||
      newConfig['inner_artwork_download_url'] == ""
    )) {
      missingConfigurations.push("Inner Artwork");
    }

    if (newConfig['jpeg_reference_path'] == null && (
      newConfig['jpeg_reference_download_url'] == null ||
      newConfig['jpeg_reference_download_url'] == ""
    )) {
      missingConfigurations.push("JPEG for Reference");
    }

    console.log(missingConfigurations);
    if (missingConfigurations.length > 0) {
      window.alert(`Please supply the missing information: ${missingConfigurations.join(", ")}.`);
      return false;
    }

    // everything OK
    return true;
  }

  render() {
    const { requestLogin, showError } = this.state;
    const { upload_started, upload_count, upload_filename, total_uploads } = this.props.cart;
    const { customer } = this.props.main;

    return (
      <Container className="my-3">
        <Modal isOpen={upload_started} className='scrolling'>
          <ModalHeader>File Upload</ModalHeader>
          <ModalBody>
            {
              (total_uploads === 0)
                ? (
                  <div>
                    <span>Contacting server . . . <br /><br /></span>
                    <Progress multi>
                      <Progress bar animated value={10} />
                    </Progress>
                  </div>
                )
                : ((total_uploads < 0)
                  ? (<span>Upload Failed! <br /><br /><br /></span>)
                  : ((upload_count === total_uploads)
                    ? (
                      <div>
                        <span>Upload Completed!<br /><br /></span>
                        <Progress multi>
                          <Progress bar color="success" value={100} > 100% </Progress>
                        </Progress>
                      </div>
                    )
                    : (
                      <div>
                        <span>Uploading <i>{upload_filename}</i><br /><br /></span>
                        <Progress multi>
                          <Progress bar animated value={20} />
                        </Progress>
                      </div>
                    )))
            }
          </ModalBody>
          <ModalFooter>
            <Button color='success' onClick={() => this.props.resetUploads()} disabled={(total_uploads === 0) || (upload_count !== total_uploads)}>Close</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showError}>
          <ModalHeader>{this.product}</ModalHeader>
          <ModalBody>
            Failed to submit request. Please try again.
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => this.setState({ showError: false })}>OK</Button>
          </ModalFooter>
        </Modal>
        <Row>
          <Col>
            <div>
              <h1 className="my-2">Booklet</h1>
              <Form innerRef={(ref) => this.formRef = ref} onSubmit={this.handleSubmit.bind(this)}>
                <h5>Product Specification</h5>
                <FormGroup row>
                  <Label htmlFor="book-open-size" sm={2}><span className="mandatory">*</span>Book Open Size</Label>
                  <Col sm={5}>
                    <InputGroup>
                      <Input id="book-open-size" type="number" name="book-open-size-width" placeholder="width" required />
                      <InputGroupAddon addonType="append">mm</InputGroupAddon>
                    </InputGroup>
                  </Col>
                  <Col sm={5}>
                    <InputGroup>
                      <Input type="number" name="book-open-size-height" placeholder="height" required />
                      <InputGroupAddon addonType="append">mm</InputGroupAddon>
                    </InputGroup>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="book-closed-size" sm={2}><span className="mandatory">*</span>Book Closed Size</Label>
                  <Col sm={5}>
                    <InputGroup>
                      <Input id="book-closed-size" type="number" name="book-closed-size-width" placeholder="width" required />
                      <InputGroupAddon addonType="append">mm</InputGroupAddon>
                    </InputGroup>
                  </Col>
                  <Col sm={5}>
                    <InputGroup>
                      <Input type="number" name="book-closed-size-height" placeholder="height" required />
                      <InputGroupAddon addonType="append">mm</InputGroupAddon>
                    </InputGroup>
                  </Col>
                </FormGroup>
                <FormGroup tag="fieldset" row>
                  <legend className="col-form-label col-sm-2"><span className="mandatory">*</span>Book Binding</legend>
                  <Col sm={10}>
                    <FormGroup check>
                      <Label check>
                        <Input type="radio" name="book-binding" value="Saddle Stitch" required />{' '}
                        Saddle Stitch
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input type="radio" name="book-binding" value="Perfect Binding" />{' '}
                        Perfect Binding
                      </Label>
                    </FormGroup>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="quantity-1" sm={2}><span className="mandatory">*</span>Quantity 1</Label>
                  <Col sm={5}>
                    <InputGroup>
                      <Input id="quantity-1" type="number" name="quantity-1" required />
                      <InputGroupAddon addonType="append">bks</InputGroupAddon>
                    </InputGroup>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="quantity-2" sm={2}>Quantity 2</Label>
                  <Col sm={5}>
                    <InputGroup>
                      <Input id="quantity-2" type="number" name="quantity-2" />
                      <InputGroupAddon addonType="append">bks</InputGroupAddon>
                    </InputGroup>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="quantity-3" sm={2}>Quantity 3</Label>
                  <Col sm={5}>
                    <InputGroup>
                      <Input id="quantity-3" type="number" name="quantity-3" />
                      <InputGroupAddon addonType="append">bks</InputGroupAddon>
                    </InputGroup>
                  </Col>
                </FormGroup>
                <br />
                <h5>Cover</h5>
                <FormGroup tag="fieldset" row onChange={this.handleRadioClicked.bind(this)}>
                  <legend className="col-form-label col-sm-2"><span className="mandatory">*</span>Cover Material</legend>
                  <Col sm={10}>
                    <FormGroup check>
                      <Label check>
                        <Input type="radio" name="cover-material" value="230gsm Art Card" />{' '}
                        230gsm Art Card
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input type="radio" name="cover-material" value="260gsm Art Card" />{' '}
                        260gsm Art Card
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input type="radio" name="cover-material" value="others" />{' '}
                        others
                      </Label>
                    </FormGroup>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label htmlFor="other-cover-material" sm={2}>Other Cover Material</Label>
                  <Col sm={5}>
                    <Input id="other-cover-material" ref={this.otherCoverMatRef} type="text" name="other-cover-material" disabled={!this.state.showOtherCoverMat} required />
                  </Col>
                </FormGroup>

                <FormGroup tag="fieldset" row>
                  <legend className="col-form-label col-sm-2"><span className="mandatory">*</span>Print Colour</legend>
                  <Col sm={10}>
                    <FormGroup check>
                      <Label check>
                        <Input type="radio" name="print-colour" value="4 Colour Outer Only" required />{' '}
                        4 Colour Outer Only
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input type="radio" name="print-colour" value="4 Colour Outer & 4 Colour Inner" />{' '}
                        4 Colour Outer &amp; 4 Colour Inner
                      </Label>
                    </FormGroup>
                  </Col>
                </FormGroup>
                <br />
                <h5>Inner</h5>
                <FormGroup tag="fieldset" row onChange={this.handleRadioClicked.bind(this)}>
                  <legend className="col-form-label col-sm-2"><span className="mandatory">*</span>Inner Material</legend>
                  <Col sm={10}>
                    <FormGroup check>
                      <Label check>
                        <Input type="radio" name="inner-material" value="80gsm Simili Paper" required />{' '}
                        80gsm Simili Paper
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input type="radio" name="inner-material" value="100gsm Simili Paper" />{' '}
                        100gsm Simili Paper
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input type="radio" name="inner-material" value="100gsm Gloss Art Paper" />{' '}
                        100gsm Gloss Art Paper
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input type="radio" name="inner-material" value="128gsm Gloss Art Paper" />{' '}
                        128gsm Gloss Art Paper
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input type="radio" name="inner-material" value="157gsm Gloss Art Paper" />{' '}
                        157gsm Gloss Art Paper
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input type="radio" name="inner-material" value="230gsm Art Card" />{' '}
                        230gsm Art Card
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input type="radio" name="inner-material" value="260gsm Art Card" />{' '}
                        260gsm Art Card
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input type="radio" name="inner-material" value="others" />{' '}
                        others
                      </Label>
                    </FormGroup>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="other-inner-material" sm={2}>Other Inner Material</Label>
                  <Col sm={5}>
                    <Input id="other-inner-material" ref={this.otherInnerMatRef} type="text" name="other-inner-material" disabled={!this.state.showOtherInnerMat} required />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="inner-pages" sm={2}><span className="mandatory">*</span>Inner Pages</Label>
                  <Col sm={5}>
                    <Input id="inner-pages" type="text" name="inner-pages" required />
                    <p className="form-notes">
                      * Saddle Stitching maximum pages 64pgs. <br />
                      * Perfect Binding minimum 48pgs.
                    </p>
                  </Col>
                </FormGroup>
                <br/>
                <FormGroup row>
                  <Label htmlFor="custom-booklet-cover" sm={2}><span className="mandatory">*</span>Cover Artwork</Label>
                  <Col sm={5}>
                    <Input type="file" name='custom-booklet-cover' id='custom-booklet-cover' onChange={this.handleFileSelect.bind(this, 'custom_booklet_cover')} accept='.pdf, .rar, .zip' />
                    <p className="form-notes">
                      Accepted upload file format: .pdf, .rar, .zip
                    </p>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="cover-artwork-url" sm={2}>Cover Artwork URL</Label>
                  <Col sm={5}>
                      <Input id="cover-artwork-url" type="text" name="cover_artwork_download_url" placeholder="ARTWORK DOWNLOAD URL" style={{ textTransform: 'none' }}/>
                  </Col>
                </FormGroup>
                <br/>
                <FormGroup row>
                  <Label htmlFor="custom-booklet-inner" sm={2}><span className="mandatory">*</span>Inner Artwork</Label>
                  <Col sm={5}>
                    <Input type="file" name='custom-booklet-inner' id='custom-booklet-inner' onChange={this.handleFileSelect.bind(this, 'custom_booklet_inner')} accept='.pdf, .rar, .zip' />
                    <p className="form-notes">
                      Accepted upload file format: .pdf, .rar, .zip
                    </p>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="inner-artwork-url" sm={2}>Inner Artwork URL</Label>
                  <Col sm={5}>
                      <Input id="inner-artwork-url" type="text" name="inner_artwork_download_url" placeholder="ARTWORK DOWNLOAD URL" style={{ textTransform: 'none' }}/>
                  </Col>
                </FormGroup>
                <br/>
                <FormGroup row>
                  <Label htmlFor="custom-booklet-jpeg_ref" sm={2}><span className="mandatory">*</span>JPEG for Reference</Label>
                  <Col sm={5}>
                    <Input type="file" name='custom-booklet-jpeg_ref' id='custom-booklet-jpeg_ref' onChange={this.handleFileSelect.bind(this, 'custom_booklet_jpeg_ref')} accept='.pdf, .rar, .zip' />
                    <p className="form-notes">
                      Accepted upload file format: .pdf, .rar, .zip
                    </p>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="jpeg_reference_download_url" sm={2}>JPEG for Reference URL</Label>
                  <Col sm={5}>
                      <Input id="jpeg_reference_download_url" type="text" name="jpeg_reference_download_url" placeholder="ARTWORK DOWNLOAD URL" style={{ textTransform: 'none' }}/>
                  </Col>
                </FormGroup>
                <FormGroup tag="fieldset" row>
                  <legend className="col-form-label col-sm-2"><span className="mandatory">*</span>Require Shipping</legend>
                  <Col sm={10}>
                    <FormGroup check>
                      <Label check>
                        <Input type="radio" name="shipping" value="Self Collect" required />{' '}
                        Self Collect
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input type="radio" name="shipping" value="Courier Service" />{' '}
                        Courier Service
                      </Label>
                    </FormGroup>
                  </Col>
                </FormGroup>
                <FormGroup tag="fieldset" row>
                  <Label htmlFor="remarks" sm={2}><span className="mandatory">*</span>Remarks</Label>
                  <Col sm={10}>
                    <Input type="textarea" name="remarks" id="remarks" required /> <br />
                    Thank you for your enquiry. We will get back to you within 2 working days.
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Col sm={12}>
                    <Button>Submit</Button>
                  </Col>
                </FormGroup>
              </Form>
              {requestLogin && (customer === null) &&
                <Alert color="warning">
                  You need to login to submit custom quote. Click <a style={{ cursor: 'pointer' }} onClick={() => this.props.showLoginModal(true)}><b>HERE</b></a> to login.
                </Alert>
              }
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ main, cart }) => {
  return { main, cart };
}

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators({
    uploadFiles: uploadFiles,
    resetUploads: resetUploads,
    showLoading: showLoading,
    showLoginModal: showLoginModal
  }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(CustomQuoteBookletPage);
