import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Container, Row, Col, Table, Popover, PopoverBody, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withAlert } from 'react-alert';
import moment from 'moment';
import MediaQuery from 'react-responsive';
import MaskedInput from 'react-text-mask';
import { fetchShippingInfo, updateCustomerProfile, fetchUserRecords } from '../../../actions';
import Invoice from '../Invoice/Invoice';
import './Account.css'
import './MyAccount.css';

class MyAccount extends Component {
    state = {
        context: 'my_profile',
        profile_changed: false,
        popoverOpen: false,
        popoverTarget: 'profile_phone',
        popoverText: '',
        selected_order: null,
        showModal: false,
    }

    constructor(props) {
        super(props);

        this.renderOrders = this.renderOrders.bind(this);
        this.renderProfile = this.renderProfile.bind(this);
        if (this.props.customer) this.props.fetchUserRecords(this.props.customer._id);
    }

    componentWillMount() {
        const {customer} = this.props;

        if (customer) {
            this.props.fetchShippingInfo([customer.address.country]);
            this.setState({
                profile_name: customer.name,
                profile_company: customer.company,
                profile_address_1: customer.address.address_1,
                profile_address_2: customer.address.address_2,
                profile_town_city: customer.address.town_city,
                profile_postcode: customer.address.postcode,
                profile_state: customer.address.state,
                profile_country: customer.address.country,
                profile_mobile: customer.mobile,
                profile_phone: customer.phone,
                profile_email: customer.email,
                profile_balance: (customer.balance) ? customer.balance.value : 0.0
            });
            // this.props.fetchUserRecords(customer._id);
        } else {
            this.setState({
                profile_name: '',
                profile_company: '',
                profile_address_1: '',
                profile_address_2: '',
                profile_town_city: '',
                profile_postcode: '',
                profile_state: 'State',
                profile_country: 'Malaysia',
                profile_mobile: '',
                profile_phone: '',
                profile_email: '',
                profile_balance: 0.0
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.customer && nextProps.customer) {
            if ((this.props.customer !== nextProps.customer) && this.state.profile_changed) {
                console.log(nextProps.customer);
                const alert = this.props.alert.show('Profile saved successfully', {
                    timeout: 3000,
                    type: 'success',
                });
                this.setState({
                    profile_name: nextProps.customer.name,
                    profile_company: nextProps.customer.company,
                    profile_address_1: nextProps.customer.address.address_1,
                    profile_address_2: nextProps.customer.address.address_2,
                    profile_town_city: nextProps.customer.address.town_city,
                    profile_postcode: nextProps.customer.address.postcode,
                    profile_state: nextProps.customer.address.state,
                    profile_country: nextProps.customer.address.country,
                    profile_mobile: nextProps.customer.mobile,
                    profile_phone: nextProps.customer.phone,
                    profile_email: nextProps.customer.email,
                    profile_balance: (nextProps.customer.balance) ? nextProps.customer.balance.value : 0.0,
                    profile_changed: false
                });
            }
        }
    }

    handleContextSwitch(newContext, e) {
        e.stopPropagation();
        e.preventDefault();
        this.props.fetchUserRecords(this.props.customer._id);
        this.setState({ context: newContext, selected_order: null });
    }

    handleViewOrder(order, e) {
        e.stopPropagation();
        e.preventDefault();
        console.log(order);

        this.setState({ selected_order: order, showModal: true });
    }

    handleInput(e) {
        this.setState({ [e.target.name]: e.target.value, profile_changed: true });
    }

    handleBillingStateChange(e) {
        if (e.target.value === 'State') {
            e.target.style.color = '#999';
            e.target.classList.remove('is-invalid');
        } else {
            e.target.style.color = '#495057';
        }
        this.setState({ profile_state: e.target.value, profile_changed: true });
    }

    resetInputStyle(e) {
        e.target.classList.remove('is-invalid');
        this.setState({
            popoverOpen: false
        });
    }

    showMobileHelpText(e) {
        this.setState({
            popoverText: 'Mobile number starts with area code (eg. 01XXXXXXXX for mobile)',
            popoverTarget: e.target.id,
            popoverOpen: true
        });
    }

    showPhoneHelpText(e) {
        this.setState({
            popoverText: 'Phone number starts with area code (eg. 0XXXXXXXX for fixed line)',
            popoverTarget: e.target.id,
            popoverOpen: true
        });
    }

    closePopover() {
        this.setState({
            popoverOpen: false
        });
    }

    resetProfile() {
        const {customer} = this.props;

        if (customer) {
            this.setState({
                profile_name: customer.name,
                profile_company: customer.company,
                profile_address_1: customer.address.address_1,
                profile_address_2: customer.address.address_2,
                profile_town_city: customer.address.town_city,
                profile_postcode: customer.address.postcode,
                profile_state: customer.address.state,
                profile_country: customer.address.country,
                profile_mobile: customer.mobile,
                profile_phone: customer.phone,
                profile_email: customer.email,
                profile_balance: (customer.balance) ? customer.balance.value : 0.0,
                profile_changed: false
            });
        } else {
            this.setState({
                profile_name: '',
                profile_company: '',
                profile_address_1: '',
                profile_address_2: '',
                profile_town_city: '',
                profile_postcode: '',
                profile_state: 'State',
                profile_country: 'Malaysia',
                profile_mobile: '',
                profile_phone: '',
                profile_email: '',
                profile_balance: 0.0,
                profile_changed: false
            });
        }
    }

    saveProfile(e) {
        e.preventDefault();
        this.closePopover();

        // Perform form validation
        if (e.target.elements['profile_name'].value === '') {
            e.target.elements['profile_name'].classList.add('is-invalid');
            e.target.elements['profile_name'].focus();
            this.setState({
                popoverText: 'Name is required!',
                popoverTarget: 'profile_name',
                popoverOpen: true
            });
        } else if (e.target.elements['profile_company'].value === '') {
            e.target.elements['profile_company'].classList.add('is-invalid');
            e.target.elements['profile_company'].focus();
            this.setState({
                popoverText: 'Company name is required!',
                popoverTarget: 'profile_company',
                popoverOpen: true
            });
        } else if (e.target.elements['profile_address_1'].value === '') {
            e.target.elements['profile_address_1'].classList.add('is-invalid');
            e.target.elements['profile_address_1'].focus();
            this.setState({
                popoverText: 'Billing address is required!',
                popoverTarget: 'profile_address_1',
                popoverOpen: true
            });
        } else if (e.target.elements['profile_town_city'].value === '') {
            e.target.elements['profile_town_city'].classList.add('is-invalid');
            e.target.elements['profile_town_city'].focus();
            this.setState({
                popoverText: 'Town / City is required!',
                popoverTarget: 'profile_town_city',
                popoverOpen: true
            });
        } else if (e.target.elements['profile_postcode'].value === '') {
            e.target.elements['profile_postcode'].classList.add('is-invalid');
            e.target.elements['profile_postcode'].focus();
            this.setState({
                popoverText: 'Postal code is required!',
                popoverTarget: 'profile_postcode',
                popoverOpen: true
            });
        } else if (isNaN(e.target.elements['profile_postcode'].value) || (e.target.elements['profile_postcode'].value.length < 5)) {
            e.target.elements['profile_postcode'].classList.add('is-invalid');
            e.target.elements['profile_postcode'].focus();
            this.setState({
                popoverText: 'Postal code must be a 5 digit number!',
                popoverTarget: 'profile_postcode',
                popoverOpen: true
            });
        } else if (e.target.elements['profile_state'].value === 'State') {
            e.target.elements['profile_state'].classList.add('is-invalid');
            e.target.elements['profile_state'].focus();
            this.setState({
                popoverText: 'Please select valid state!',
                popoverTarget: 'profile_state',
                popoverOpen: true
            });
        } else if (e.target.elements['profile_mobile'].value.length === 0) {
            e.target.elements['profile_mobile'].classList.add('is-invalid');
            e.target.elements['profile_mobile'].focus();
            this.setState({
                popoverText: 'Mobile number is required!',
                popoverTarget: 'profile_mobile',
                popoverOpen: true
            });
        } else if (e.target.elements['profile_email'].value.length < 9) {
            e.target.elements['profile_email'].classList.add('is-invalid');
            e.target.elements['profile_email'].focus();
            this.setState({
                popoverText: 'Email address is required!',
                popoverTarget: 'profile_email',
                popoverOpen: true
            });
        } else {
            console.log('Validation completed successfully');
            // Submit order to backend
            let customer = {
                    _id: this.props.customer._id,
                    name: e.target.elements['profile_name'].value.trim().toUpperCase(),
                    company: e.target.elements['profile_company'].value.trim().toUpperCase(),
                    address: {
                        address_1: e.target.elements['profile_address_1'].value.trim().toUpperCase(),
                        address_2: e.target.elements['profile_address_2'].value.trim().toUpperCase(),
                        town_city: e.target.elements['profile_town_city'].value.trim().toUpperCase(),
                        postcode: e.target.elements['profile_postcode'].value.trim().toUpperCase(),
                        state: e.target.elements['profile_state'].value.trim().toUpperCase(),
                        country: e.target.elements['profile_country'].value.trim().toUpperCase()
                    },
                    mobile: e.target.elements['profile_mobile'].value.trim(),
                    phone: e.target.elements['profile_phone'].value.trim(),
                    email: e.target.elements['profile_email'].value.trim().toLowerCase(),
                    status: this.props.customer.status
            }
            this.props.updateCustomerProfile(customer);
        }
    }

    // showModal() {
    //     this.setState({ showModal: true });
    // }

    closeModal() {
        this.setState({ showModal: false });
    }

    toFullAddress(addressModal) {
        let fullAddressArray = [
            addressModal.address_1,
            addressModal.address_2,
            addressModal.town_city,
            addressModal.state,
            addressModal.country,
            addressModal.postcode
        ];
        fullAddressArray = fullAddressArray.filter(x => x.trim() !== '');
        return fullAddressArray.join(', ');
    }

    /**
     * Generate item's configuration table to be placed inside the cart table
     *
     * @param {Object} item Product item in the cart system
     */
    generateItemConfigurationTable(item) {
        return (
            <table className="table table-bordered table-sm table-striped">
                <tbody>
                    {
                        Object.keys(item.configurations).map(key => {
                            let value = item.configurations[key];
                            if(key === 'size') {
                                value = `${value.height} mm x ${value.width} mm`;
                            }

                            return (
                                <tr key={key}>
                                    <td>{key}</td>
                                    <td>{value}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        )
    }

    renderProfile() {
        const { profile_changed } = this.state;
        const { checkout } = this.props;
        console.log(this.state);

        return (
            <div style={{paddingTop:'5px'}}>
                {/* <h2 style={{paddingBottom:'15px'}}>My Profile</h2> */}
                <Form onSubmit={this.saveProfile.bind(this)}>
                    <Popover placement='bottom' isOpen={this.state.popoverOpen} target={this.state.popoverTarget} toggle={this.closePopover.bind(this)}>
                        <PopoverBody>{this.state.popoverText}</PopoverBody>
                    </Popover>
                    <FormGroup row>
                        <Label for="profile_name" sm={2}>Name</Label>
                        <Col sm={10}>
                            <Input type="text" id="profile_name" name="profile_name" placeholder="Name" value={this.state.profile_name} onChange={this.handleInput.bind(this)} onBlur={this.resetInputStyle.bind(this)}/>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="profile_company" sm={2}>Company</Label>
                        <Col sm={10}>
                            <Input type="text" id="profile_company" name="profile_company" placeholder="Company" value={this.state.profile_company} onChange={this.handleInput.bind(this)} onBlur={this.resetInputStyle.bind(this)}/>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="profile_address_1" sm={2}>Address</Label>
                        <Col sm={10}>
                            <Input type="text" id="profile_address_1" name="profile_address_1" placeholder="Address Line 1" value={this.state.profile_address_1} onChange={this.handleInput.bind(this)} onBlur={this.resetInputStyle.bind(this)}/>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="profile_address_2" sm={2}></Label>
                        <Col sm={10}>
                            <Input type="text" id="profile_address_2" name="profile_address_2" placeholder="Address Line 2" value={this.state.profile_address_2} onChange={this.handleInput.bind(this)} onBlur={this.resetInputStyle.bind(this)}/>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="profile_town_city" sm={2}>Town / City</Label>
                        <Col sm={10}>
                            <Input type="text" id="profile_town_city" name="profile_town_city" placeholder="Town / City" value={this.state.profile_town_city} onChange={this.handleInput.bind(this)} onBlur={this.resetInputStyle.bind(this)}/>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="profile_postcode" sm={2}>Postal Code</Label>
                        <Col sm={5}>
                            <MaskedInput
                                mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                                className="form-control"
                                placeholder="Postal Code"
                                placeholderChar="X"
                                guide={true}
                                id="profile_postcode"
                                name="profile_postcode"
                                value={this.state.profile_postcode}
                                onChange={this.handleInput.bind(this)}
                                onBlur={this.resetInputStyle.bind(this)}
                            />
                        </Col>
                        <Col sm={5}>
                            <Label for="profile_state" hidden>State</Label>
                            <Input type="select" className="state_select" id="profile_state" name="profile_state" value={this.state.profile_state} placeholder="State" onChange={this.handleBillingStateChange.bind(this)} onBlur={this.resetInputStyle.bind(this)}>
                                <option>State</option>
                                {  (checkout.shipping_rates.length > 0) &&
                                checkout.shipping_rates.find((m) => m._id === this.state.profile_country).rates.map((n) => <option key={n.state}>{n.state}</option>)
                                }
                            </Input>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="profile_country" sm={2}>Country</Label>
                        <Col sm={10}>
                            <Input type="text" id="profile_country" name="profile_country" placeholder="Country" value={this.state.profile_country} disabled />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="profile_mobile" sm={2}>Mobile</Label>
                        <Col sm={10}>
                            <MaskedInput
                                mask={[/0/,/[1-9]/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/]}
                                className="form-control"
                                placeholder="Mobile Number"
                                guide={false}
                                id="profile_mobile"
                                name="profile_mobile"
                                value={this.state.profile_mobile}
                                onChange={this.handleInput.bind(this)}
                                onInput={this.showMobileHelpText.bind(this)}
                                onBlur={this.resetInputStyle.bind(this)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="profile_phone" sm={2}>Phone</Label>
                        <Col sm={10}>
                            <MaskedInput
                                mask={[/0/,/[1-9]/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/]}
                                className="form-control"
                                placeholder="Phone Number"
                                guide={false}
                                id="profile_phone"
                                name="profile_phone"
                                value={this.state.profile_phone}
                                onChange={this.handleInput.bind(this)}
                                onInput={this.showPhoneHelpText.bind(this)}
                                onBlur={this.resetInputStyle.bind(this)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="profile_email" sm={2}>Email</Label>
                        <Col sm={10}>
                            <Input type="profile_email" id="profile_email" name="profile_email" placeholder="EMAIL ADDRESS" value={this.state.profile_email} style={{textTransform: 'lowercase'}} disabled={this.state.profile_email.length>0}/>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="profile_balance" sm={2}>Balance</Label>
                        <Col sm={10}>
                            <Input type="profile_balance" id="profile_balance" name="profile_balance" placeholder="0.00" value={this.state.profile_balance.toFixed(2)} readOnly={true}/>
                        </Col>
                    </FormGroup>
                    {/* <button type="submit" className="button" color="primary" style={{ marginTop: '10px', marginBottom: '10px', float: 'left' }}>RESET PASSWORD</button> */}
                    <button type="submit" className={profile_changed ? "button" : "button disabled"} style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '2px', marginRight: '2px', float: 'right' }} disabled={!profile_changed}>SAVE</button>
                    <button className={profile_changed ? "button" : "button disabled"} style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '2px', marginRight: '2px', float: 'right' }} onClick={this.resetProfile.bind(this)} disabled={!profile_changed}>RESET</button>
                </Form>
            </div>
        )
    }

    renderOrders() {
        const { records } = this.props;
        const { selected_order } = this.state;

        return (
            <div style={{paddingTop:'5px'}}>
                {/* <h2 style={{paddingBottom:'15px'}}>My Orders</h2> */}
                <Table responsive hover style={{cursor: 'pointer'}}>
                    <thead>
                        <tr>
                            <th>Order #</th>
                            <th>Ordered On</th>
                            <th>Total</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        records.map((record) => {
                            return (
                                <tr key={record._id} style={{paddingTop:'25px', paddingBottom:'25px'}} onClick={this.handleViewOrder.bind(this, record)}>
                                    <td>{record._id}</td>
                                    <td>{moment(record.order_date).format('DD/MM/YYYY HH:mm')}</td>
                                    <td>RM {record.order_details.total_amount.toFixed(2)}</td>
                                    <td>{record.status}</td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </Table>
            </div>
        )
    }

    render() {
        const { context, selected_order } = this.state;
        let view;

        switch(context) {
            case 'my_orders':
                view = this.renderOrders;
                break;

            case 'my_profile':
                view = this.renderProfile;
                break;

            default:
                break;
        }

        return (
            <Container className="account-container">
                { selected_order &&
                    <Modal isOpen={this.state.showModal} toggle={this.closeModal.bind(this)} style={{ maxWidth: "90vw" }}>
                        <ModalBody>
                            <Invoice order={selected_order} />
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.closeModal.bind(this)}>Close</Button>
                        </ModalFooter>
                    </Modal>
                }
                <Row>
                   <Col id="sidebar" xs="3" style={{ textAlign: 'left' }}>
                        <div className="box-category">
                            <ul className="box-category">
                                <li>
                                    <h3 id="product-title"><b>MY ACCOUNT</b></h3>
                                    <ul className="box-category">
                                        <li>
                                            <h4><b><a className={context==='my_profile'?'active':''} onClick={this.handleContextSwitch.bind(this, 'my_profile')}>USER PROFILE</a></b></h4>
                                        </li>
                                        <li>
                                            <h4><b><a className={context==='my_orders'?'active':''} onClick={this.handleContextSwitch.bind(this, 'my_orders')}>ORDER HISTORY</a></b></h4>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col>
                        {
                            view()
                        }
                    </Col>
                 </Row>
            </Container>
        );
    }
}

const mapStateToProps = ({ checkout, main }) => {
    return { checkout, customer: main.customer, records: main.records };
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchShippingInfo: fetchShippingInfo,
        updateCustomerProfile: updateCustomerProfile,
        fetchUserRecords: fetchUserRecords
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withAlert(MyAccount));