import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAlert } from 'react-alert';
import {
    Button,
    Row, Col,
    Popover, PopoverBody,
    Form, FormGroup, Label, Input, FormText,
    Card, CardDeck, CardBody, CardFooter, CardColumns,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faFacebookF from '@fortawesome/fontawesome-free-brands/faFacebookF';
import faGoogle from '@fortawesome/fontawesome-free-brands/faGoogle';
import { showLoginModal, showLoading, registerCustomer } from '../../../actions';
import { serverURI } from '../../../config';
import * as firebase from 'firebase';

const request = require('superagent');

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            popoverOpen: false,
            popoverTarget: 'login_email',
            popoverText: '',
            loading: false
        }
    }

    resetInputStyle(e) {
        e.target.classList.remove('is-invalid');
        this.setState({
            popoverOpen: false
        });
    }

    closePopover() {
        this.setState({
            popoverOpen: false
        });
    }

    closeLoginModal(e) {
        e.preventDefault();
        this.props.showLoginModal(false);
    }

    authErrorToMsg(err_code) {
        switch (err_code) {
            case 'auth/invalid-email':
                return 'Invalid email address';

            case 'auth/email-already-in-use':
                return 'Account already existed';

            case 'auth/operation-not-allowed':
                return 'Operation not allowed';

            case 'auth/user-disabled':
                return 'Acccount disabled, please contact administrator';

            case 'auth/user-not-found':
                return 'Account not found, please register an account';

            case 'auth/wrong-password':
                return 'Wrong password';

            case 'auth/weak-password':
                return 'Weak password';

            case 'auth/account-exists-with-different-credential':
                return 'Same email address has been used';

            default:
                return 'No error code';
        }
    }

    handleRegister(e) {
        e.preventDefault();
        window.location.href = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/sign-up';
    }

    handleLogin(login, e) {
        e.preventDefault();
        require('formdata-polyfill');
        let formData = new FormData(this.formRef);
        if (!this.formRef.elements['login_email'].value.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
            this.formRef.elements['login_email'].classList.add('is-invalid');
            this.formRef.elements['login_email'].focus();
            this.setState({
                popoverText: 'Invalid email format!',
                popoverTarget: 'login_email',
                popoverOpen: true
            });
        } else if (!this.formRef.elements['login_password'].value.match(/.*^(?=.{6,})(?=.*[a-zA-Z])(?!.*\s).*$/)) {
            this.formRef.elements['login_password'].classList.add('is-invalid');
            this.formRef.elements['login_password'].focus();
            this.setState({
                popoverText: 'Password MUST be at least 6 alphanumeric characters!',
                popoverTarget: 'login_password',
                popoverOpen: true
            });
        } else {
            let userData = Array.from(formData.values());
            this.props.showLoading(true);
            if (login) {
                this.props.firebaseApp.auth().signInWithEmailAndPassword(userData[0], userData[1])
                    .then((userCredential) => {
                        if (this.props.successHandle) {
                            console.log('Login execute success handle');
                            this.props.successHandle({ _id: userCredential.uid, provider: userCredential.providerData[0].providerId, email: userCredential.email });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        this.props.showLoading(false);
                        const alert = this.props.alert.show('Login failed! ' + this.authErrorToMsg(err.code), {
                            timeout: 3000,
                            type: 'error',
                        });
                    });
            } else {
                let actionCodeSettings = {
                    // URL you want to redirect back to. The domain (www.example.com) for this
                    // URL must be whitelisted in the Firebase Console.
                    url: window.location.href,
                    // This must be true.
                    handleCodeInApp: true,
                    // iOS: {
                    //   bundleId: 'com.example.ios'
                    // },
                    // android: {
                    //   packageName: 'com.example.android',
                    //   installApp: true,
                    //   minimumVersion: '12'
                    // },
                    // dynamicLinkDomain: 'example.page.link'
                };

                this.props.firebaseApp.auth().sendSignInLinkToEmail(userData[0], actionCodeSettings)
                    .then(() => {
                        // The link was successfully sent. Inform the user.
                        // Save the email locally so you don't need to ask the user for it again
                        // if they open the link on the same device.
                        window.localStorage.setItem('emailForSignIn', userData[0]);
                    })
                    .catch((err) => {
                        console.log(err);
                        this.props.showLoading(false);
                        const alert = this.props.alert.show('Register failed! ' + this.authErrorToMsg(err.code), {
                            timeout: 3000,
                            type: 'error',
                        });
                    });

                //     this.props.firebaseApp.auth().createUserWithEmailAndPassword(userData[0], userData[1])
                //     .then((userCredential) => {
                //         if (this.props.successHandle) {
                //             console.log('Register execute success handle');
                //             this.props.successHandle({ _id: userCredential.uid, provider: userCredential.providerData[0].providerId, email: userCredential.email });
                //         }
                //     })
                //     .catch((err) => {
                //         console.log(err);
                //         this.props.showLoading(false);
                //         const alert = this.props.alert.show('Register failed! ' + this.authErrorToMsg(err.code), {
                //             timeout: 3000,
                //             type: 'error',
                //         });
                //     });
            }
        }
    }

    handleFacebookLogin() {
        console.log('Signing into Facebook');
        let provider = new firebase.auth.FacebookAuthProvider();
        this.props.showLoading(true);
        firebase.auth().signInWithPopup(provider).then((result) => {
            let user = result.user;
            console.log('Facebook signin successful');
        }).catch((error) => {
            console.log('Facebook signin error: ' + error);

            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;
            // ...
            // if (error.code === 'auth/account-exists-with-different-credential') {
            //     firebase.auth.currentUser.linkWithPopup(provider).then(function(result) {
            //         // Accounts successfully linked.
            //         var credential = result.credential;
            //         var user = result.user;
            //         console.log('Accounts linking successful!');
            //         // ...
            //       }).catch(function(error) {
            //         // Handle Errors here.
            //         // ...
            //         console.log('Accounts linking failed!');
            //       });
            // }
            this.props.showLoading(false);
            const alert = this.props.alert.show('Login failed! ' + this.authErrorToMsg(error.code), {
                timeout: 3000,
                type: 'error'
            });
        });
    }

    handleGoogleLogin() {
        console.log('Signing into Google');
        let provider = new firebase.auth.GoogleAuthProvider();
        this.props.showLoading(true);
        firebase.auth().signInWithPopup(provider).then((result) => {
            let user = result.user;
            console.log('Google signin successful');
            this.props.showLoading(false);
        }).catch((error) => {
            console.log('Google signin error: ' + error);

            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;
            // ...
            // if (error.code === 'auth/account-exists-with-different-credential') {
            //     firebase.auth.currentUser.linkWithPopup(provider).then(function(result) {
            //         // Accounts successfully linked.
            //         var credential = result.credential;
            //         var user = result.user;
            //         // ...
            //       }).catch(function(error) {
            //         // Handle Errors here.
            //         // ...
            //       });
            // }
            this.props.showLoading(false);
            const alert = this.props.alert.show('Login failed! ' + this.authErrorToMsg(error.code), {
                timeout: 3000,
                type: 'error'
            });
        });
    }

    handleResetPassword(e) {
        e.preventDefault();
        // this.props.history.push('/reset-password');
        window.location.href = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/reset-password';
    }

    render() {
        const { order, isOpen } = this.props;

        return (
            <Modal isOpen={isOpen}>
                <a href="#" className="closeIcon" onClick={this.closeLoginModal.bind(this)}>&times;</a>
                <ModalHeader>Login</ModalHeader>
                <ModalBody>
                    <Popover placement='top' isOpen={this.state.popoverOpen} target={this.state.popoverTarget} toggle={this.closePopover.bind(this)}>
                        <PopoverBody>{this.state.popoverText}</PopoverBody>
                    </Popover>
                    <Form innerRef={(ref) => this.formRef = ref}>
                        <Card>
                            <CardBody>
                                <FormGroup row>
                                    <Col>
                                        <Label for="login_email" hidden>Email</Label>
                                        <Input type="email" id="login_email" name="login_email" placeholder="Email" defaultValue={(order) ? order.customer.email : null} onBlur={this.resetInputStyle.bind(this)} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col>
                                        <Label for="login_password" hidden>Password</Label>
                                        <Input type="password" id="login_password" name="login_password" placeholder="Password" onBlur={this.resetInputStyle.bind(this)} />
                                    </Col>
                                </FormGroup>
                                <Row>
                                    <Col></Col>
                                    <Col xs="auto">
                                        <button className="button login" onClick={this.handleLogin.bind(this, true)}>Login</button>
                                        <button className="button login" onClick={this.handleRegister.bind(this)}>Sign Up</button>
                                    </Col>
                                    <Col></Col>
                                </Row>
                                <Row>
                                    <Col></Col>
                                    <Col xs="auto" id="forgotPassword">
                                        <a href='#' onClick={this.handleResetPassword.bind(this)}>Forgot Password?</a>
                                    </Col>
                                    <Col></Col>
                                </Row>
                                <p className="hrBreak">OR</p>
                                {/* <FormGroup row className="loginButtons">
                                    <Button color="" className="facebookBtn" onClick={this.handleFacebookLogin.bind(this)}><FontAwesomeIcon icon={faFacebookF} />&nbsp;&nbsp;Login with Facebook</Button>
                                </FormGroup> */}
                                <FormGroup row className="loginButtons">
                                    <Button color="" className="googleBtn" onClick={this.handleGoogleLogin.bind(this)}><FontAwesomeIcon icon={faGoogle} />&nbsp;&nbsp;Sign in with Google</Button>
                                </FormGroup>
                            </CardBody>
                        </Card>
                    </Form>
                </ModalBody>
            </Modal>
        )
    }
}

const mapStateToProps = ({ main, cart, checkout }) => {
    const { customer, loading, firebaseApp } = main;
    const { order } = checkout;
    return { customer, cart, loading, firebaseApp, order };
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        showLoginModal: showLoginModal,
        showLoading: showLoading,
        registerCustomer: registerCustomer
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withAlert(Login));
