import React, { Component } from 'react';
import './ball-pulse.css';

/**
 * Loading indicator
 * 
 */
export default class LoadingIndicator extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        return (
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '3rem', marginBottom: '3rem'}}>
                <div style={{display: 'flex', flexDirection: 'column', alignContent: 'center'}}>
                    <div>Loading</div>
                    <div className='la-ball-pulse' style={{ color: 'red' }}>
                        {/* Put n divs if you need n indicators */}
                        <div />
                        <div />
                        <div />
                    </div>
                </div>
            </div>
        )
    }
}