import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText
} from 'reactstrap';
import './ContactFormPage.css';
import { serverURI } from '../../../config';

const request = require('superagent');

class ContactFormPage extends Component {
  async handleSubmit(e) {
    e.preventDefault();
    let enquiry = {
      name: e.target.elements['name'].value,
      email: e.target.elements['email'].value,
      message: e.target.elements['message'].value
    }

    try {
      let resp = await request.post(serverURI + '/v1/submit_enquiry')
          .set('Content-Type', 'application/json')
          .send(enquiry);
      if (resp.status === 200) {
          this.formRef.reset();
          this.props.history.push('/acknowledge?title=enquiry');
      }
    } catch (err) {
        console.log(err);
        // this.setState({ showError: true });
    }
  }

  render() {
    const { customer } = this.props.main;

    return (
      <div>
        <div class="map-outer">
          <div class="gmap-canvas">
            <iframe width="100%" height="500" id="gmap_canvas"
              src="https://maps.google.com/maps?q=Percetakan%20U-tech%20Sdn%20Bhd%2C%207%2C%20Solok%20Sungai%20Pinang%204%2C%20Georgetown%2C%2011600%20sungai%20pinang%2C%20Penang&t=&z=16&ie=UTF8&iwloc=&output=embed"
              frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
            </iframe>
          </div>
        </div>
        <Container className="my-3">
          <Row>
            <Col>
              <h1 className="page-title my-2">Contact Us</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Our Location</h3>
              <p>
                <strong>Address:</strong><br/>
                U-Tech Enterprise<br/>
                No. 7, Solok Sungai Pinang 4,<br/>
                11600 Sungai Pinang,<br/>
                Penang, Malaysia.<br/>
                Service Category: Business Services<br/>
                (GPS: N 5°23'59.3952" E100°19'28.4340)
              </p>
            </Col>
            <Col>
              <h3>Customer Service</h3>
              <p>
                <strong>Telephone:</strong><br/>
                604-502 3256
              </p>
              {/* <p>
                <strong>Fax:</strong><br/>
                604-281 5207
              </p> */}
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Business Hours</h3>
              <p>
                Monday – Friday: 9.00am – 5.00pm<br/>
                Closed on Weekend &amp; Public Holiday
              </p>
            </Col>
            <Col>
              <h3>Self Pick Up Hours</h3>
              <p>
                Monday – Friday: 9.00am – 5.00pm<br/>
                Saturday &amp; Sunday: Not available
              </p>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <h3>Contact Form</h3>
              <Form innerRef={(ref) => this.formRef = ref} onSubmit={this.handleSubmit.bind(this)}>
                {
                  (customer)
                  ?
                    <FormGroup>
                      <Label htmlFor="input-name">Name:</Label>
                      <Input style={{ textTransform: 'unset' }} type="text" id="input-name" name="name" defaultValue={customer.name} required/>
                    </FormGroup>
                  :
                    <FormGroup>
                      <Label htmlFor="input-name">Name:</Label>
                      <Input style={{ textTransform: 'unset' }} type="text" id="input-name" name="name" required/>
                    </FormGroup>
                }
                {
                  (customer)
                  ?
                    <FormGroup>
                      <Label htmlFor="input-email">Email:</Label>
                      <Input style={{ textTransform: 'unset' }} type="email" id="input-email" name="email" defaultValue={customer.email} required/>
                    </FormGroup>
                  :
                    <FormGroup>
                      <Label htmlFor="input-email">Email:</Label>
                      <Input style={{ textTransform: 'unset' }} type="email" id="input-email" name="email" required/>
                    </FormGroup>
                }
                <FormGroup>
                  <Label htmlFor="input-message">Enquiry:</Label>
                  <Input style={{ textTransform: 'unset' }} rows="5" type="textarea" id="input-message" name="message" required/>
                </FormGroup>
                <Button>Submit</Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ main }) => {
  return { main };
}

export default connect(mapStateToProps)(ContactFormPage);
