import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
} from 'reactstrap';

import './HowWeDoPage.css';

import howWeDoImage from './how-we-do.jpg';

class HowWeDoPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container className="my-3">
        <Row>
          <Col>
            <h1 className="my-2">HOW WE DO</h1>
            <div className="process-link text-center">
              <Link to="/how-we-do" class="selected">How We Do</Link>
              <Link to="/how-to-top-up-account">How To Top Up Account</Link>
              <Link to="/how-to-order">How To Order</Link>
              <Link to="/how-to-count-process-day">How To Count Process Day</Link>
              <Link to="/how-to-join">How To Join</Link>
            </div>
            <div className="text-center">
              <img className="img-fluid" src={howWeDoImage} title="How We Do" alt="howWeDo"/>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default HowWeDoPage;
