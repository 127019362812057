/**
 * Check an array for type and length
 * 
 * @param {String} targetName - Name of the item, for identification puepose
 * @param {*} targetObj - Item to check
 * @param {Boolean} warnIfEmpty - Warn if the item is an array and it's empty
 */
export const checkArray = (targetName, targetObj, warnIfEmpty = true) => {
    if (Array.isArray(targetObj) === false) {
        throw `${targetName} is not an array`;
    }

    if (warnIfEmpty && targetObj.length === 0) {
        console.warn(`${targetName} is an empty array`);
    }
}

/**
 * Compare the value of two object, by converting it to JSON string.
 * 
 * @param {Object} x Item 1
 * @param {Object} y Item 2
 * 
 * @returns {Boolean} True if the item is equal.
 */
export const isObjValueEqual = (x, y) => {
    let xVal = JSON.stringify(x)
    let yVal = JSON.stringify(y)
    return xVal === yVal;
}

/**
     * Get if the current configuration a known variant
     * 
     * @param {*} variants Variant data
     * @param {*} configuration Configuration to check
     * 
     * @returns {Object} { result: <boolean>, variant_id: <string> }
     */
export const matchCurrentConfigToVariant = (variants, configurations) => {
    let output = {
        matched: false,
        variant_id: ''
    };

    if (variants == null) {
        // Only run this if we have the data
        return output;
    }

    for (let i = 0; i < variants.length; i++) {
        let currentVariant = variants[i];
        let isCurrentVariantMatched = true;
        let criterias = Object.keys(currentVariant).filter(x => x !== 'id');
        let cIndex = 0;
        while (cIndex < criterias.length && isCurrentVariantMatched) {
            let cName = criterias[cIndex];
            if (configurations[cName] == undefined) {
                // criteria not found on current configuration
                isCurrentVariantMatched = false;
            } else {
                // compare values
                isCurrentVariantMatched = isObjValueEqual(configurations[cName], currentVariant[cName]);
            }

            // go to next criteria
            cIndex++;
        }

        if (isCurrentVariantMatched === true) {
            output.matched = true;
            output.variant_id = currentVariant.id;
            return output;
        }

    }

    return output;
}

/**
 * Read file as base64 string
 * 
 * @param {File} file File object retrieve from input. See https://developer.mozilla.org/en-US/docs/Web/API/File.
 * 
 * @returns {Promise} Promise with base64 data
 */
export const readFileToBase64StringPromise = (file) => {
    return new Promise((resolve, reject) => {
        var regexS = /data\:.+\/.+;base64,/;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.replace(regexS, ''));
        reader.onerror = error => reject(error);
    });
}

/**
 * Generate url for product specification file
 * 
 * @param {String} fileName File name with extension
 */
export const productSpecUrl = (fileName) => {
    return `${process.env.PUBLIC_URL}/files/product-specs/${fileName}`
}

/**
 * Generate url for product price file
 * 
 * @param {String} fileName File name with extension
 */
export const productPriceUrl = (fileName) => {
    return `${process.env.PUBLIC_URL}/files/prices/${fileName}`
}