import React, { Component } from 'react';
import { Container, Row, Col, Card, CardDeck, CardHeader, CardBody, CardImg, CardText, Modal, ModalBody, ModalFooter, Button } from 'reactstrap';

class SignUpComplete extends Component {

    render() {

        return (
            <Container fluid={false} className="my-3">
                <h2 className="text-center">Thank You!</h2>
                <CardDeck>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col>
                                    <p className="text-center">Your account has been registered successfully.<br/>Please check your email and click on the activation link to complete the sign up process.</p>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </CardDeck>
            </Container>
        )
    }
}

export default SignUpComplete;