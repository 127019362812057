import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
} from 'reactstrap';

import './HowToTopUpAccountPage.css';

import cashChequeDeposit from './cash-cheque-deposit.png';
import interbankFundTransfer from './interbank-fund-transfer.png';


class HowToTopUpAccountPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container className="my-3">
        <Row>
          <Col>
            <h1 className="my-2">HOW TO TOP-UP ACCOUNT</h1>

            <div className="process-link text-center">
              <Link to="/how-we-do">How We Do</Link>
              <Link to="/how-to-top-up-account" class="selected">How To Top Up Account</Link>
              <Link to="/how-to-order">How To Order</Link>
              <Link to="/how-to-count-process-day">How To Count Process Day</Link>
              <Link to="/how-to-join">How To Join</Link>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center image-information">
              <div className="image-block">
                <img className="img-fluid" src={cashChequeDeposit} title="Cash Cheque Deposit" alt="cashChequeDeposit"/>
                <br/>
                <span className="image-label">Cash / Cheque Deposit</span>
              </div>
              <span className="or-divider">OR</span>
              <div className="image-block">
                <img className="img-fluid" src={interbankFundTransfer} title="Interbank Fund Transfer" alt="interbankFundTransfer"/>
                <br/>
                <span className="image-label">Interbank Fund Transfer</span>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Payment Method</h2>
            <p>
              Before you place an order, you need to top up your account.<br />
              There are 2 payment methods :<br />
              <strong>CASH / CHEQUE DEPOSIT or INTERBANK FUND TRANSFER</strong><br />
              Kindly email us the bank in slip after the payment is being made.<br /><br />

              {/* <strong>Fax :</strong> +604-502 3256<br /> */}
              <strong>Email :</strong> online@utech.com.my
            </p>

            <h2>Payable to :</h2>
            <p>
              <strong>U-TECH ENTERPRISE</strong><br />
              <strong>Public Bank</strong> 381 623 1200<br />
            </p>

            <h2>Cash Advance Pre-paid System</h2>
            <p>UTECH uses a pre-paid system. Cash advance will be served as your account money and debited according to your order(s). There is no minimum reload amount. Whenever you need to reload or top-up your account, you may do so in any of your order amounts.</p>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default HowToTopUpAccountPage;
