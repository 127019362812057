import React, { Component } from 'react';

import {
  Container,
  Row,
  Col,
  Table,
} from 'reactstrap';

import coredrawLogo from '../../../assets/product-guide/coredraw-logo.png';
import iconDownload from '../../../assets/product-guide/icon-download.png';
import illustratorLogo from '../../../assets/product-guide/illustrator-logo.png';
import pdfLogo from '../../../assets/product-guide/pdf-logo.png';

import './ArtWorkSpecificationPage.css';

class ArtWorkSpecificationPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container className="my-3">
        <Row>
          <Col>
            <h1 className="my-2">Art Work Specification</h1>

            <p>Artwork Specifications help you to get the best results for your print job. You may required to resend your artwork if we found that there is file errors.  Therefore, please always refer to our product size template and artwork specifications to avoid any unneccessary delay.</p>

            <p>
              <strong>File format accepted: PDF, Adobe Illustrator cs6, Coreldraw x5</strong>
              <img class="accepted-format-img" src={illustratorLogo} alt="illustrator-logo" />
              <img class="accepted-format-img" src={coredrawLogo} alt="coredraw-logo" />
              <img class="accepted-format-img" src={pdfLogo} alt="pdf-logo" />
            </p>

            <Table bordered="true">
              <thead class="specification-table-header">
                <tr>
                  <th class="align-middle">Artwork Specifications</th>
                  <th class="text-center">
                    <img class="download-image" src={pdfLogo} alt="pdf-logo" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>General Artwork Specifications</td>
                  <td class="text-center">
                    <a href="/files/artwork-specifications/general-artwork-specifications.pdf" target="_blank">
                      <img class="download-image" src={iconDownload} alt="icon-download" />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Why Use Product Size Template</td>
                  <td class="text-center">
                    <a href="/files/artwork-specifications/why-use-product-size-template.pdf" target="_blank">
                      <img class="download-image" src={iconDownload} alt="icon-download" />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Business Card Artwork Orientation</td>
                  <td class="text-center">
                    <a href="/files/artwork-specifications/business-card-artwork-orientation.pdf" target="_blank">
                      <img class="download-image" src={iconDownload} alt="icon-download" />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Preparing Round Corner Artwork</td>
                  <td class="text-center">
                    <a href="/files/artwork-specifications/preparing-round-corner-artwork.pdf" target="_blank">
                      <img class="download-image" src={iconDownload} alt="icon-download" />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Preparing Hot Stamping Artwork</td>
                  <td class="text-center">
                    <a href="/files/artwork-specifications/preparing-hot-stamping-artwork.pdf" target="_blank">
                      <img class="download-image" src={iconDownload} alt="icon-download" />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Preparing Spot UV Artwork</td>
                  <td class="text-center">
                    <a href="/files/artwork-specifications/preparing-spotuv-artwork.pdf" target="_blank">
                      <img class="download-image" src={iconDownload} alt="icon-download" />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Preparing Spot UV + Hot Stamping Artwork</td>
                  <td class="text-center">
                    <a href="/files/artwork-specifications/preparing-spotuv-hot-stamping-artwork.pdf" target="_blank">
                      <img class="download-image" src={iconDownload} alt="icon-download" />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Preparing Saddle Stitch Booklet</td>
                  <td class="text-center">
                    <a href="/files/artwork-specifications/preparing-saddlestitch-booklet.pdf" target="_blank">
                      <img class="download-image" src={iconDownload} alt="icon-download" />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Preparing Perfect Bind Booklet</td>
                  <td class="text-center">
                    <a href="/files/artwork-specifications/preparing-perfectbind-booklet.pdf" target="_blank">
                      <img src={iconDownload} alt="icon-download" />
                    </a>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ArtWorkSpecificationPage;
