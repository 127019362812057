import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
  Table,
} from 'reactstrap';

import processDay1 from './process-duration-images/1process-working.png';
import processDay2 from './process-duration-images/2process-working.png';
import processDay3 from './process-duration-images/3process-working.png';
import processDay4 from './process-duration-images/4process-working.png';
import processDay5 from './process-duration-images/5process-working.png';
import processDay6 from './process-duration-images/6process-working.png';
import processDay7 from './process-duration-images/7process-working.png';

import './HowToCountProcessDayPage.css';

class HowToCountProcessDayPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container className="my-3">
        <Row>
          <Col>
            <h1 className="my-2">How To Count Process Day</h1>

            <div className="process-link text-center">
              <Link to="/how-we-do">How We Do</Link>
              <Link to="/how-to-top-up-account">How To Top Up Account</Link>
              <Link to="/how-to-order">How To Order</Link>
              <Link to="/how-to-count-process-day" class="selected">How To Count Process Day</Link>
              <Link to="/how-to-join">How To Join</Link>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Ordering Cut-Off Time</h2>

            <Table bordered="true">
              <tbody>
                <tr>
                  <td class="text-center align-middle">MONDAY - FRIDAY <br/> 5.00PM</td>
                  <td class="text-center align-middle">
                    Booklet - Offset <br/>
                    Brochure <br/>
                    Business Card - Offset <br/>
                    Flyer
                  </td>
                </tr>
                <tr>
                  <td class="text-center align-middle">MONDAY - FRIDAY <br/> 3.00PM</td>
                  <td class="text-center align-middle">
                    Booklet - Digital <br/>
                    Business Card - Digital
                  </td>
                </tr>
              </tbody>
            </Table>

            <b><i>Process Working Days exclude:</i></b>
            <ul>
              <li>The date of order and the date of goods received/delivery day, Saturdays and Sundays or Public Holiday.</li>
              <li>Standard Delivery to West Malaysia requires 1 more Working day.</li>
              <li>Standard Delivery to East Malaysia requires 2 more Working day.</li>
              <li>For orders that are submitted or pending after cut off time, they will be considered as next day's order.</li>
            </ul>

            <h2>Process Duration</h2>

            <Table>
              <tbody>
                <tr>
                  <td className="align-middle">1 Process Working Day</td>
                  <td><img className="img-fluid" src={processDay1} alt="process-day1"/></td>
                </tr>
                <tr>
                  <td className="align-middle">2 Process Working Days</td>
                  <td><img className="img-fluid" src={processDay2} alt="process-day2"/></td>
                </tr>
                <tr>
                  <td className="align-middle">3 Process Working Days</td>
                  <td><img className="img-fluid" src={processDay3} alt="process-day3"/></td>
                </tr>
                <tr>
                  <td className="align-middle">4 Process Working Days</td>
                  <td><img className="img-fluid" src={processDay4} alt="process-day4"/></td>
                </tr>
                <tr>
                  <td className="align-middle">5 Process Working Days</td>
                  <td><img className="img-fluid" src={processDay5} alt="process-day5"/></td>
                </tr>
                <tr>
                  <td className="align-middle">6 Process Working Days</td>
                  <td><img className="img-fluid" src={processDay6} alt="process-day6"/></td>
                </tr>
                <tr>
                  <td className="align-middle">7 Process Working Days</td>
                  <td><img className="img-fluid" src={processDay7} alt="process-day7"/></td>
                </tr>
                <tr><td></td><td></td></tr>
              </tbody>
            </Table>

          </Col>
        </Row>
      </Container>
    );
  }
}

export default HowToCountProcessDayPage;
