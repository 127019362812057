import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import './UtechFooter.css'

import footerIcon from './footer-icon.png';

export default class UtechFooter extends React.Component {
  divStyle = {
    position: 'absolute',
    bottom: '0',
    width: '100%',
    height: '45px',
    color: 'white',
  };

  render() {
    return (
      <footer>
        <div className="footer-links pt-4 pb-1">
          <Container>
            <Row>
              <Col xs="12" md="6" lg="auto" className="footer-links-col">
                <h3>Product</h3>
                <ul>
                  <li><Link to="/products/1 Colour Digital Booklet">1 Colour Digital Booklet</Link></li>
                  <li><Link to="/products/4 Colour Digital Booklet">4 Colour Digital Booklet</Link></li>
                  {/* <li><Link to="/products/Offset Booklet">Offset Booklet</Link></li> */}
                  <li><Link to="/products/Digital Business Card">Digital Business Card</Link></li>
                  <li><Link to="/products/Offset Business Card">Offset Business Card</Link></li>
                  <li><Link to="/products/Brochure">Brochure</Link></li>
                  <li><Link to="/products/Flyer">Flyer</Link></li>
                  <li><Link to="/products/Racing Horse Calendar With Header" className="disabled-link" >Racing Horse Calendar With Header</Link></li>
                  <li><Link to="/products/Racing Horse Calendar Without Header" className="disabled-link">Racing Horse Calendar Without Header</Link></li>
                  <li><Link to="/products/Sticker Label">Sticker Label</Link></li>
                </ul>
              </Col>
              <Col xs="12" md="6" lg="auto" className="footer-links-col d-none d-md-block">
                <h3>Utech Process</h3>
                <ul>
                  <li><Link to="/how-we-do">How We Do</Link></li>
                  <li><Link to="/how-to-top-up-account">How to Top Up Account</Link></li>
                  <li><Link to="/how-to-order">How to Order</Link></li>
                  <li><Link to="/how-to-count-process-day">How to Count Process Day</Link></li>
                  <li><Link to="/how-to-join">How to Join</Link></li>
                </ul>
              </Col>
              <Col xs="12" md="6" lg="auto" className="footer-links-col d-none d-md-block">
                <h3>Product Guide</h3>
                <ul>
                  <li><Link to="/art-work-specification">Art Work Specification</Link></li>
                  <li><Link to="/product-size-template">Product Size Template</Link></li>
                  <li><Link to="/product-specifications">Product Specification</Link></li>
                </ul>
              </Col>
              <Col xs="12" md="6" lg="auto" className="footer-links-col d-none d-md-block">
                <h3>Custom Quote</h3>
                <ul>
                  <li><Link to="/custom-quote-booklet">Booklet</Link></li>
                  <li><Link to="/custom-quote-general">General</Link></li>
                </ul>
              </Col>
              <Col className="footer-links-col"></Col>
            </Row>
          </Container>
        </div>
        <div className="footer-div">
          <Container>
            <Row>
              <Col xs="12" md="6">
                <div className="d-inline-block float-none float-md-left">U-Tech Enterprise © 2019</div>
              </Col>
              <Col xs="12" md="6">
                <img className="footer-icon d-none d-lg-block" src={footerIcon} title="footer-icon" alt="Footer Icon"/>
                <div className="d-inline-block float-none float-md-right"><Link to="/terms-of-use">Terms of Use</Link> | <Link to="/privacy-policy">Privacy Policy</Link></div>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
    );
  }
}
